import { useTokenContext } from "@outschool/ui-auth";

import { FEATURE_FLAGS, isProduction } from "../../shared/Env";

export default function useExperimentOverridesEnabled() {
  const { getTokens } = useTokenContext();
  return (
    (!!getTokens()?.decodedToken?.is_admin || !isProduction) &&
    !!FEATURE_FLAGS.EXPERIMENT_OVERRIDE
  );
}
