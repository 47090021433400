import {
  UserWalletSummaryQuery,
  UserWalletSummaryQueryVariables
} from "@outschool/gql-frontend-generated";
import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";
import { useSession } from "@outschool/ui-auth";

export const CENTS_UPPER_LIMIT = 50000;

const userWalletSummaryQuery = gql`
  query UserWalletSummary {
    currentUser {
      uid
      walletSummary {
        pricingOffersTotalCents
        pricingOffers {
          uid
          remainingCapCents
          buyerOrgName
          isFinancialAid
        }
        creditAccountBalanceCents
        totalBalanceCents
      }
    }
  }
`;

export type WalletSummary = NonNullable<
  UserWalletSummaryQuery["currentUser"]
>["walletSummary"];
export type PricingOfferSummary = NonNullable<
  WalletSummary["pricingOffers"]
>[number];

export const useUserWalletSummary = (skip: boolean = false) => {
  const { isLoggedIn } = useSession();
  const { data, loading } = useQueryWithPreviousData<
    UserWalletSummaryQuery,
    UserWalletSummaryQueryVariables
  >(userWalletSummaryQuery, {
    skip: !isLoggedIn || skip
  });
  const walletSummary =
    data?.currentUser?.walletSummary ?? ({} as WalletSummary);

  return {
    pricingOffersTotalCents: walletSummary.pricingOffersTotalCents ?? 0,
    pricingOffers: walletSummary.pricingOffers ?? [],
    creditAccountBalanceCents: walletSummary.creditAccountBalanceCents ?? 0,
    totalBalanceCents: walletSummary.totalBalanceCents ?? 0,
    loading
  };
};
