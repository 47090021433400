import { Box } from "@outschool/backpack";
import { Loading, Space } from "@outschool/ui-legacy-component-library";
import React from "react";

type LoadingWithMessageProps = React.PropsWithChildren<{}>;

export default function LoadingWithMessage({
  children
}: LoadingWithMessageProps) {
  return (
    <Box
      flex
      sx={{
        flexDirection: "row",
        alignItems: "center"
      }}
    >
      <Loading delay={0} sx={{ color: "inherit" }} /> <Space x="small" />
      <Box>{children}</Box>
    </Box>
  );
}
