import { Box } from "@outschool/backpack";
import { useTrackEvent } from "@outschool/ui-analytics";
import { useSession } from "@outschool/ui-auth";
import { Loading } from "@outschool/ui-legacy-component-library";
import { pxToRem } from "@outschool/ui-utils";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { PaymentElement } from "@stripe/react-stripe-js";
import React from "react";

import { StripePaymentElementPaymentMethod } from "../../../shared/Stripe";

export default function StripePaymentElement({
  setIsPaymentElementComplete,
  setPaymentMethod,
  paymentMethod,
  payingUser
}: {
  setIsPaymentElementComplete: (value: boolean) => void;
  setPaymentMethod: (value: StripePaymentElementPaymentMethod) => void;
  paymentMethod: StripePaymentElementPaymentMethod;
  payingUser?: {
    name?: string;
    email?: string;
  };
}) {
  const trackEvent = useTrackEvent();
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const { currentUser, currentUserHasLoaded } = useSession();

  return (
    <Box sx={{ paddingBottom: pxToRem(28) }}>
      <Box
        sx={{
          color: "gray900",
          fontSize: pxToRem(20),
          fontWeight: 500,
          paddingBottom: "small"
        }}
      >
        Payment method
      </Box>
      {!hasLoaded && !currentUserHasLoaded && <Loading />}
      <PaymentElement
        onReady={() => setHasLoaded(true)}
        options={{
          defaultValues: {
            billingDetails: {
              name: currentUser?.name ?? payingUser?.name,
              email: currentUser?.email ?? payingUser?.email
            }
          }
        }}
        onChange={e => {
          const updatedPaymentMethod =
            e.value.type === "card"
              ? StripePaymentElementPaymentMethod.card
              : // @ts-ignore
                StripePaymentElementPaymentMethod[e.value.type];
          setIsPaymentElementComplete(e.complete);
          if (paymentMethod !== updatedPaymentMethod) {
            trackEvent("payment_type_change", {
              type: e.value.type
            });
          }
          setPaymentMethod(updatedPaymentMethod);
        }}
      />
    </Box>
  );
}
