import { Roles } from "@outschool/auth-shared";
import { FullCurrentUserFragmentFragment } from "@outschool/gql-frontend-generated";

import { preferredAges } from "../../shared/User";

export function isOrganizationAdmin(
  user: FullCurrentUserFragmentFragment | null
): boolean {
  return Boolean(user?.organizationAdmin);
}

export function isOrganizationAdminWhoCanEnroll(
  user: FullCurrentUserFragmentFragment | null //This is not necessary currentUser
): boolean {
  return Boolean(
    user?.organizationAdmin &&
      user?.roles?.includes(Roles.CanOrganizationEnroll)
  );
}

type DefaultSortOrder = "upcoming" | "relevance";

type ChildWithAge = { age?: number };

export function searchDefaults(
  user?: {
    admin_for_organization_uid?: string | null;
    children: ChildWithAge[];
  },
  learners?: ChildWithAge[]
) {
  let filters: {
    age?: string;
    order?: DefaultSortOrder;
  } = {};
  if (!user?.admin_for_organization_uid) {
    let ages = preferredAges(user, learners);
    ages.filter(age => age < 19);
    if (ages.length) {
      filters.age = ages.join(",");
    }
  }

  return filters;
}
