import { useTopNoticeContext } from "@outschool/ui-components-website";
import { Banner } from "@outschool/ui-legacy-component-library";
import React, { useEffect } from "react";

import * as Env from "../../shared/Env";
import ErrorMessage from "./ErrorMessage";

const DURATION = 12; // seconds

export default function TimedTopNoticeBar() {
  const { setTimedTopNotice, timedTopNotice } = useTopNoticeContext();

  useEffect(() => {
    if (timedTopNotice) {
      const tc = setTimeout(() => {
        setTimedTopNotice(null);
      }, DURATION * 1000);
      return () => {
        clearTimeout(tc);
      };
    }
    return () => {};
  }, [setTimedTopNotice, timedTopNotice]);

  return timedTopNotice ? (
    <Banner sticky>
      <ErrorMessage
        value={timedTopNotice}
        showStatusPageLink={Env.IS_READ_ONLY_MODE}
      />
    </Banner>
  ) : null;
}
