import { Box, Typography, useTheme } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import { CreditIcon, TrackedButton } from "@outschool/ui-components-shared";
import { JoinSubscriptionModal } from "@outschool/ui-components-website";
import React from "react";

export function JoinSubscriptionNavItem() {
  const theme = useTheme();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const { t } = useTranslation(
    "client\\components\\nav\\JoinSubscriptionNavItem"
  );
  return (
    <>
      <TrackedButton
        variant="link"
        trackingName="subscription_join_nav_item"
        sx={{
          border: "none",
          paddingX: 8,
          paddingTop: 6,
          display: "block",
          color: theme.palette.common.black
        }}
        onClick={(_e: React.MouseEvent<HTMLButtonElement>) => {
          setShowModal(!showModal);
        }}
      >
        <Box>
          <CreditIcon
            fillColor={theme.palette.warning.main}
            strokeColor={theme.palette.warning[700]}
          />
        </Box>
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.common.black,
            fontWeight: 600
          }}
        >
          {t("Join Membership")}
        </Typography>
      </TrackedButton>
      <JoinSubscriptionModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
}
