import {
  BASE_LOCALE,
  I18nLocale,
  I18nType,
  InitOptions,
  i18next,
  initReactI18next
} from "@outschool/localization";
import ChainedBackend from "i18next-chained-backend";
import HttpApi from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";

import { setDayjsLocale } from "../../shared/dayjsLocale";
import {
  ENABLED_FEATURE_FLAGS,
  ENABLE_LOCALE_PSEUDOLOCALIZATION,
  FeatureFlag,
  TRANSLATION_SERVICE_URL,
  isDevelopment,
  isTest
} from "../../shared/Env";
import { pseudo, setLetterRepeatOnPseudo } from "./i18n_pseudolocalization";

const translatingEnabled = ENABLED_FEATURE_FLAGS.includes(
  FeatureFlag.TranslateSiteCopy
);

export const initI18n = (userSelectedLocale: I18nLocale): I18nType => {
  const i18nOpts: InitOptions = {
    debug: false,
    lng: userSelectedLocale,
    // the default, load: "all", will load variants we may not have
    // ex: when lng = "zh-TW", it will try to load "zh" and "zh-TW"
    //
    load: "currentOnly",
    fallbackLng: BASE_LOCALE,
    ns: [
      "client",
      "localization",
      "shared",
      "ui-auth",
      "ui-components-shared",
      "ui-components-classroom",
      "ui-components-contentful",
      "ui-components-website",
      "ui-legacy-component-library",
      "ui-rich-text-editor"
    ],
    defaultNS: "client",
    keySeparator: "\\",
    nsSeparator: "\\",
    // This next line is necessary unless we want to polyfill Intl.PluralRules
    // https://www.i18next.com/misc/json-format#i18next-json-v4
    compatibilityJSON: "v3",
    // This function is called when `t` can't find a key, either because
    // the key doesn't exist in JSON, or because there's no JSON file.
    // `key` is the string passed into `t`, including the keyPrefix
    // `defaultValue` is the prop of the same name from `t` options
    parseMissingKeyHandler(key, defaultValue) {
      // This regex removes the prefix from the `key`
      // E.g. client\Button\Hello there! --> Hello there!
      return (defaultValue ?? key).replace(/.*\\([^\\]+)$/, "$1");
    },
    interpolation: {
      // React already escapes, so we don't want to double escape
      escapeValue: false
    },
    react: {
      // This option is not supported by `i18next-parser`, so we turn it off
      // (Open issue: https://github.com/i18next/i18next-parser/issues/264)
      transSupportBasicHtmlNodes: false
    }
  };

  i18next.use(initReactI18next);

  if (ENABLE_LOCALE_PSEUDOLOCALIZATION) {
    // this should never be set in production
    i18next.use(pseudo);
  }

  const localEnglishBackend = resourcesToBackend((language, ns, callback) => {
    import(`../../../../../../locales/${language}/${ns}.json`)
      .then(({ default: resources }) => {
        callback(null, resources);
      })
      .catch(error => {
        callback(error, null);
      });
  });

  if (!isTest) {
    i18next.use(ChainedBackend);
    i18nOpts.backend = {
      backends: [],
      backendOptions: []
    };
    if (TRANSLATION_SERVICE_URL && translatingEnabled) {
      (i18nOpts.backend as any).backends = [HttpApi, localEnglishBackend];
      (i18nOpts.backend as any).backendOptions = [
        {
          loadPath: (languages: $TSFixMe, namespaces: $TSFixMe) => {
            const [language] = languages;
            const [namespace] = namespaces;
            const ns = namespace.split("\\").shift();
            return `${TRANSLATION_SERVICE_URL}/${ns}+(${language}).json`;
          }
        }
      ];
    } else {
      (i18nOpts.backend as any).backends = [localEnglishBackend];
    }
  }

  if (ENABLE_LOCALE_PSEUDOLOCALIZATION) {
    // this should never be set in production
    i18next.use(pseudo);
    if (isDevelopment && !isTest) {
      (i18nOpts.backend as any).backends = [localEnglishBackend];
      // for Development make sure when you make changes its reading the generated json files
      // this way local PseudoLocalization works with new changes
    }
  }

  i18next.init(i18nOpts);

  if (translatingEnabled) {
    setDayjsLocale(userSelectedLocale);
  }

  return i18next;
};

export const togglePseudoLocalization = (
  toggleOn: boolean,
  letterRepeat: number
) => {
  if (!ENABLE_LOCALE_PSEUDOLOCALIZATION) {
    console.log("Pseudo-localization is not available");
    return;
  }
  if (toggleOn) {
    console.log(
      "Turning pseudo-localization on with a repeat count of :: " + letterRepeat
    );
    i18next.options.postProcess = ["pseudo"];
    setLetterRepeatOnPseudo(letterRepeat);
  } else {
    console.log("Turning pseudo-localization off");
    i18next.options.postProcess = false;
  }
};
