import { Box, Typography } from "@outschool/backpack";
import { centsToDollars } from "@outschool/business-rules";
import { Trans, useTranslation } from "@outschool/localization";
import { ExternalLink } from "@outschool/ui-components-shared";
import { Checkbox } from "@outschool/ui-legacy-component-library";
import { pxToRem } from "@outschool/ui-utils";
import React from "react";

import {
  STRIPE_PRIVACY_POLICY_URL,
  STRIPE_TOS_URL
} from "./StripeAuthorization";

type SubscriptionAuthorizationProps = {
  setIsChecked: (isChecked: boolean) => void;
  isChecked: boolean;
  disabled: boolean;
  totalCents: number;
};

export default function ResumeSubscriptionsAuthorization({
  setIsChecked,
  isChecked,
  disabled,
  totalCents
}: SubscriptionAuthorizationProps) {
  const { t } = useTranslation(
    "client\\components\\payments\\ResumeSubscriptionsAuthorization"
  );
  const paymentAmount = `$${centsToDollars(totalCents)} USD`;

  return (
    <Box
      sx={{
        backgroundColor: "grey.50",
        marginBottom: "1em",
        padding: pxToRem(16),
        borderRadius: pxToRem(8),
        width: "100%"
      }}
    >
      <Box
        flex
        sx={{
          fontSize: "0.875em"
        }}
      >
        <Checkbox
          id="disclaimer-checkbox"
          checked={isChecked}
          disabled={disabled}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setIsChecked(e.target.checked)
          }
        />

        <label htmlFor="disclaimer-checkbox">
          <Typography
            variant="inherit"
            component="div"
            sx={{
              marginLeft: "0.5em",
              "p:not(:first-child)": {
                paddingTop: 8
              }
            }}
          >
            <Trans t={t}>
              <p>
                I acknowledge the charges will automatically be charged weekly
                on Sunday. To remove your learner from the class and stop
                recurring charges, please visit the classroom page for the class
                and click on the “Stop Subscription” link.
              </p>
              <p>
                I authorize Outschool, Inc. to charge my credit card{" "}
                {{ paymentAmount }} as payment for the class on a recurring
                basis through its third-party vendor, Link by Stripe, Inc.,
                processing such personal information in accordance with their{" "}
                <ExternalLink url={STRIPE_TOS_URL}>
                  Terms of Services
                </ExternalLink>{" "}
                and{" "}
                <ExternalLink url={STRIPE_PRIVACY_POLICY_URL}>
                  Privacy Policy
                </ExternalLink>
                . Outschool does not store or process this information.
              </p>
            </Trans>
          </Typography>
        </label>
      </Box>
    </Box>
  );
}
