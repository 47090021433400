import { Icon, Theme, styled } from "@outschool/backpack";
import { faStar, fasStar } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import React from "react";

const StyledLabel = styled("label")({
  cursor: "pointer",
  margin: 0
});

const StyledStarInput = styled("input")(({ theme }) => ({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  width: "1px",
  "&:focus-visible + label": {
    outline: `2px solid ${theme.palette.primary.dark}`
  }
}));

const StyledList = styled("ul")(({ theme }) => ({
  margin: 0,
  listStyle: "none",
  display: "flex",
  gap: theme.spacing(4)
}));

function StarInputItem({
  id,
  value,
  checkedValue,
  onChange,
  children
}: $TSFixMe) {
  return (
    <>
      <StyledStarInput
        type="radio"
        value={value}
        checked={value === checkedValue}
        onChange={() => onChange(value)}
        id={id}
      />
      <StyledLabel htmlFor={id}>
        <Icon
          icon={checkedValue >= value ? fasStar : faStar}
          sx={(theme: Theme) => ({
            width: theme.spacing(32),
            height: theme.spacing(32),
            color: theme.palette.warning.main
          })}
          title={children}
        />
      </StyledLabel>
    </>
  );
}

type Props = {
  id?: string;
  value: number;
  onChange: (value: number) => void;
};

export function StarInput({ id = "starRating", value, onChange }: Props) {
  const { t } = useTranslation("client\\routes\\ReviewSubmissionPage");
  return (
    <StyledList>
      <li>
        <StarInputItem
          id={`${id}-1`}
          value={1}
          checkedValue={value}
          onChange={(s: $TSFixMe) => onChange(s)}
        >
          {t("1 star")}
        </StarInputItem>
      </li>
      <li>
        <StarInputItem
          id={`${id}-2`}
          value={2}
          checkedValue={value}
          onChange={onChange}
        >
          {t("2 star")}
        </StarInputItem>
      </li>
      <li>
        <StarInputItem
          id={`${id}-3`}
          value={3}
          checkedValue={value}
          onChange={onChange}
        >
          {t("3 star")}
        </StarInputItem>
      </li>
      <li>
        <StarInputItem
          id={`${id}-4`}
          value={4}
          checkedValue={value}
          onChange={onChange}
        >
          {t("4 star")}
        </StarInputItem>
      </li>
      <li>
        <StarInputItem
          id={`${id}-5`}
          value={5}
          checkedValue={value}
          onChange={onChange}
        >
          {t("5 star")}
        </StarInputItem>
      </li>
    </StyledList>
  );
}
