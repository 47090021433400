// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */

import { Search as SearchFilters } from "@outschool/business-rules";
import { FundingProgramAbbreviation } from "@outschool/funding-programs-shared";
import { SearchFilters as SearchFiltersType } from "@outschool/gql-backend-generated";
import { searchPath, tutorMatchPath } from "@outschool/routes";
import { useSessionStorageState } from "@outschool/session-storage";
import { dayjs } from "@outschool/time";
import {
  EsaSessionType,
  getEsaState,
  getEsaWaitPeriod,
  useSession
} from "@outschool/ui-auth";
import _ from "lodash";
import React, { useCallback, useContext, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import * as User from "../../shared/User";
import useTrackSearchFiltersApplied from "../hooks/useTrackSearchFiltersApplied";

const AGE_FILTER_LOCAL_STORAGE_KEY = "ageFilter";

/**
 * Type extended for filters used in the UI but not sent to the backend
 * (userName is always paired with userUid, but allows us to reconstruct
 * the UI)
 */
export type ExtendedSearchFiltersType = SearchFiltersType & {
  userName?: string | null;
  originalSpelling?: string;
};

export type SetFiltersType = (
  category: string,
  partialFilters: ExtendedSearchFiltersType,
  options?: {
    overrideRouting?: boolean;
    isTutorMatchPage?: boolean;
  }
) => void;

interface SearchFiltersContextValue {
  searchUid?: string;
  setFilters?: SetFiltersType;
  filters?: ExtendedSearchFiltersType;
  clearSearchInputBox?: () => void;
  clearSearchInputBoxRef?: React.RefObject<HTMLButtonElement>;
}

export const SearchFiltersContext =
  React.createContext<SearchFiltersContextValue>({});

const clearSearchInputBoxRef = React.createRef<HTMLButtonElement>();

export function SearchFiltersContextProvider({
  children
}: React.PropsWithChildren<{}>) {
  const { currentUser, esaSessionType } = useSession();
  const esaWaitPeriod = getEsaWaitPeriod();

  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const esaState = getEsaState();
  const [persistedFundingPrograms, setPersistedFundingPrograms] =
    React.useState(!!esaState ? [esaState] : undefined);

  const defaultLearnerAges =
    User.preferredAges(currentUser).length > 0
      ? User.preferredAges(currentUser).join(",")
      : undefined;

  const numberOfLearners = currentUser?.children?.length ?? 0;

  const noAutoAgeFilter = numberOfLearners > 1;

  const [persistedAgeFilter, setPersistedAgeFilter] = useSessionStorageState<
    string | undefined
  >(
    AGE_FILTER_LOCAL_STORAGE_KEY,
    noAutoAgeFilter ? undefined : defaultLearnerAges
  );

  const { searchUid, trackSearchFiltersApplied } =
    useTrackSearchFiltersApplied();

  useEffect(() => {
    if (esaSessionType === EsaSessionType.MERIT) {
      setPersistedFundingPrograms([FundingProgramAbbreviation.KS]);
    }
  }, [location.search, esaSessionType]);

  /* Filters priority (from lowest to highest priority):
      - age from local storage (more info here: https://outschool.slack.com/archives/C016RTZPMQ9/p1648835968536299)
      - filters from query string
  */
  const filters = React.useMemo(() => {
    const filtersFromQuery = SearchFilters.fromQueryAndParams(
      location.query,
      params
    );
    const esaSearchDate = {
      startAfter: dayjs().add(esaWaitPeriod, "days").format("YYYY-MM-DD")
    };
    const defaultScheduleFiltersToUse = esaState ? esaSearchDate : {};
    const _filters: Record<string, any> = {
      ...defaultScheduleFiltersToUse,
      age:
        persistedAgeFilter
          ?.split(",")
          ?.map(age => parseInt(age))
          ?.filter(age => age < 19)
          ?.join(",") ?? persistedAgeFilter,
      fundingPrograms: persistedFundingPrograms,
      ...filtersFromQuery
    };

    if (_filters?.age) {
      const age = _filters.age
        ?.split(",")
        ?.map((age: $TSFixMe) => parseInt(age))
        ?.filter((age: $TSFixMe) => age < 19)
        ?.join(",");

      if (age === "") {
        delete _filters["age"];
      } else {
        _filters.age = age;
      }
    }

    if (!!_filters?.adminTags?.trim()) {
      _filters.adminTags = _filters.adminTags.split(",");
    } else {
      _filters.adminTags = undefined;
    }

    if (_filters?.theme) {
      _filters.theme = SearchFilters.convertThemeQueryToFilter(_filters.theme);
    }

    if (!_filters?.fundingPrograms?.length) {
      _filters.fundingPrograms = undefined;
    }

    return _filters;
  }, [
    persistedAgeFilter,
    persistedFundingPrograms,
    location.query,
    params,
    esaState,
    esaWaitPeriod
  ]);

  const setFilters = useCallback<SetFiltersType>(
    (filterCategory, partialFilters, options) => {
      if ("q" in partialFilters && "multiTermQuery" in filters) {
        delete filters.multiTermQuery;
      }

      if ("multiTermQuery" in partialFilters && "q" in filters) {
        delete filters.q;
      }

      const newFilters: SearchFiltersType = _.omitBy(
        SearchFilters.omitCatchalls({
          ...filters,
          ...partialFilters
        }),
        _.isNil
      );

      const oldFilters = _.omitBy(
        SearchFilters.omitCatchalls(filters),
        _.isNil
      );
      trackSearchFiltersApplied({
        filterCategory,
        newFilters,
        oldFilters
      });

      if (!_.isEqual(oldFilters, newFilters)) {
        setPersistedAgeFilter(partialFilters.age || undefined);
        setPersistedFundingPrograms(newFilters.fundingPrograms ?? undefined);
      }

      let newFiltersWithFormattedTheme = Object.assign({}, newFilters);
      if (newFilters.theme) {
        newFiltersWithFormattedTheme.theme =
          SearchFilters.convertThemeFilterToQuery(newFilters.theme);
      }

      if (
        typeof newFiltersWithFormattedTheme?.includeInProgressFixedLengthLiveFormat ===
        "boolean"
      ) {
        // searchPath expects a string for boolean values
        // @ts-ignore
        newFiltersWithFormattedTheme.includeInProgressFixedLengthLiveFormat =
          newFiltersWithFormattedTheme.includeInProgressFixedLengthLiveFormat
            ? "true"
            : "false";
      }

      if (!options?.overrideRouting) {
        const pathFunc = options?.isTutorMatchPage
          ? tutorMatchPath
          : searchPath;
        history.push(pathFunc(newFiltersWithFormattedTheme), {
          filterCategory
        });
      }
    },
    [history, filters, setPersistedAgeFilter, trackSearchFiltersApplied]
  );

  const clearSearchInputBox = useCallback(() => {
    clearSearchInputBoxRef.current?.click();
  }, []);

  const value = React.useMemo(
    () => ({
      clearSearchInputBox,
      clearSearchInputBoxRef,
      filters,
      setFilters,
      searchUid
    }),
    [clearSearchInputBox, filters, setFilters, searchUid]
  );

  return (
    <SearchFiltersContext.Provider value={value}>
      {children}
    </SearchFiltersContext.Provider>
  );
}

export function useSearchFilters() {
  const context = useContext(SearchFiltersContext);
  if (!context.setFilters) {
    throw new Error("Called useSearchFilters without a SearchFiltersContext");
  }
  return context;
}
