import { UserLocale } from "@outschool/gql-backend-generated";
import {
  BASE_LOCALE,
  I18nLocale,
  makeLanguageString,
  useLocale
} from "@outschool/localization";
import { useSession } from "@outschool/ui-auth";
import * as _ from "lodash";
import React from "react";
import { generatePath, useLocation, useRouteMatch } from "react-router";

import ActionType from "../actions/ActionType";
import { PseudoLocalizationContext } from "../providers/PseudoLocalizationProvider";
import { useUpdateCurrentUserMutation } from "../queries/CurrentUser";
import { useAppState } from "../stores/AppStateProvider";

export const usePseudoLocalization = () =>
  React.useContext(PseudoLocalizationContext);

export const useSetLocale = () => {
  const { search, hash } = useLocation();
  const { path, params } = useRouteMatch();
  const { isLoggedIn } = useSession();
  const locale = useLocale();
  const appState = useAppState();
  const [updateCurrentUser] = useUpdateCurrentUserMutation();

  return {
    setLocale: React.useCallback(
      async (newLocale: I18nLocale) => {
        if (newLocale !== locale) {
          const [basePathname] = path.split("/").filter(Boolean);
          const pathWithoutLocale =
            basePathname === locale ? path.slice(locale.length + 1) : path;

          const { locale: _locale, ...paramsWithoutLocale } = params as any;
          const localePrefix = newLocale === BASE_LOCALE ? "" : `/${newLocale}`;
          const newLocalePath = generatePath(
            localePrefix + pathWithoutLocale,
            paramsWithoutLocale
          );
          // Fully reload the page on locale change.
          // eslint-disable-next-line no-restricted-syntax
          window.location.replace(`${newLocalePath}${search}${hash}`);
        }
      },
      [locale, search, hash, path, params]
    ),
    setUserLocale: React.useCallback(
      async (newLocale: UserLocale) => {
        if (isLoggedIn) {
          const { data } = await updateCurrentUser({
            variables: { locale: newLocale }
          });
          appState.appDispatcher.dispatch(
            ActionType.User.CURRENT_USER_UPDATED,
            {
              // @ts-ignore TS(2532): Object is possibly 'undefined'.
              currentUser: data.updateCurrentUser,
              property: "locale",
              value: newLocale
            }
          );
        }
      },
      [appState, isLoggedIn, updateCurrentUser]
    )
  };
};

export function useLanguagePreference() {
  const [browserLanguage, browserLanguages] = React.useMemo(() => {
    const nav: Partial<Navigator> & { userLanguage?: string } =
      navigator ?? window.navigator ?? {};
    let { language, languages, userLanguage } = nav;
    const browserLanguage = language ?? userLanguage ?? languages?.[0];
    const browserLanguages = _.uniq(
      _.filter([language, userLanguage, ...(languages ?? [])])
    );
    return [browserLanguage, browserLanguages];
  }, []);

  const hasLanguagePreference = React.useCallback(
    (language: string, strict: boolean = false) => {
      const genericLanguage = makeLanguageString(language, strict);
      return browserLanguages.some(lang => {
        if (lang) {
          genericLanguage === makeLanguageString(lang, strict);
        }
      });
    },
    [browserLanguages]
  );
  return { browserLanguage, browserLanguages, hasLanguagePreference };
}
