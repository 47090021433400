// Needs to be before other imports, to enable polyfills
/* import-sort-ignore */
import "@babel/polyfill";
import { skipTreeshake } from "./lib/init-platform-browser";

import { isLocalStorageSupported } from "@outschool/local-storage";
import { BASE_LOCALE, I18nLocale } from "@outschool/localization";
import { init as initUiComponentsWebsite } from "@outschool/ui-components-website";
import { createBrowserHistory } from "history";
import React from "react";
import { createRoot } from "react-dom/client";

import { LOCALE, OUTSCHOOL_ENV } from "../shared/Env";
import * as Routes from "../shared/Routes";
import App from "./components/App";
import { initAnalyticsPackage } from "./lib/analytics";
import { initExperiments } from "./lib/experiments";
import { createHistory } from "./lib/history";
import { initI18n } from "./lib/i18n";
import AppState from "./stores/AppState";

skipTreeshake();
initAnalyticsPackage();
initExperiments();

// Add any client-side locale overrides here if needed
const userSelectedLocale = LOCALE;

Routes.initRoutesPackage(userSelectedLocale);

const i18n = initI18n(userSelectedLocale);

initUiComponentsWebsite({
  env: OUTSCHOOL_ENV
});

const historyConfig =
  userSelectedLocale === BASE_LOCALE
    ? undefined
    : {
        basename: userSelectedLocale
      };
const history = createHistory(createBrowserHistory(historyConfig));

const appState = new AppState(history, isLocalStorageSupported());

const isLatinScriptLocale = [I18nLocale.En, I18nLocale.Es].includes(
  userSelectedLocale
);

const LoadCSS = React.lazy(() => import("./components/EnglishCss"));

const container = document.getElementById("app-main");
const root = createRoot(container!);
root.render(
  <>
    <React.Suspense fallback={<></>}>
      {isLatinScriptLocale && ( // Do not load Ginto font for non-(English || Spanish) locales
        <LoadCSS />
      )}
    </React.Suspense>
    <App
      appState={appState}
      history={history}
      userSelectedLocale={userSelectedLocale}
      i18n={i18n}
    />
  </>
);

(window as any).osClientJsLoadedAt = window.performance?.now?.() ?? Date.now();

const difference =
  (window as any).osClientJsLoadedAt - (window as any).osJsStartedAt;
const seconds = (difference / 1000).toFixed(2);
console.log(`OS Client JS finished execution after ${seconds} seconds.`);
