import { Dispatcher } from "flux";

import * as Env from "../../shared/Env";

type Payload = object & { actionType: string };

function instrumentAction(action: Payload) {
  if (!Env.isTest) {
    console.log("ACTION:", action.actionType);
  }
}

export default class AppDispatcher {
  private readonly dispatcher: Dispatcher<Payload>;
  constructor() {
    this.dispatcher = new Dispatcher();
    this.dispatcher.register(instrumentAction);

    this.dispatch = this.dispatch.bind(this);
    this.dispatchNextTick = this.dispatchNextTick.bind(this);
  }

  dispatch(actionType: string, payload: Omit<Payload, "actionType"> = {}) {
    // TODO support FSA https://github.com/acdlite/flux-standard-action
    this.dispatcher.dispatch({ ...payload, actionType });
  }

  dispatchNextTick(
    actionType: string,
    payload: Omit<Payload, "actionType"> = {}
  ) {
    setTimeout(() => {
      // TODO support FSA https://github.com/acdlite/flux-standard-action
      this.dispatcher.dispatch({ ...payload, actionType });
    }, 0);
  }
}
