import {
  Divider,
  Icon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  useUniqueId
} from "@outschool/backpack";
import { MainToolbarPinnedTabs } from "@outschool/gql-backend-generated";
import { faChevronDown, faChevronUp } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import {
  orgTeacherAvailabilityPath,
  pathnameWithoutLocale,
  sellerOrgPath,
  teachTipsUrl,
  teacherDashboardPath,
  teacherSchedulePath
} from "@outschool/routes";
import { useSession } from "@outschool/ui-auth";
import { ExternalLink } from "@outschool/ui-components-shared";
import { union } from "lodash";
import React from "react";

import * as Routes from "../../../shared/Routes";
import * as User from "../../../shared/User";
import { UserMode } from "../../../shared/UserMode";
import { useFeatureFlag } from "../../lib/FeatureFlags";
import { useBroadcastMessageFlag } from "../../routes/TeacherBroadcastPage";
import IconBadgeWithNumber from "../IconBadgeWithNumber";
import Link from "../Link";

const ConversationBadge = () => {
  const { currentUser } = useSession();

  return (
    <IconBadgeWithNumber
      color="red"
      count={User.unreadConversationsCount(currentUser)}
      dataTestId={"unread-message-count"}
    />
  );
};

export const MAX_PINNED_TABS = 3;

export type EducatorTab = {
  value: string;
  label: string;
  to: string;
  Badge?: React.FC;
};
export type EducatorTabs = {
  [key in MainToolbarPinnedTabs]: EducatorTab;
};

export const useEducatorPinnedTabs = () => {
  const { t } = useTranslation("client\\components\\nav\\EducatorModeToolbar");

  const allAvailableTabsByKey: EducatorTabs = {
    [MainToolbarPinnedTabs.Conversations]: {
      value: MainToolbarPinnedTabs.Conversations,
      label: t("Conversations"),
      to: Routes.conversationsPath(UserMode.Educator),
      Badge: ConversationBadge
    },
    [MainToolbarPinnedTabs.Classes]: {
      value: MainToolbarPinnedTabs.Classes,
      label: t("Classes"),
      to: Routes.teacherClassesPath()
    },
    [MainToolbarPinnedTabs.Schedule]: {
      value: MainToolbarPinnedTabs.Schedule,
      label: t("Schedule"),
      to: teacherSchedulePath()
    },
    [MainToolbarPinnedTabs.Insights]: {
      value: MainToolbarPinnedTabs.Insights,
      label: t("Insights"),
      to: Routes.teacherStatsPath()
    },
    [MainToolbarPinnedTabs.Sections]: {
      value: MainToolbarPinnedTabs.Sections,
      label: t("Sections"),
      to: Routes.teacherSectionsPath()
    },
    [MainToolbarPinnedTabs.Availability]: {
      value: MainToolbarPinnedTabs.Availability,
      label: t("Group class availability"),
      to: Routes.teacherAvailabilityPath()
    },
    [MainToolbarPinnedTabs.Coupons]: {
      value: MainToolbarPinnedTabs.Coupons,
      label: t("Coupons"),
      to: Routes.teacherCouponsPath()
    },
    [MainToolbarPinnedTabs.Profile]: {
      value: MainToolbarPinnedTabs.Profile,
      label: t("Profile"),
      to: Routes.leaderSettingsPath()
    },
    [MainToolbarPinnedTabs.Marketing]: {
      value: MainToolbarPinnedTabs.Marketing,
      label: t("Marketing"),
      to: Routes.teacherMarketingPath()
    },
    [MainToolbarPinnedTabs.Groups]: {
      value: MainToolbarPinnedTabs.Groups,
      label: t("Groups"),
      to: Routes.teacherClubsPath()
    },
    [MainToolbarPinnedTabs.Credentials]: {
      value: MainToolbarPinnedTabs.Credentials,
      label: t("Credentials"),
      to: Routes.teacherUploadCredentialsPath()
    }
  };

  const { currentUser } = useSession();
  const userMainToolbarPinnedTabKeys: string[] = (
    currentUser?.leader?.mainToolbarPinnedTabs || []
  )
    // Make sure tab is valid
    .filter(tab => tab in allAvailableTabsByKey);

  // Order the pinned tabs to be in front of the available tabs based on user's preferences
  const orderedTabKeys: string[] = union(
    userMainToolbarPinnedTabKeys,
    Object.keys(allAvailableTabsByKey)
    // Make sure tab is valid
  ).filter(tab => tab in allAvailableTabsByKey);

  return {
    /**
     * On object with all available tabs by key.
     */
    allAvailableTabsByKey,
    /**
     * An array of all available tabs
     */
    allAvailableTabs: Object.values(allAvailableTabsByKey),
    /**
     * An array of all pinned tab keys set by the user.
     * Invalid tabs are filtered out.
     */
    userMainToolbarPinnedTabKeys,
    /**
     * An array of all tabs, with pinned tabs first.
     * Invalid tabs are filtered out.
     */
    orderedTabs: orderedTabKeys.map(
      // @ts-ignore
      key => allAvailableTabsByKey[key]
    ) as EducatorTab[]
  };
};

export const EducatorModeToolbar = () => {
  const tabs = useEducatorModeToolbarTabs();
  const { value: currentTab } =
    tabs.find(tab =>
      pathnameWithoutLocale(window.location.pathname).startsWith(tab.to)
    ) || {};

  return (
    <Tabs sx={{ flex: 1 }} value={currentTab || false} variant="scrollable">
      {tabs.map(({ Badge, ...tab }) => (
        <Tab
          key={tab.value}
          {...tab}
          component={Link}
          icon={Badge ? <Badge /> : undefined}
          // eslint-disable-next-line i18next/no-literal-string
          iconPosition="end"
        />
      ))}
      <ToolbarOverflowMenu />
    </Tabs>
  );
};

const useEducatorModeToolbarTabs = (): EducatorTab[] => {
  const { t } = useTranslation("client\\components\\nav\\EducatorModeToolbar");
  const { currentUser } = useSession();
  const useOneOnOneFormatForOrgs = useFeatureFlag(
    "supply-one-on-one-activity-format-for-orgs"
  );

  const { orderedTabs } = useEducatorPinnedTabs();

  const isOrgTeacher = currentUser?.sellerOrg?.currentUserIsTeacher;
  if (isOrgTeacher) {
    return [
      {
        value: "schedule",
        label: t("Schedule"),
        to: teacherSchedulePath()
      },
      {
        value: "sections",
        label: t("Sections"),
        to: Routes.teacherSectionsPath()
      },
      ...(useOneOnOneFormatForOrgs
        ? [
            {
              value: "availability",
              label: t("1-on-1 Availability"),
              to: orgTeacherAvailabilityPath()
            }
          ]
        : []),
      {
        value: "groups",
        label: t("Groups"),
        to: Routes.teacherClubsPath()
      },
      {
        value: "conversations",
        label: t("Conversations"),
        to: Routes.conversationsPath(UserMode.Educator),
        Badge: ConversationBadge
      },
      {
        value: "educator-library",
        label: t("Educator Library"),
        to: teachTipsUrl()
      },
      {
        value: "credentials",
        label: t("Credentials"),
        to: Routes.teacherUploadCredentialsPath()
      }
    ];
  }

  const isOrgApplicant = !!currentUser?.latestSellerOrgApplication;
  const isTeacherApplicant = User.hasTeacherApplication(currentUser);

  // only show the first 3 pinned tabs chosen by the user
  const userTabs: EducatorTab[] = orderedTabs.slice(0, MAX_PINNED_TABS);

  const tabs: EducatorTab[] = [
    { value: "home", label: t("Home"), to: teacherDashboardPath() },
    ...(isTeacherApplicant
      ? [{ value: "apply", label: t("Apply"), to: Routes.teacherApplyPath() }]
      : []),
    ...userTabs,
    ...(isOrgApplicant
      ? [{ value: "manage", label: t("Manage"), to: sellerOrgPath() }]
      : [])
  ];

  return tabs;
};

const useToolbarOverflowMenuItems = () => {
  const { currentUser } = useSession();
  const { orderedTabs } = useEducatorPinnedTabs();
  const isOrgTeacher = currentUser?.sellerOrg?.currentUserIsTeacher;

  if (isOrgTeacher) {
    return [];
  }

  // Overflow menu includes all pinned tabs that are not shown in the main toolbar
  return orderedTabs.slice(MAX_PINNED_TABS);
};

const ToolbarOverflowMenu = () => {
  const { currentUser } = useSession();
  const canUseBroadcastMessaging = useBroadcastMessageFlag(currentUser);
  const isOrgTeacher = currentUser?.sellerOrg?.currentUserIsTeacher;
  const { t } = useTranslation("client\\components\\nav\\EducatorModeToolbar");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const buttonId = useUniqueId("ToolbarOverflowMenu-button");
  const menuId = useUniqueId("ToolbarOverflowMenu-menu");

  const menuItems = useToolbarOverflowMenuItems();

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (isOrgTeacher) {
    return null;
  }

  return (
    <>
      <Tab
        label={t("Menu")}
        icon={<Icon icon={isOpen ? faChevronUp : faChevronDown} />}
        // eslint-disable-next-line i18next/no-literal-string
        iconPosition="end"
        onClick={handleButtonClick}
        id={buttonId}
        sx={{
          flexDirection: "row",
          color: "text.primary",
          // gap matches space between text and icon in Button
          gap: 8
        }}
      />
      <Menu
        id={menuId}
        aria-labelledby={buttonId}
        open={isOpen}
        onClose={handleMenuClose}
        anchorEl={anchorEl}
        /* eslint-disable i18next/no-literal-string */
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        /* eslint-enable i18next/no-literal-string */
      >
        {menuItems.map(({ value, to, label, Badge }) => (
          <MenuItem key={value} component={Link} to={to}>
            <ListItemText>{label}</ListItemText>
            {Badge && (
              <ListItemIcon>
                <Badge />
              </ListItemIcon>
            )}
          </MenuItem>
        ))}
        {menuItems.length > 0 && <EducatorMenuDivider />}
        <MenuItem component={Link} to={Routes.activityCreatePath()}>
          <ListItemText>{t("Create new class")}</ListItemText>
        </MenuItem>
        <MenuItem component={ExternalLink} url={teachTipsUrl()}>
          <ListItemText>{t("Educator Library")}</ListItemText>
        </MenuItem>
        {canUseBroadcastMessaging && (
          <MenuItem component={ExternalLink} url={"/teach/broadcast"}>
            <ListItemText>{t("Broadcast Message")}</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

const EducatorMenuDivider = () => (
  <Divider
    sx={{
      marginY: "0.8rem !important"
    }}
  />
);
