import _ from "lodash";

export function addressToOneLine(address: string) {
  return address.replace(/[\n\r]+/g, ", ");
}

// Uses the Google Geocode API to map an address to location information.
// Works for partial or complete addresses like '94122', 'SF, CA', etc.
// Returns a "geocoder result". If the goecoder returns a list of results, this function wiill
// return the first of those.
export async function geocode(
  address: string,
  fetchFn: (input: RequestInfo, init?: RequestInit) => Promise<Response>
) {
  // TODO - store this key elsewhere?
  const googleAPIKey = "AIzaSyCnPQug7qOPRIVQqHU8ojUAwxfJyVfrLKs";
  const oneLineAddress = addressToOneLine(address);
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${oneLineAddress}&key=${googleAPIKey}`;
  const response = await fetchFn(url, {
    method: "GET"
  });

  if (response.ok) {
    const { results } = await response.json();
    return pickMostSpecificResult(results);
  } else {
    const { errorMessage } = await response.json();
    throw new Error("Status " + response.status + ". " + errorMessage);
  }
}

type GeocoderResult = {
  address_components: {
    types: string[];
    long_name?: string;
    short_name?: string;
  }[];
};

// Chooses the geocoder result with the largest number of 'useful' address components,
// as the most detailed. E.g. choose 'Queens, NY, USA' over 'Queens County, NY, USA'
// See test for sample results format.
export function pickMostSpecificResult(geocoderResults: GeocoderResult[]) {
  if (!geocoderResults.length) {
    return undefined;
  }

  const sortedByNumberOfUsefulAddressComponents = _.sortBy(
    geocoderResults,
    result => {
      const usefulAddressComponents = result.address_components.filter(
        component => {
          // A 'useful' component has one or more useful component types.
          const usefulComponentTypes = component.types.filter(componentType =>
            _.includes(
              ["locality", "sublocality", "administrative_area_level_1"],
              componentType
            )
          );
          const addressComponentIsUseful = !!usefulComponentTypes.length;
          return addressComponentIsUseful;
        }
      );
      return usefulAddressComponents.length;
    }
  );
  return sortedByNumberOfUsefulAddressComponents.reverse()[0];
}

export function resultToCity(geocoderResult: GeocoderResult) {
  const sublocality =
    geocoderResult &&
    geocoderResult.address_components.filter(e =>
      _.includes(e.types, "sublocality")
    )[0];
  if (sublocality && sublocality.long_name) {
    return sublocality.long_name;
  }

  const locality =
    geocoderResult &&
    geocoderResult.address_components.filter(e =>
      _.includes(e.types, "locality")
    )[0];
  return locality ? locality.long_name : undefined;
}

export function resultToState(geocoderResult: GeocoderResult) {
  const state =
    geocoderResult &&
    geocoderResult.address_components.filter(e =>
      _.includes(e.types, "administrative_area_level_1")
    )[0];
  return state ? state.long_name : undefined;
}

export function resultToStateAbbreviation(geocoderResult: GeocoderResult) {
  const state =
    geocoderResult &&
    geocoderResult.address_components.filter(e =>
      _.includes(e.types, "administrative_area_level_1")
    )[0];
  return state ? state.short_name : undefined;
}

export function resultToCountry(geocoderResult: GeocoderResult) {
  const country =
    geocoderResult &&
    geocoderResult.address_components.filter(e =>
      _.includes(e.types, "country")
    )[0];
  return country ? country.long_name : undefined;
}
