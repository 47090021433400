import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";

import {
  CurrencyCode,
  ExchangeRateQuery,
  ExchangeRateQueryVariables
} from "../../shared/generated/graphql";

export const exchangeRateQuery = gql`
  query ExchangeRate($currencyCode: CurrencyCode!) {
    exchangeRate(currencyCode: $currencyCode) {
      uid
      currency_code
      exchange_rate
    }
  }
`;

export function useExchangeRate(currencyCode: CurrencyCode) {
  const { loading, error, data } = useQueryWithPreviousData<
    ExchangeRateQuery,
    ExchangeRateQueryVariables
  >(exchangeRateQuery, { variables: { currencyCode } });

  const exchangeRate = data?.exchangeRate?.exchange_rate ?? 1;
  return { loading, error, exchangeRate };
}
