import { gql } from "@outschool/ui-apollo";

export const resendEmailConfirmationMutation = gql`
  mutation ResendEmailConfirmation($userUid: ID!) {
    resendEmailConfirmation(userUid: $userUid)
  }
`;

export const resendOtherEmailConfirmation = gql`
  mutation ResendOtherEmailConfirmation($userUid: ID!, $otherEmail: String!) {
    resendOtherEmailConfirmation(userUid: $userUid, otherEmail: $otherEmail)
  }
`;
