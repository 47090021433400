import { SUPPORTED_LOCALES, useTranslation } from "@outschool/localization";
import { pathFromLocation, pathToUrl } from "@outschool/routes";
import { useSession } from "@outschool/ui-auth";
import _ from "lodash";
import React, { useMemo } from "react";
import Helmet, { HelmetProps } from "react-helmet";
import { useLocation } from "react-router";

import { useDefaultPageTitle } from "../../shared/PageMetadata";

// @ts-ignore TS(2537): Type 'DetailedHTMLProps<MetaHTMLAttributes<HTMLMet... Remove this comment to see the full error message
type MetaTag = HelmetProps["meta"][number];

/**
 * Add these header tags for all users
 */
const HEADER_TAG_DEFAULTS: MetaTag[] = [
  { property: "og:type", content: "website" }
];

/**
 * Add these header tags when the user has made at least one purchase
 */
const HEADER_TAGS_EXISTING_BUYERS: MetaTag[] = [
  {
    name: "apple-itunes-app",
    content:
      "app-id=1594191740, affilate-data=pt=123314890&ct=product-smart-app-banner"
  }
];

function addDefaultsToMeta(meta: MetaTag[], allDefaultMetaTags: MetaTag[]) {
  const newMeta = meta.slice();
  for (const defaultMeta of allDefaultMetaTags) {
    if (
      !_.some(
        meta,
        ({ property, name }) =>
          (property && property === defaultMeta.property) ||
          (name && name === defaultMeta.name)
      )
    ) {
      newMeta.unshift(defaultMeta);
    }
  }
  return newMeta;
}

export default function HeaderTags({
  title,
  meta = [],
  canonical,
  ...otherProps
}: HelmetProps & { canonical?: string }) {
  const { currentUser } = useSession();
  const location = useLocation();
  const currentPath = pathFromLocation(location);
  let hrefLangUrls = [];

  for (let supportedLocale of SUPPORTED_LOCALES) {
    let langUrl = pathToUrl(currentPath, supportedLocale);
    // @ts-ignore
    hrefLangUrls.push({
      rel: "alternate",
      hrefLang: supportedLocale,
      href: langUrl
    });
  }

  const otherLinks = [...hrefLangUrls, ...(otherProps.link || [])];
  const links = canonical
    ? [{ rel: "canonical", href: canonical }, ...otherLinks]
    : otherLinks;

  const metaWithDefaults = useMemo(
    () =>
      addDefaultsToMeta(
        meta,
        Boolean(currentUser?.firstPaidEnrollment)
          ? HEADER_TAG_DEFAULTS.concat(HEADER_TAGS_EXISTING_BUYERS)
          : HEADER_TAG_DEFAULTS
      ),
    [meta, currentUser?.firstPaidEnrollment]
  );

  const { t } = useTranslation("client\\components\\HeaderTags");
  const defaultTitle = useDefaultPageTitle();

  const titleTemplate = "%s | " + t("Outschool");

  return (
    <Helmet
      defaultTitle={defaultTitle}
      titleTemplate={titleTemplate}
      title={title}
      link={links}
      script={otherProps?.script}
      {...otherProps}
      meta={metaWithDefaults}
    />
  );
}
