import { useLocalStorageState } from "@outschool/local-storage";
import React from "react";

import {
  DEFAULT_PSEUDOLOCALIZATION_REPEAT_COUNT,
  ENABLE_LOCALE_PSEUDOLOCALIZATION
} from "../../shared/Env";
import { togglePseudoLocalization } from "../lib/i18n";

/**
 * Pseudolocalization is a testing-only function that flips a setting to display
 * modified English letters whereever language translation tags have been set.
 * This utility is provided to verify that translation is working on a page.
 * It will be disabled in production.
 *
 * When this is toggledOn, you will see the English language text modified.
 * No other languages are effected, so if you'd like to test this, be sure to set
 * the locale to English
 *
 * @togglePseudo  : will toggle the translation post-processor off and on
 * @pseudoRepeatCound : determines how many times to repeat selected letters.  This is here to test
 * language strings that might be longer than English to see how it looks on the page.
 * @updatePseudoLocalizationSettings : will update context and i18n config at the same time.  These
 * need to be kept in sync
 *
 * This context does not need to persist anywhere, and will be reset upon reload.
 * */
export const PseudoLocalizationContext = React.createContext<{
  togglePseudo: boolean;
  pseudoRepeatCount: number;
  updatePseudoLocalizationSettings: (
    togglePseudo: boolean,
    pseudoRepeatCount: number
  ) => void;
}>({
  togglePseudo: false,
  pseudoRepeatCount: DEFAULT_PSEUDOLOCALIZATION_REPEAT_COUNT,
  updatePseudoLocalizationSettings: () => {}
});

export const PseudoLocalizationProvider = ({
  children
}: React.PropsWithChildren<{}>) => {
  const [togglePseudo, setTogglePseudo] = useLocalStorageState<boolean>(
    "i18n.devtools.PseudoLocalizationProvider.togglePseudo",
    false
  );
  const [pseudoRepeatCount, setPseudoRepeatCount] =
    useLocalStorageState<number>(
      "i18n.devtools.PseudoLocalizationProvider.pseudoRepeatCount",
      DEFAULT_PSEUDOLOCALIZATION_REPEAT_COUNT
    );
  const updatePseudoLocalizationSettings = React.useCallback(
    (toggle: boolean, letterCount: number) => {
      setTogglePseudo(toggle);
      setPseudoRepeatCount(letterCount);
    },
    [setTogglePseudo, setPseudoRepeatCount]
  );
  React.useEffect(() => {
    if (ENABLE_LOCALE_PSEUDOLOCALIZATION) {
      togglePseudoLocalization(togglePseudo, pseudoRepeatCount);
    }
  }, [togglePseudo, pseudoRepeatCount]);
  return (
    <PseudoLocalizationContext.Provider
      value={{
        togglePseudo,
        pseudoRepeatCount,
        updatePseudoLocalizationSettings
      }}
    >
      {children}
    </PseudoLocalizationContext.Provider>
  );
};
