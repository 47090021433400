// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { useAnalytics } from "@outschool/ui-analytics";
import React from "react";

import * as Attribution from "../lib/Attribution";

type Context = {};
const CONTEXT = {};

const AttributionContext = React.createContext<Context>({});

export const AttributionProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children
}) => {
  const analytics = useAnalytics();

  Attribution.updateFromLandingPageLocation(
    analytics,
    window.location,
    window.document.referrer
  );

  return (
    <AttributionContext.Provider value={CONTEXT}>
      {children}
    </AttributionContext.Provider>
  );
};
