// This control is for testing only and should never be
// Customer-facing, so there is no need to add translations
/* eslint-disable i18next/no-literal-string */

import { Box, Typography } from "@outschool/backpack";
import { Select, ToggleButton } from "@outschool/ui-legacy-component-library";
import * as React from "react";

interface PseudoLocalizationControlProps {
  contextToggleValue: boolean;
  togglePseudolocalization: boolean;
  pseudolocalizationRepeatCount: number;
  setTogglePseudolocalization: (togglePseudolocalization: boolean) => void;
  setPseudolocalizationRepeatCount: (
    pseudolocalizationRepeatCount: number
  ) => void;
}

export default function PseudoLocalizationControl(
  props: PseudoLocalizationControlProps
) {
  const {
    contextToggleValue,
    togglePseudolocalization,
    pseudolocalizationRepeatCount,
    setTogglePseudolocalization,
    setPseudolocalizationRepeatCount
  } = props;

  const getButtonText = () => {
    if (togglePseudolocalization === contextToggleValue) {
      return togglePseudolocalization ? "Toggle Off" : "Toggle On";
    }
    return togglePseudolocalization ? "Remain Off" : "Remain On";
  };
  const onToggle = () => {
    setTogglePseudolocalization(!togglePseudolocalization);
  };
  const headingSx = {
    margin: "0 0 10px 0"
  };

  return (
    <Box flex>
      <Box
        data-test-id="pseudo-localization-control"
        sx={{
          position: "relative",
          flex: "auto"
        }}
      >
        <Box>
          <Typography variant="h4" sx={headingSx}>
            For Testing Translations
          </Typography>

          <Typography variant="inherit">
            PseudoLocalization is{" "}
            {contextToggleValue ? (
              <Typography
                variant="inherit"
                sx={{
                  fontWeight: "fontWeightBold",
                  color: "#29a877"
                }}
              >
                On
              </Typography>
            ) : (
              <Typography
                variant="inherit"
                sx={{
                  color: "error.500"
                }}
              >
                Off
              </Typography>
            )}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "2px"
          }}
        >
          <ToggleButton onClick={onToggle} sx={{ borderWidth: "2px" }}>
            {" "}
            <Typography
              variant="inherit"
              sx={{
                fontSize: "0.75em"
              }}
            >
              Click To{" "}
            </Typography>
            {getButtonText()}{" "}
          </ToggleButton>
          {togglePseudolocalization && (
            <SelectRepeatCount
              pseudolocalizationRepeatCount={pseudolocalizationRepeatCount}
              setPseudolocalizationRepeatCount={
                setPseudolocalizationRepeatCount
              }
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

type SelectRepeatCountProps = Pick<
  PseudoLocalizationControlProps,
  "pseudolocalizationRepeatCount" | "setPseudolocalizationRepeatCount"
>;

const SelectRepeatCount = (props: SelectRepeatCountProps) => {
  const { pseudolocalizationRepeatCount, setPseudolocalizationRepeatCount } =
    props;
  return (
    <Box
      sx={{
        width: "50%",
        display: "inline-flex",
        flexDirection: "row"
      }}
    >
      <Typography
        variant="inherit"
        sx={{
          size: "small",
          color: "primary.700"
        }}
      >
        Length Multiplier
      </Typography>
      <Select
        options={[0, 1, 2, 3, 4, 5, 6]}
        value={pseudolocalizationRepeatCount}
        handleChangeOnBlur={false}
        onChange={setPseudolocalizationRepeatCount}
        sx={{ display: "block", borderRadius: "15px", lineHeight: "1em" }}
        placeholder="Set letter repeat..."
      />
    </Box>
  );
};
