import { gql } from "@outschool/ui-apollo";

import EnrollmentPaymentFragment from "./EnrollmentPaymentFragment";
import LearnerFragment from "./LearnerFragment";
import { ParentFragment } from "./ParentFragments";

export default gql`
  fragment GiftEnrollmentFragment on GiftEnrollment {
    uid
    parent {
      uid
      ...ParentFragment
    }
    learner {
      uid
      ...LearnerFragment
    }
    buyer {
      uid
      ...ParentFragment
    }
    pricingOffer {
      name
      buyerOrgName
      isFinancialAid
    }
    giftClaimedAt
    createdAt
    confirmedAt
    withdrawnAt
    withdrawn_at
    ongoingStoppedAt
    ongoingStartTime
    ongoingEndTime
    ongoing_stopped_at
    currency_code
    exchange_rate
    amount_in_local_currency
    ongoing_start_time
    ongoing_end_time
    hasPaymentSchedule
    isEnded
    isPaused
    orderSlugId
    canBeWithdrawn
    canBeStopped
    canBePaused
    priceCents
    currentUserIsPurchaser
    details {
      name
      age
      attendedAt
      message
    }
    payments {
      ...EnrollmentPaymentFragment
    }
    refundableAmountCents
    refundPolicy {
      type
      name
      description
    }
    paymentSchedule {
      uid
      stoppedAt
      endTime
    }
    pauseWindow {
      uid
      startDate
      endDate
      canBeCanceled
      pauseReason
    }
  }
  ${ParentFragment}
  ${LearnerFragment}
  ${EnrollmentPaymentFragment}
`;
