/**
 * Create a duplicate error instance with the same name and stack trace.
 * Used to prevent Sentry from deduplicating error messages.
 * @param error the error to duplicate
 * @param newMessagePrefix Prepend this message to the start of the original
 * message
 */
export const duplicateError = (error: Error, newMessagePrefix: string) => {
  const wrapperError = new Error(`${newMessagePrefix} ${error.message}`);
  wrapperError.name = error.name;
  wrapperError.stack = error.stack;
  return wrapperError;
};
