import { Box, Typography } from "@outschool/backpack";
import React from "react";

export default function Toolbar(props: $TSFixMe) {
  return (
    <Box
      {...props}
      sx={[
        {
          backgroundColor: "#f1f2f3",
          borderBottom: "1px solid",
          borderColor: "grey.100",
          paddingTop: "1em"
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx])
      ]}
    />
  );
}

export function CrumbArrow() {
  // eslint-disable-next-line i18next/no-literal-string
  return (
    <Typography
      variant="inherit"
      sx={{
        color: "grey.500",
        marginX: "0.5em"
      }}
    >
      »
    </Typography>
  );
}
