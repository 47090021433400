import { getReferrerAttribution } from "@outschool/attribution";
import { Box, Theme, Typography } from "@outschool/backpack";
import { Trans, useTranslation } from "@outschool/localization";
import React from "react";

export function getHasUnlimtedCouponApplied() {
  const { couponCode, landingPage } = getReferrerAttribution();
  return !!couponCode && landingPage.startsWith("/unlimited");
}

export function UnlimitedCouponBanner() {
  const { t } = useTranslation("client\\banners\\UnlimitedCouponBanner");

  return (
    <Box
      sx={(theme: Theme) => ({
        backgroundColor: theme.palette.info[50],
        color: theme.palette.info.dark,
        textAlign: "center",
        padding: "1.4rem 2.4rem"
      })}
    >
      <Typography as="p" variant="h6">
        <Trans t={t}>
          You’ve got 1 month free for Outschool Unlimited!{" "}
          <Typography
            as="span"
            variant="h6"
            sx={(theme: Theme) => ({ color: theme.palette.primary["700"] })}
          >
            Coupon will be applied at checkout.
          </Typography>
        </Trans>
      </Typography>
    </Box>
  );
}
