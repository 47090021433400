import { Box, Icon, Typography, visuallyHidden } from "@outschool/backpack";
import { faGlobe, fasClock } from "@outschool/icons";
import { useLookupIP } from "@outschool/iplookup-client";
import {
  CURRENCIES_INFO,
  getLanguageFromLocale,
  useCurrencyLocalization,
  useLocale,
  useTranslation
} from "@outschool/localization";
import { formatIANATimeZoneName } from "@outschool/time";
import { Footer } from "@outschool/ui-components-website";
import { useIsTablet } from "@outschool/ui-utils";
import React, { useState } from "react";

import { useRandomCategoriesByFacetQuery } from "../hooks/useRandomCategoriesByFacet";
import { useTimeZone } from "../providers/TimeZoneProvider";
import LocalizationPickerMobile from "./localization/LocalizationPickerMobile";
import LocalizationPickerModal from "./localization/LocalizationPickerModal";

function FooterLocalizationButton() {
  const { t } = useTranslation("client\\components\\Footer");
  const { currencyCode } = useCurrencyLocalization();
  const { timeZone } = useTimeZone();
  const locale = useLocale();
  const isTablet = useIsTablet();

  const [localizationMenuOpen, setLocalizationMenuOpen] =
    useState<boolean>(false);
  const openLocalizationMenu = () => setLocalizationMenuOpen(true);
  const closeLocalizationMenu = () => setLocalizationMenuOpen(false);

  const a11yLabel = t`Open currency, time zone, and language settings`;

  const currencySymbol = CURRENCIES_INFO[currencyCode]?.symbol;
  const currencyLabel = (
    <>
      <Typography
        variant="inherit"
        aria-hidden
        sx={{
          fontWeight: "fontWeightBold",
          marginRight: "5px"
        }}
      >
        {currencySymbol}
      </Typography>
      {currencyCode}
    </>
  );
  const timeZoneName = formatIANATimeZoneName(timeZone);
  const timeZoneLabel = (
    <>
      <Icon
        aria-hidden
        icon={fasClock}
        sx={{
          color: "common.white",
          backgroundColor: "primary.700",
          marginRight: "5px"
        }}
      />
      {timeZoneName}
    </>
  );
  const languageName = getLanguageFromLocale(locale).localLanguageName;
  const languageLabel = (
    <>
      <Icon
        aria-hidden
        icon={faGlobe}
        sx={{
          color: "common.white",
          backgroundColor: "primary.700",
          marginRight: "5px"
        }}
      />
      {languageName}
    </>
  );
  const divider = (
    <Typography
      variant="inherit"
      sx={{
        margin: "0 7px"
      }}
    >
      {"·"}
    </Typography>
  );

  return (
    <>
      {
        <Box
          data-test-id="footer-localization-picker"
          onClick={openLocalizationMenu}
          sx={{
            fontSize: "0.875em",
            color: "common.white"
          }}
        >
          <button
            style={{
              background: "none",
              color: "inherit",
              border: "none",
              cursor: "pointer",
              padding: 0
            }}
          >
            <span style={visuallyHidden}>{a11yLabel}</span>
            <Box
              sx={{
                display: "inline-block",
                verticalAlign: "middle",
                paddingRight: "0.5rem"
              }}
            >
              {languageLabel}
              {divider}
              {currencyLabel}
              {divider}
              {timeZoneLabel}
            </Box>
          </button>
        </Box>
      }
      {isTablet ? (
        <LocalizationPickerMobile
          open={localizationMenuOpen}
          onClose={closeLocalizationMenu}
          onBack={closeLocalizationMenu}
          standalone
        />
      ) : (
        <LocalizationPickerModal
          open={localizationMenuOpen}
          onClose={closeLocalizationMenu}
        />
      )}
    </>
  );
}

export default function FooterWithLocalization() {
  const { isInCA } = useLookupIP();
  const { data } = useRandomCategoriesByFacetQuery("popular", 15);
  const { data: tutoringData } = useRandomCategoriesByFacetQuery(
    "tutoring",
    15
  );
  const randomCategories =
    data?.randomCategoriesByFacet?.map(c => ({
      slug: c.slug,
      name: c.nameTitleCased,
      basePath: "/popular"
    })) ?? [];
  const tutoringCategories =
    tutoringData?.randomCategoriesByFacet?.map(b => ({
      slug: b.slug,
      name: b.nameTitleCased,
      basePath: b.basePath
    })) ?? [];

  return (
    <Footer
      isInCA={isInCA}
      LocalizationButton={<FooterLocalizationButton />}
      randomCategories={randomCategories}
      tutoringCategories={tutoringCategories}
    />
  );
}
