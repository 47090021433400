import { init } from "@outschool/experiments-shared";

import {
  COMMIT_SHA,
  KAFKA_REST_PROXY,
  OUTSCHOOL_ENV,
  clientVersion
} from "../../shared/Env";

export function initExperiments() {
  init({
    GIT_COMMIT_SHA: COMMIT_SHA as string,
    KAFKA_REST_PROXY,
    OUTSCHOOL_ENV,
    CLIENT_VERSION: clientVersion
  });
}
