import { gql } from "@outschool/ui-apollo";

export const teacherCredentialsQuery = gql`
  query TeacherCredentials {
    teacherCredentials {
      certificateDiscipline
      certificateExpireDate
      certificateIssueDate
      certificateNumber
      certificateSecondaryDiscipline
      certificateState
      createdAt
      credentialType
      degreeInstitution
      degreeIssueDate
      degreeSubject
      degreeType
      otherDetails
      otherType
      name
      uid
    }
  }
`;
