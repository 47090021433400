import { Box } from "@outschool/backpack";
import { Trans, useTranslation } from "@outschool/localization";
import { Component } from "@outschool/ownership-areas";
import {
  addParamsToUrl,
  browseRootPath,
  joinParentAccountPath,
  joinTeacherAccountPath,
  loginPath,
  queryStringToObject,
  searchPath,
  teachPath
} from "@outschool/routes";
import { useAnalytics } from "@outschool/ui-analytics";
import {
  ContinueWithApple,
  ContinueWithGoogle,
  ContinueWithKakao,
  ContinueWithLine,
  EmailSignupWithinModalForm,
  LoginUserFeedback,
  getPostLoginPath,
  useAuthError,
  useCanShowGoogleAuth,
  useShouldShowKakao,
  useShouldShowLine
} from "@outschool/ui-auth";
import { AccountDisclaimer } from "@outschool/ui-components-shared";
import {
  EmailSignupButton,
  TeacherRegistrationLinkExpComponent,
  useSignupToSearchExperiment,
  useTeacherRegistrationLinkExperiment
} from "@outschool/ui-components-website";
import { useNavigation } from "@outschool/ui-utils";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";

import { APPLE_SIGNIN_CLIENT_ID, GOOGLE_CLIENT_ID } from "../../shared/Env";
import ActionType from "../actions/ActionType";
import {
  useLoginWithApple,
  useLoginWithRedirect
} from "../hooks/Authentication";
import { useAppState } from "../stores/AppStateProvider";
import Link from "./Link";

interface SignupFormProps {
  onRedirect?: (e: React.MouseEvent<Element, MouseEvent>) => void;
  onClickSocialSignup?: () => void;
  promptLogin?: boolean;
  isTeacherFlow?: boolean;
  trackingParameters?: { [key: string]: string };
  trackingUniqueId?: string;
  signupParams?: { [key: string]: string | boolean | number };
  loginParams?: { [key: string]: string | boolean | number };
  includeEmailSignupWithinModal?: boolean;
  joinFromNavBar?: boolean;
  setIsRedirecting?: (isRedirecting: boolean) => void;
}

export default function SignupForm({
  onRedirect,
  onClickSocialSignup,
  promptLogin,
  isTeacherFlow,
  trackingParameters,
  trackingUniqueId,
  signupParams,
  loginParams,
  includeEmailSignupWithinModal = false,
  joinFromNavBar,
  setIsRedirecting = () => {}
}: SignupFormProps) {
  const postLoginPath = getPostLoginPath();
  const { t } = useTranslation("client\\components\\SignupForm");
  const [disableSignupButtons, setDisableSignupButtons] = useState(false);
  const { isInTreatment, trigger } = useTeacherRegistrationLinkExperiment();

  const hasTriggered = React.useRef(false);

  React.useEffect(() => {
    if (promptLogin && !hasTriggered.current && joinFromNavBar) {
      trigger();
      hasTriggered.current = true;
    }
  }, [promptLogin, trigger, joinFromNavBar, isTeacherFlow]);

  // Conditionally show email signup form within modal
  const [showEmailSignupForm, setShowEmailSignupForm] = useState(false);
  return (
    <Box
      sx={{
        display: "grid",
        gap: "1em",
        width: "100%"
      }}
    >
      <SignupButtons
        isTeacherFlow={isTeacherFlow}
        trackingParameters={trackingParameters}
        trackingUniqueId={trackingUniqueId}
        onRedirect={onRedirect}
        signupParams={signupParams}
        postLoginPath={postLoginPath}
        onClickSocialSignup={onClickSocialSignup}
        includeEmailSignupWithinModal={includeEmailSignupWithinModal}
        setDisableSignupButtons={setDisableSignupButtons}
        disableSignupButtons={disableSignupButtons}
        setShowEmailSignupForm={setShowEmailSignupForm}
        showEmailSignupForm={showEmailSignupForm}
        setIsRedirecting={setIsRedirecting}
      />
      {promptLogin && joinFromNavBar && isInTreatment ? (
        <TeacherRegistrationLinkExpComponent
          onRedirect={onRedirect}
          loginParams={loginParams}
          postLoginPath={postLoginPath}
          trackingUniqueId={trackingUniqueId}
        />
      ) : promptLogin ? (
        <Trans t={t}>
          <Box
            sx={{
              justifySelf: "center"
            }}
          >
            Already have an account?{" "}
            <Link
              data-test-id="login-link"
              onClick={onRedirect}
              to={addParamsToUrl(loginPath(), {
                postLoginPath: loginParams?.redirect ?? postLoginPath,
                ...loginParams
              })}
              trackingName="login"
              trackingUniqueId={trackingUniqueId}
            >
              Log in
            </Link>
          </Box>
        </Trans>
      ) : null}
      <AccountDisclaimer
        isSignup={true}
        showEmailSignupWithinModal={showEmailSignupForm}
      />
    </Box>
  );
}

function SignupButtons({
  isTeacherFlow,
  trackingParameters,
  trackingUniqueId,
  onRedirect,
  signupParams,
  postLoginPath,
  onClickSocialSignup,
  includeEmailSignupWithinModal,
  setDisableSignupButtons,
  disableSignupButtons,
  setShowEmailSignupForm,
  showEmailSignupForm,
  setIsRedirecting
}: $TSFixMe) {
  const analytics = useAnalytics();
  const { t } = useTranslation("client\\components\\SignupForm");
  const history = useHistory();
  const [userFeedbackMessage, setUserFeedbackMessage] =
    React.useState<React.ReactNode>(null);
  const appState = useAppState();
  const canShowGoogleAuth = useCanShowGoogleAuth();
  const navigate = useNavigation();

  const { isInTreatment, trigger } = useSignupToSearchExperiment();
  const signupToSearchExperimentPath =
    postLoginPath === browseRootPath() && isInTreatment
      ? searchPath()
      : postLoginPath;

  const hasTriggeredExperiment = React.useRef(false);
  const triggerSignupToSearchExperiment = React.useCallback(() => {
    if (postLoginPath === browseRootPath() && !hasTriggeredExperiment.current) {
      trigger();
      hasTriggeredExperiment.current = true;
    }
  }, [postLoginPath, trigger]);

  const {
    isGiftCardSignup,
    isOnboardingSignup,
    shouldCreateDefaultLearner,
    defaultLearnerAge
  } = signupParams || {};

  const createParams = {
    isGiftCardSignup,
    isLeader: isTeacherFlow,
    trackingParameters,
    isOnboardingSignup,
    shouldCreateDefaultLearner,
    defaultLearnerAge
  };

  const defaultErrorMessage = t("Sorry that didn't work, try again");
  const handleAuthError = React.useCallback(
    (error: $TSFixMe, message: React.ReactNode = defaultErrorMessage) => {
      OsPlatform.captureError(error, {
        tags: { component: Component.UserAccountManagement }
      });
      setUserFeedbackMessage(message);
    },
    [defaultErrorMessage, setUserFeedbackMessage]
  );

  const generateLoginError = useAuthError();

  const handleLoginSuccess = React.useCallback(
    ({
      sessionToken,
      refreshToken,
      postLoginPath,
      isLearnerTransfer
    }: $TSFixMe) => {
      if (!isLearnerTransfer) {
        appState.appDispatcher.dispatch(ActionType.User.LOGGED_IN, {
          sessionToken,
          refreshToken
        });
        const redirectPath =
          signupParams?.redirect ||
          (isTeacherFlow ? teachPath() : postLoginPath);
        history.push(redirectPath);
      } else {
        appState.appDispatcher.dispatch(
          ActionType.User.REDIRECT_TO_LEARNER_APP,
          {
            transferToken: sessionToken,
            isLearnerLogin: true
          }
        );
      }
    },
    [history, appState.appDispatcher, isTeacherFlow, signupParams?.redirect]
  );

  const handleLoginError = React.useCallback(
    (error: $TSFixMe) => {
      const errorObject = new Error(error);
      handleAuthError(errorObject, generateLoginError(error));
    },
    [handleAuthError, generateLoginError]
  );

  const handleAppleLogin = useLoginWithApple(
    handleLoginSuccess,
    handleLoginError,
    createParams,
    signupToSearchExperimentPath
  );

  const setAuthStrategy = useLoginWithRedirect(
    handleLoginError,
    createParams,
    // @ts-ignore TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    null,
    signupParams?.redirect || signupToSearchExperimentPath
  );

  const showKakao = useShouldShowKakao();
  const showLine = useShouldShowLine();

  const location = useLocation();
  const searchParams =
    (typeof window !== "undefined" && queryStringToObject(location.search)) ||
    {};

  return (
    <React.Fragment>
      <LoginUserFeedback
        userFeedbackMessage={userFeedbackMessage}
        isError={true}
      />
      {canShowGoogleAuth && (
        <ContinueWithGoogle
          trackingName="signup-with-google"
          setAuthStrategy={setAuthStrategy}
          // @ts-ignore TS(2322): Type '() => Promise<string | undefined>' is not as... Remove this comment to see the full error message
          getAnonymousId={analytics.anonymousId}
          clientId={GOOGLE_CLIENT_ID!}
          onClick={() => {
            onClickSocialSignup();
            triggerSignupToSearchExperiment();
          }}
          disableWhileLoading={disableSignupButtons}
        />
      )}

      {showLine && (
        <ContinueWithLine
          isLearnerApp={false}
          setAuthStrategy={setAuthStrategy}
          trackingName="signup-with-line"
          getAnonymousId={analytics.anonymousId}
          disableWhileLoading={disableSignupButtons}
          onClick={() => {
            triggerSignupToSearchExperiment();
          }}
        />
      )}

      {showKakao && (
        <ContinueWithKakao
          isLearnerApp={false}
          setAuthStrategy={setAuthStrategy}
          trackingName="signup-with-kakao"
          getAnonymousId={analytics.anonymousId}
          disableWhileLoading={disableSignupButtons}
          onClick={() => {
            triggerSignupToSearchExperiment();
          }}
        />
      )}

      <ContinueWithApple
        onSuccess={handleAppleLogin}
        onError={handleAuthError}
        trackingName="signup-with-apple"
        clientId={APPLE_SIGNIN_CLIENT_ID!}
        onClick={() => {
          onClickSocialSignup();
          triggerSignupToSearchExperiment();
        }}
        disableWhileLoading={disableSignupButtons}
      />
      {includeEmailSignupWithinModal ? (
        <EmailSignupWithinModalForm
          showEmailSignupForm={showEmailSignupForm}
          setShowEmailSignupForm={setShowEmailSignupForm}
          setDisableSignupButtons={setDisableSignupButtons}
          searchParams={searchParams}
          trackingUniqueId={trackingUniqueId}
          onUserLoggedIn={(sessionToken: string, refreshToken: string) => {
            appState.appDispatcher.dispatch(ActionType.User.LOGGED_IN, {
              sessionToken,
              refreshToken
            });
            const redirectPath = signupToSearchExperimentPath;
            navigate(redirectPath);
            window.location.reload();
          }}
          onUserRefresh={() => {
            appState.appDispatcher.dispatch(ActionType.User.REFRESH);
          }}
          isTeacherFlow={isTeacherFlow}
          setIsRedirecting={setIsRedirecting}
          triggerSignupToSearchExperiment={triggerSignupToSearchExperiment}
        />
      ) : (
        <EmailSignupButton
          linkComponent={Link}
          onClick={() => {
            onRedirect;
          }}
          trackingUniqueId={trackingUniqueId}
          to={
            isTeacherFlow
              ? joinTeacherAccountPath()
              : joinParentAccountPath({ ...signupParams, postLoginPath })
          }
        />
      )}
    </React.Fragment>
  );
}
