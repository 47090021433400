import {
  useAdSettings,
  useAnalytics,
  useIdentify,
  useTrackEvent
} from "@outschool/ui-analytics";
import { useApolloClient } from "@outschool/ui-apollo";
import { useRefreshSessionToken, useTokenContext } from "@outschool/ui-auth";
import { useTopNoticeContext } from "@outschool/ui-components-website";
import React from "react";

import AppState from "./AppState";

interface AppStateProviderProps {
  appState: AppState;
}

const AppStateContext = React.createContext<{ appState: AppState } | null>(
  null
);

export function AppStateProvider({
  appState,
  children
}: React.PropsWithChildren<AppStateProviderProps>) {
  const [appStateLoaded, setAppStateLoaded] = React.useState(false);

  const adSettings = useAdSettings();
  const analytics = useAnalytics();
  const apolloClient = useApolloClient();
  const identify = useIdentify();
  const { getTokens, getAuthData, setTokens, logout } = useTokenContext();
  const { setTimedTopNotice, setTopNotice } = useTopNoticeContext();
  const trackEvent = useTrackEvent();
  const refreshSessionToken = useRefreshSessionToken();

  React.useEffect(() => {
    appState.setAnalytics(analytics, identify, trackEvent, adSettings);
    appState.setLogoutCurrentUser(logout);
    appState.setApolloClient(apolloClient);
    appState.setTopNoticeSetter(setTopNotice);
    appState.setTimedTopNoticeSetter(setTimedTopNotice);
    appState.setAuth({
      getTokens,
      setTokens,
      getAuthData
    });
    appState.setRefreshSessionToken(refreshSessionToken);
    appState.initUserData();

    setAppStateLoaded(true);
  }, [
    appState,
    analytics,
    identify,
    trackEvent,
    adSettings,
    logout,
    apolloClient,
    setTopNotice,
    setTimedTopNotice,
    getTokens,
    setTokens,
    getAuthData,
    refreshSessionToken
  ]);

  const [appStateWrappedInNewObject, setAppStateWrappedInNewObject] =
    React.useState({ appState });

  React.useEffect(() => {
    if (appState) {
      const changeListener = () => {
        setAppStateWrappedInNewObject({ appState });
      };

      appState.addChangeListener(changeListener);
      return () => {
        appState.removeChangeListener(changeListener);
      };
    } else {
      return () => {};
    }
  }, [appState]);

  if (!appStateLoaded) {
    return null;
  }

  return (
    <AppStateContext.Provider value={appStateWrappedInNewObject}>
      {children}
    </AppStateContext.Provider>
  );
}

export function withAppState(DecoratedComponent: any) {
  const displayName = DecoratedComponent.displayName || "Component";

  // TODO: Use React.forwardRef
  return class AppStateWrapper extends React.Component {
    static displayName = `AppStateWrapper(${displayName})`;

    render() {
      return (
        <AppStateContext.Consumer>
          {appStateWrappedInNewObject => (
            <DecoratedComponent
              // @ts-ignore TS(2531): Object is possibly 'null'.
              appState={appStateWrappedInNewObject.appState}
              {...this.props}
            />
          )}
        </AppStateContext.Consumer>
      );
    }
  };
}

export function useAppState() {
  const appStateWrappedInNewObject = React.useContext(AppStateContext);
  // @ts-ignore TS(2531): Object is possibly 'null'.
  return appStateWrappedInNewObject.appState;
}
