import { Image, SxProps } from "@outschool/backpack";
import { useIsMobile } from "@outschool/ui-utils";
import React from "react";

import outschoolGlyph from "../../../images/Outschool_Glyph.svg";
import logo from "../../../images/Outschool_Logo_Color.svg";
import logoWhite from "../../../images/Outschool_Logo_White.svg";

interface HeaderLogoProps {
  isWhiteText?: boolean;
  sxProps?: SxProps;
}

export default function HeaderLogo({ isWhiteText, sxProps }: HeaderLogoProps) {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <Image
        // eslint-disable-next-line i18next/no-literal-string
        alt="Outschool"
        sx={{
          height: "24px",
          ...sxProps
        }}
        src={outschoolGlyph}
      />
    );
  }

  return (
    <Image
      // eslint-disable-next-line i18next/no-literal-string
      alt="Outschool"
      sx={{
        width: "206px",
        height: "26px",
        ...sxProps
      }}
      src={isWhiteText ? logoWhite : logo}
    />
  );
}
