import { Box, Icon, Image, Typography } from "@outschool/backpack";
import { faCheck } from "@outschool/icons";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { browseRootPath } from "@outschool/routes";
import {
  AuthTriggerActionTypes,
  WelcomeModalData,
  useLoginFlowContext
} from "@outschool/ui-auth";
import { TrackedButton } from "@outschool/ui-components-shared";
import { Modal } from "@outschool/ui-legacy-component-library";
import React from "react";

import rocketHeadImage from "../../images/rocket-head.png";
import Link from "./Link";

export const WELCOME_MODAL_MESSAGE = {
  [AuthTriggerActionTypes.SAVE_AFTER_LOGIN]: (
    _data: WelcomeModalData[AuthTriggerActionTypes.SAVE_AFTER_LOGIN]
  ) => `Your class was saved to your "Favorites"`,
  [AuthTriggerActionTypes.SAVE_SEARCH_AFTER_LOGIN]: (
    _data: WelcomeModalData[AuthTriggerActionTypes.SAVE_SEARCH_AFTER_LOGIN]
  ) => `Your search was saved to your "Favorites"`,
  [AuthTriggerActionTypes.MARK_HELPFUL_AFTER_LOGIN]: (
    _data: WelcomeModalData[AuthTriggerActionTypes.MARK_HELPFUL_AFTER_LOGIN]
  ) => `The review was marked as helpful`,
  [AuthTriggerActionTypes.FOLLOW_AFTER_LOGIN]: (
    data: WelcomeModalData[AuthTriggerActionTypes.FOLLOW_AFTER_LOGIN]
  ) => `You are now following ${data.leaderName}`
};

export default function WelcomeModal() {
  const {
    isWelcomeModalOpen,
    closeWelcomeModal,
    postLoginActionType,
    welcomeModalData,
    authTrigger
  } = useLoginFlowContext();

  const welcomeFlashMessage =
    !!postLoginActionType &&
    WELCOME_MODAL_MESSAGE.hasOwnProperty(postLoginActionType)
      ? // @ts-ignore
        WELCOME_MODAL_MESSAGE[postLoginActionType](welcomeModalData)
      : undefined;

  const onClose = React.useCallback(() => {
    if (isWelcomeModalOpen) {
      closeWelcomeModal();
      document.body.style.overflow = "auto";
    }
  }, [isWelcomeModalOpen, closeWelcomeModal]);

  return (
    <Modal
      ariaLabel="Welcome to Outschool!"
      open={isWelcomeModalOpen}
      sx={{ maxWidth: 450, paddingY: "large" }}
      onClose={onClose}
    >
      <Box
        sx={{
          display: "grid",
          gridRowGap: "2em",
          justifyItems: "center"
        }}
      >
        <Typography variant="h3">Welcome to Outschool!</Typography>
        {welcomeFlashMessage && (
          <Box
            sx={{
              backgroundColor: "rgba(41, 168, 119, 0.1)",
              borderColor: "rgba(41, 168, 119, 0.3)",
              color: "#29a877",
              border: "1px solid",
              borderRadius: 4,
              justifySelf: "stretch",
              textAlign: "center",
              padding: "0.5em"
            }}
          >
            <Icon icon={faCheck} />
            &nbsp; {welcomeFlashMessage}
          </Box>
        )}
        <Image
          sx={{
            width: "50%"
          }}
          src={rocketHeadImage}
        />
        <TrackedButton
          variant="contained"
          component={Link}
          to={browseRootPath()}
          onClick={onClose}
          trackingName="welcome-modal-cta"
          trackingSharedProperties={{ authTrigger }}
        >
          See Your Class Suggestions
        </TrackedButton>
      </Box>
    </Modal>
  );
}
