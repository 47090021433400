import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";

import {
  NextMeetingReminderQueryQuery,
  PickLearnerQuery,
  PickLearnerQueryVariables
} from "../../shared/generated/graphql";

const NextMeetingQuery = gql`
  query NextMeetingReminderQuery {
    nextMeetingReminder {
      uid
      startTime
      sectionUid
      activityTitle
    }
  }
`;

export function useNextMeetingReminder(skip: boolean) {
  return useQueryWithPreviousData<NextMeetingReminderQueryQuery>(
    NextMeetingQuery,
    {
      skip,
      pollInterval: skip ? 0 : 5 * 60 * 1000
    }
  );
}

export const classroomPickLearnerQuery = gql`
  query PickLearner($sectionUid: ID!) {
    classroom(sectionUid: $sectionUid) {
      uid
      section {
        uid
        currentUserCanManage
      }
    }
    learnersToPick(sectionUid: $sectionUid) {
      uid
      name
      age
      enrollmentUid
      isEnrollmentPaused
    }
  }
`;

export function usePickLearner(sectionUid: string) {
  return useQueryWithPreviousData<PickLearnerQuery, PickLearnerQueryVariables>(
    classroomPickLearnerQuery,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        sectionUid
      }
    }
  );
}
