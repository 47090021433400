import { gql, graphql } from "@outschool/ui-apollo";

export const loginMutation = gql`
  mutation Login(
    $emailOrUsername: String!
    $password: String!
    $postLoginOption: PostLoginOption
  ) {
    login(
      emailOrUsername: $emailOrUsername
      password: $password
      postLoginOption: $postLoginOption
    ) {
      __typename

      sessionToken
      learnerTransferToken

      ... on AuthenticationV2 {
        refreshToken
      }
    }
  }
`;

export const changePasswordMutation = gql`
  mutation changePassword($uid: ID!, $token: String!, $password: String!) {
    changePassword(uid: $uid, token: $token, password: $password) {
      sessionToken
      refreshToken
    }
  }
`;

export const changeLearnerPasswordMutation = gql`
  mutation changeLearnerPassword(
    $uid: ID!
    $token: String!
    $password: String!
  ) {
    changeLearnerPassword(uid: $uid, token: $token, password: $password) {
      transferToken
    }
  }
`;

export const confirmEmailMutation = gql`
  mutation confirmEmail($uid: ID!, $token: String!) {
    confirmEmail(uid: $uid, token: $token) {
      sessionToken
      refreshToken
    }
  }
`;

export const confirmOtherEmailMutation = gql`
  mutation confirmOtherEmail($uid: ID!, $token: String!) {
    confirmOtherEmail(uid: $uid, token: $token)
  }
`;

export const confirmEmailMutationDecorator = graphql(confirmEmailMutation, {
  props({ mutate }) {
    return {
      confirmEmail(uid: $TSFixMe, token: $TSFixMe) {
        return mutate!({
          variables: {
            uid,
            token
          }
        });
      }
    };
  }
});

export const getLearnerModeTransferToken = gql`
  mutation GetLearnerModeTransferToken($learnerUid: ID!) {
    learnerModeTransferToken(learnerUid: $learnerUid) {
      transferToken
    }
  }
`;
