import { SearchItem } from "@outschool/ui-components-website";
import React, { PropsWithChildren, useContext, useState } from "react";

export const SearchSuggestionsProvider = ({
  children,
  initialItem
}: PropsWithChildren<{ initialItem?: SearchItem }>) => {
  const [initialSelectedItem, _setInitialSelectedItem] = useState<
    SearchItem | undefined
  >(initialItem);

  function setInitialSelectedItem(item: SearchItem) {
    _setInitialSelectedItem(item);
  }

  return (
    <SearchSuggestionsContext.Provider
      value={{ initialSelectedItem, setInitialSelectedItem }}
    >
      {children}
    </SearchSuggestionsContext.Provider>
  );
};

export const SearchSuggestionsContext = React.createContext<
  | {
      initialSelectedItem: SearchItem | undefined;
      setInitialSelectedItem: (item: SearchItem) => void;
    }
  | undefined
>(undefined);

export const useSearchSuggestionsContext = () => {
  return useContext(SearchSuggestionsContext);
};
