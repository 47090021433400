import { useClientVersion } from "@outschool/ui-gql";
import {
  Link,
  addStateToLocation
} from "@outschool/ui-legacy-component-library";
import { LinkProps } from "@outschool/ui-utils";
import React from "react";

import * as Env from "../../shared/Env";

function ClientCheckingLink(
  {
    onClick,
    ...props
  }: LinkProps & { useHardNav?: boolean; useLinkTracking?: boolean } & Record<
      string,
      any
    >,
  ref: $TSFixMe
) {
  const expectedClientVersionRef = useClientVersion();
  return (
    <Link
      ref={ref}
      {...props}
      onClick={(e: $TSFixMe) =>
        handleClick(e, expectedClientVersionRef, onClick)
      }
    />
  );
}

function handleClick(
  e: $TSFixMe,
  expectedClientVersionRef: $TSFixMe,
  onClick: $TSFixMe
) {
  const expectedClientVersion = expectedClientVersionRef?.current;

  // TODO: Research on this ordering. It makes sense that we check
  //       if the default is prevented and only way is to trigger the
  //       onClick method, however, I am not 100% sure if this is the
  //       way events are handled by default.
  onClick && typeof onClick === "function" && onClick(e);
  const forceClientUpdate =
    !!expectedClientVersion && Env.clientVersion !== expectedClientVersion;

  if (forceClientUpdate) {
    // Workaround to tell react-router to skip push-state and give way to the native anchor behavior
    e.metaKey = true;
    console.log(
      `Forcing client update from version ${Env.clientVersion} to version ${expectedClientVersion}`
    );
    return false;
  }
  return true;
}

export default React.forwardRef(ClientCheckingLink);
export { addStateToLocation };
