import { Box, Icon, Typography } from "@outschool/backpack";
import { faAngleLeft, faTimes } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { IconButton } from "@outschool/ui-legacy-component-library";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";
import ReactDOM from "react-dom";

import LocalizationPickerMenu from "./LocalizationPickerMenu";

export default function LocalizationPickerMobile({
  open,
  onClose,
  onBack,
  standalone = false
}: $TSFixMe): React.ReactPortal {
  const { t } = useTranslation(
    "client\\components\\localization\\LocalizationPickerMobile"
  );
  return ReactDOM.createPortal(
    <Box>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: 3000,
          backgroundColor: standalone ? "rgba(0,0,0,0.6)" : "initial",
          pointerEvents: open ? "initial" : "none",
          opacity: open ? 1 : 0,
          transition: "opacity 0.3s ease"
        }}
        onClick={onClose}
      />
      <Box
        flex
        sx={{
          flexDirection: "column",
          position: "fixed",
          right: open ? 0 : "-100vw",
          top: 0,
          bottom: 0,
          backgroundColor: "common.white",
          zIndex: 3001,
          transition: "right 0.3s ease",
          minWidth: "75vw"
        }}
      >
        <Box
          flex
          sx={{
            padding: "0.5em",
            justifyContent: "space-between",
            borderBottom: "1px solid",
            borderColor: "#ecf1f8",
            marginLeft: "6px"
          }}
        >
          <Box
            flex
            onClick={onBack}
            sx={{
              alignItems: "center"
            }}
          >
            <Icon
              icon={faAngleLeft}
              sx={{
                color: "#2BA7CE",
                marginRight: "12px",
                fontSize: "1.333em"
              }}
            />
            <Typography variant="inherit">{t("Back")}</Typography>
          </Box>
          <IconButton
            icon={faTimes}
            iconProps={{ sx: { fontSize: "1.333em" } }}
            onClick={onClose}
          />
        </Box>
        {open && <LocalizationPickerMenu onClose={onBack} />}
      </Box>
    </Box>,
    document.body
  );
}
