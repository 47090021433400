import { BASE_LOCALE } from "@outschool/localization";
import Pseudo from "i18next-pseudo";

import { DEFAULT_PSEUDOLOCALIZATION_REPEAT_COUNT } from "../../shared/Env";

export const pseudo = new Pseudo({
  enabled: true,
  languageToPseudo: BASE_LOCALE,
  letterMultiplier: DEFAULT_PSEUDOLOCALIZATION_REPEAT_COUNT,
  repeatedLetters: ["R", "C", "L", "o", "a", "t", "g"]
});

export const setLetterRepeatOnPseudo = (repeatCount: $TSFixMe) => {
  if (repeatCount < 0) {
    console.log("Can't set to negative");
    return;
  }
  pseudo.configurePseudo({ letterMultiplier: repeatCount });
};
