import { initializePlatformBrowser } from "@outschool/platform-browser/init";

import { Sentry } from "./sentry";

initializePlatformBrowser({
  env: (window as any).OUTSCHOOL_SERVER.PLATFORM_ENV,
  sentry: Sentry
});

export function skipTreeshake() {
  // no-op; exists just to keep this file from being removed by webpack tree-shaking.
}
