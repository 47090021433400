import { CategoryPage } from "@outschool/gql-backend-generated";
import { useQueryWithPreviousData } from "@outschool/ui-apollo";
import { RandomCategoriesByFacetQuery } from "@outschool/ui-components-website";

export type RandomCategoryFacet = Pick<
  CategoryPage,
  "uid" | "nameTitleCased" | "basePath" | "slug"
> & {
  // @ts-ignore TS(2344): Type 'string' does not satisfy the constraint 'nev... Remove this comment to see the full error message
  searchFilters: Pick<CategoryPage["searchFilters"], "q">;
};

export const useRandomCategoriesByFacetQuery = (
  facet?: string,
  limit?: number
) =>
  useQueryWithPreviousData<{
    randomCategoriesByFacet: RandomCategoryFacet[];
  }>(RandomCategoriesByFacetQuery, {
    variables: { facet, limit }
  });
