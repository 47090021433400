/** Lives here for now to support tree-shaking these dynamic imports */
import { I18nLocale } from "@outschool/localization";
import { dayjs } from "@outschool/time";

export function getDayjsLocale(locale: I18nLocale) {
  // Must be written as a switch statement to support lazy loading
  switch (locale) {
    case I18nLocale.Ko:
      return import("dayjs/locale/ko");
    case I18nLocale.Ja:
      return import("dayjs/locale/ja");
    case I18nLocale.ZhTw:
      return import("dayjs/locale/zh-tw");
    case I18nLocale.Es:
      return import("dayjs/locale/es");
    case I18nLocale.En:
    default:
      return new Promise<"en">(res => res("en"));
  }
}

export async function setDayjsLocale(locale: I18nLocale) {
  const dayjsLocale = await getDayjsLocale(locale);
  dayjs.locale(dayjsLocale);
}

export async function withDayjsLocale<T extends (...args: any[]) => any>(
  locale: I18nLocale,
  fn: T
): Promise<ReturnType<T>> {
  const originalLocale = dayjs.locale();
  await setDayjsLocale(locale);
  let result, error;
  try {
    result = await fn();
  } catch (e) {
    error = e;
  } finally {
    await setDayjsLocale(originalLocale as I18nLocale);
  }
  if (error) {
    throw error;
  }
  return result;
}
