import {
  Box,
  Icon,
  Image,
  SxProps,
  Theme,
  Typography
} from "@outschool/backpack";
import { fasStar } from "@outschool/icons";
import {
  TFunction,
  renderCurrencyString,
  useTranslation
} from "@outschool/localization";
import {
  TrackedButton,
  TrackedButtonProps
} from "@outschool/ui-components-shared";
import { Modal } from "@outschool/ui-legacy-component-library";
import { useIsMobile } from "@outschool/ui-utils";
import React from "react";

import walletIcon from "../../../images/wallet-icon.svg";
import {
  CENTS_UPPER_LIMIT,
  PricingOfferSummary,
  useUserWalletSummary
} from "../../hooks/useUserWalletSummary";

const CENTS_LOWER_LIMIT = 50;

interface WalletNavItemProps
  extends Omit<TrackedButtonProps, "startIcon" | "trackingName"> {}

export function WalletNavItem({ onClick, sx, ...props }: WalletNavItemProps) {
  const { t } = useTranslation("client\\components\\nav\\WalletButton");
  const isMobile = useIsMobile();

  const {
    totalBalanceCents,
    pricingOffers,
    creditAccountBalanceCents,
    loading: summaryLoading
  } = useUserWalletSummary();

  const [showModal, setShowModal] = React.useState(false);

  if (
    summaryLoading ||
    (totalBalanceCents === 0 && pricingOffers.length === 0)
  ) {
    return null;
  }

  const hasLowFunds = totalBalanceCents < CENTS_LOWER_LIMIT;

  const dollarFormatter = (
    centsRemaining: number,
    showCurrencyCode: boolean = true
  ) =>
    renderCurrencyString({
      priceInCents: centsRemaining,
      showCurrencyCode,
      displayNoCents: true
    });
  return (
    <>
      <TrackedButton
        variant="link"
        onClick={(e: any) => {
          onClick?.(e);
          setShowModal(true);
        }}
        sx={[
          (theme: Theme) => ({
            borderRadius: 5,
            paddingX: 8,
            paddingY: 6,
            my: 10,
            backgroundColor: hasLowFunds
              ? theme.palette.warning[100]
              : theme.palette.success[100],
            color: "inherit",
            "&:hover:not(:disabled), &:focus:not(:disabled)": {
              backgroundColor: hasLowFunds
                ? theme.palette.warning[200]
                : theme.palette.success[200],
              color: hasLowFunds
                ? theme.palette.warning[900]
                : theme.palette.success[900]
            },
            textDecoration: "none !important",
            justifyContent: "flex-start",
            [theme.breakpoints.up("sm")]: {
              justifyContent: "center"
            }
          }),
          ...(Array.isArray(sx) ? sx : [sx])
        ]}
        startIcon={
          <WalletIcon showStar={!hasLowFunds} alt={t("Wallet button")} />
        }
        trackingName="nav_user_wallet"
        {...props}
      >
        {isMobile && (
          <Typography variant="inherit" sx={{ marginRight: 5 }}>
            {t("Wallet Balance:")}
          </Typography>
        )}
        <Typography
          variant="inherit"
          sx={(theme: Theme) => ({
            color: hasLowFunds
              ? theme.palette.warning[700]
              : theme.palette.success[700]
          })}
        >
          {totalBalanceCents > CENTS_UPPER_LIMIT
            ? `${dollarFormatter(CENTS_UPPER_LIMIT, false)}+`
            : dollarFormatter(totalBalanceCents, false)}
        </Typography>
      </TrackedButton>
      <WalletFundsModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        totalBalanceCents={totalBalanceCents}
        pricingOffers={pricingOffers}
        creditAccountBalanceCents={creditAccountBalanceCents}
        dollarFormatter={dollarFormatter}
        t={t}
      />
    </>
  );
}

interface WalletFundsModalProps {
  showModal: boolean;
  onClose: () => void;
  totalBalanceCents: number;
  pricingOffers: PricingOfferSummary[];
  creditAccountBalanceCents: number;
  dollarFormatter: (centsRemaining: number) => string;
  t: TFunction;
}

function WalletFundsModal({
  showModal,
  onClose,
  totalBalanceCents,
  pricingOffers,
  creditAccountBalanceCents,
  dollarFormatter,
  t
}: WalletFundsModalProps) {
  return (
    <Modal
      open={showModal}
      onClose={onClose}
      sx={{
        display: "flex",
        padding: "2.4rem",
        flexDirection: "column",
        gap: "1.6rem"
      }}
    >
      <Box
        flex
        sx={{
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center"
        }}
      >
        <WalletIcon
          size="large"
          showStar
          alt={t("Picture of wallet")}
          sx={{ marginRight: 16 }}
        />
        <Typography variant="h3">{t("Outschool Balances")}</Typography>
      </Box>
      {pricingOffers.map((pricingOffer: PricingOfferSummary) => (
        <PricingOfferCard
          key={pricingOffer.uid}
          pricingOffer={pricingOffer}
          dollarFormatter={dollarFormatter}
          t={t}
        />
      ))}
      {creditAccountBalanceCents > 0 && (
        <WalletCard
          fundsTitle={t("Outschool Balance")}
          fundsDescription={t("Current Balance")}
          fundsCents={creditAccountBalanceCents}
          dollarFormatter={dollarFormatter}
        />
      )}
      <Typography
        variant="h6"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingX: 24,
          color: "grey.700"
        }}
      >
        <span>{t("Total Balance:")}</span>
        <span>{dollarFormatter(totalBalanceCents)}</span>
      </Typography>
    </Modal>
  );
}

interface WalletCardProps {
  fundsTitle: string;
  fundsDescription: string;
  fundsCents: number;
  dollarFormatter: (centsRemaining: number) => string;
}

const WalletCard = ({
  fundsTitle,
  fundsDescription,
  fundsCents,
  dollarFormatter
}: WalletCardProps) => (
  <Box
    flex
    sx={(theme: Theme) => ({
      backgroundColor:
        fundsCents > 0
          ? theme.palette.success[100]
          : theme.palette.warning[100],
      flexDirection: "column",
      paddingY: 16,
      paddingX: 24,
      borderRadius: 4,
      gap: 12
    })}
  >
    <Typography variant="h4" sx={{ color: "grey.800" }}>
      {fundsTitle}
    </Typography>
    <Typography
      variant="h5"
      sx={(theme: Theme) => ({
        typography: "h6",
        [theme.breakpoints.up("sm")]: {
          typography: "h5"
        },
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        color: "grey.700",
        fontWeight: "fontWeightRegular"
      })}
    >
      <span>{fundsDescription}</span>
      <span>{dollarFormatter(fundsCents)}</span>
    </Typography>
  </Box>
);

interface PricingOfferCardProps {
  pricingOffer: PricingOfferSummary;
  dollarFormatter: (centsRemaining: number) => string;
  t: TFunction;
}

const PricingOfferCard = ({
  pricingOffer,
  dollarFormatter,
  t
}: PricingOfferCardProps) => {
  if (pricingOffer.remainingCapCents === null) {
    return null;
  }

  return (
    <WalletCard
      fundsTitle={
        pricingOffer.isFinancialAid
          ? t("Financial Assistance")
          : t("Covered By {{ buyerOrgName }}", {
              buyerOrgName: pricingOffer.buyerOrgName
            })
      }
      fundsDescription={t("Current Funds")}
      fundsCents={pricingOffer.remainingCapCents}
      dollarFormatter={dollarFormatter}
    />
  );
};

interface WalletIconProps {
  showStar?: boolean;
  size?: "small" | "large";
  alt?: string;
  sx?: SxProps;
}

function WalletIcon({
  showStar = false,
  size = "small",
  alt = "",
  sx
}: WalletIconProps) {
  return (
    <Box
      sx={[
        {
          position: "relative"
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      <Image
        src={walletIcon}
        alt={alt}
        height={size === "small" ? "23px" : "65px"}
      />
      {showStar && (
        <Icon
          icon={fasStar}
          color="warning"
          sx={{
            fontSize: size === "small" ? "10px" : "29px",
            position: "absolute",
            bottom: 0,
            left: 0,
            transform: "translate(-25%, 10%)"
          }}
        />
      )}
    </Box>
  );
}
