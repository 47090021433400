import { gql } from "@outschool/ui-apollo";

import LearnerFragment from "./LearnerFragment";

export default gql`
  fragment EnrollmentSummaryFragment on Enrollment {
    uid
    section {
      uid
      activity {
        uid
        title
      }
    }
    learner {
      ...LearnerFragment
    }
  }

  ${LearnerFragment}
`;
