import { gql } from "@outschool/ui-apollo";

export default gql`
  enum ModalType {
    WELCOME
  }

  type AppState {
    openModal: ModalType
    authTrigger: String # TODO(cp): Make this the enum source of truth for AuthTrigger
    welcomeFlashMessage: String
  }

  extend type Query {
    appState: AppState!
  }
`;
