import { shouldChargeInLocalCurrency as originalFn } from "@outschool/localization";

import { currencyEnrollmentEnabled } from "../../shared/hooks/useCurrencyEnrollmentEnabled/useCurrencyEnrollmentEnabled";

export const shouldChargeInLocalCurrency = (
  ...args: Parameters<typeof originalFn>
): ReturnType<typeof originalFn> => {
  if (!currencyEnrollmentEnabled()) {
    return false;
  }

  return originalFn(...args);
};
