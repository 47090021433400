import { getReferrerAttribution } from "@outschool/attribution";
import { OAUTH2_IS_NEW_USER } from "@outschool/auth-shared";
import { UserLocale } from "@outschool/gql-backend-generated";
import { useLookupIP } from "@outschool/iplookup-client";
import { useLocalStorageState } from "@outschool/local-storage";
import { getLanguageFromLocale, useLocale } from "@outschool/localization";
import { captureError } from "@outschool/platform";
import { signUpPath } from "@outschool/routes";
import { guessBrowserTimeZone } from "@outschool/time";
import {
  IntegrationCategory,
  useAnalytics,
  useTrackEvent
} from "@outschool/ui-analytics";
import {
  ContinueWithGoogleOneTap,
  useLoginWithGoogleOneTapMutationV2,
  useOAuth2TrackingParams,
  useSession,
  useTrackUserCreatedMutation
} from "@outschool/ui-auth";
import { useNavigation } from "@outschool/ui-utils";
import Cookies from "js-cookie";
import React from "react";
import { useLocation } from "react-router";

import { GOOGLE_CLIENT_ID } from "../../shared/Env";
import ActionType from "../actions/ActionType";
import { useAppState } from "../stores/AppStateProvider";
import OneTapConsentModal, {
  ONE_TAP_SIGNUP_STORAGE_KEY
} from "./OneTapConsentModal";

function useLoginWithGoogleOneTap({
  onSuccess,
  onError
}: {
  onSuccess: (
    sessionToken: string,
    refreshToken: string,
    isNewUser: boolean
  ) => void;
  onError: (error?: Error) => void;
}) {
  const loginWithGoogle = useLoginWithGoogleOneTapMutationV2();

  const analytics = useAnalytics();
  const { ipInfoLoaded, isInGDPR } = useLookupIP();
  const locale = useLocale();
  const { userLocale } = getLanguageFromLocale(locale);

  return React.useCallback(
    async (token: string) => {
      const createOptions = {
        attribution: await analytics.attribution(),
        browserTimeZone: guessBrowserTimeZone(),
        isGiftCardSignup: false,
        isLeader: false,
        subscribe: ipInfoLoaded ? !isInGDPR : false,
        locale: userLocale === UserLocale.En ? undefined : userLocale
      };

      const anonymousId = await analytics.anonymousId();
      const osRef = {
        ...getReferrerAttribution(),
        anonymousId
      };

      try {
        const { data } = await loginWithGoogle({
          variables: {
            createOptions,
            osRef,
            idToken: token
          }
        });

        if (
          data === undefined ||
          data?.loginOrCreateAccountWithGoogleV2 === undefined
        ) {
          onError(new Error("empty response"));
          return;
        }

        if (
          data?.loginOrCreateAccountWithGoogleV2.__typename === "LoginError"
        ) {
          onError(new Error("login error"));
        } else {
          const {
            loginOrCreateAccountWithGoogleV2: {
              isNewUser,
              authentication: { sessionToken, refreshToken }
            }
          } = data;
          onSuccess(sessionToken, refreshToken, isNewUser);
        }
      } catch (e) {
        onError(e);
      }
    },
    [
      analytics,
      loginWithGoogle,
      onSuccess,
      onError,
      ipInfoLoaded,
      isInGDPR,
      userLocale
    ]
  );
}

const SignupWithGoogleOneTap = ({}) => {
  const navigate = useNavigation();
  const appState = useAppState();
  const trackEvent = useTrackEvent();
  const trackAdEvent = useTrackEvent(IntegrationCategory.Advertising);
  const location = useLocation();
  const { trackingParameters, resetTrackingParameters } =
    useOAuth2TrackingParams();
  const trackUserCreated = useTrackUserCreatedMutation();

  const { isLoggedIn, currentUser } = useSession();

  const [showModal, setShowModal] = useLocalStorageState<boolean>(
    ONE_TAP_SIGNUP_STORAGE_KEY,
    false
  );

  const loginWithGoogle = useLoginWithGoogleOneTap({
    onSuccess: (sessionToken, refreshToken, isNewUser) => {
      if (!isNewUser) {
        trackEvent("signin-by-google-onetap");
      }
      appState.appDispatcher.dispatch(ActionType.User.LOGGED_IN, {
        sessionToken,
        refreshToken
      });
    },
    onError: error => {
      captureError(error);
      navigate(signUpPath());
    }
  });

  const shouldHide = React.useMemo(
    () =>
      /login|signup|teach$|organization|for-organizations/.test(
        location.pathname
      ) || /signup/.test(location.search),
    [location]
  );

  // only do this in CSR as it handles logged in.
  React.useEffect(() => {
    const isNewUser = Boolean(Cookies.get(OAUTH2_IS_NEW_USER));
    if (currentUser?.uid && isNewUser) {
      trackEvent("signup-by-google", trackingParameters);
      trackAdEvent("user-created", {
        value: 0,
        userUid: currentUser.uid,
        method: "google-one-tap"
      });
      trackUserCreated({});
      setShowModal(true);
      // prevent duplicate eventing
      Cookies.remove(OAUTH2_IS_NEW_USER);
      resetTrackingParameters();
    }
  }, [
    currentUser,
    trackAdEvent,
    trackEvent,
    trackingParameters,
    trackUserCreated,
    setShowModal,
    resetTrackingParameters
  ]);

  if (isLoggedIn) {
    return showModal ? (
      <OneTapConsentModal setShowModal={setShowModal} />
    ) : null;
  }

  return (
    <ContinueWithGoogleOneTap
      clientId={GOOGLE_CLIENT_ID as string}
      loginWithGoogle={async credential => {
        loginWithGoogle(credential);
        trackEvent("one-tap-prompt-click");
      }}
      shouldPrompt={!shouldHide}
      onLoad={() => {}}
      onPrompt={notification => {
        if (notification.getMomentType() === "display") {
          trackEvent("one-tap-prompt-view");
        } else if (notification.getMomentType() === "skipped") {
          trackEvent("one-tap-prompt-cancel");
        }
      }}
    />
  );
};

export default SignupWithGoogleOneTap;
