import { useTranslation } from "@outschool/localization";
import { ApolloError } from "@outschool/ui-apollo";
import React from "react";

import SimpleLayout from "../components/layouts/SimpleLayout";
import { NotFoundContent } from "../components/NotFoundContent";
import StickyHeader from "../components/parentDashboard/tabs/StickyHeader";
import { UserMode } from "../lib/UserMode";

type NotFoundPageProps = {
  data?: any;
  error?: string | ApolloError;
  title?: string;
  message?: React.ReactNode;
  userMode?: UserMode;
};

export default function NotFoundPage({
  data, // TODO: The structure of GraphQL data field may not be the same.
  error,
  title: titleProp,
  message,
  userMode
}: NotFoundPageProps) {
  const { t } = useTranslation("client\\routes\\NotFoundPage");
  const title = titleProp ?? t`Whoops! That page doesn't exist.`;

  if (data && data.error && data.error.networkError) {
    return (
      <SimpleLayout narrowPaddedColumn userMode={userMode}>
        <NotFoundContent data={data} title={title} message={message} />
      </SimpleLayout>
    );
  }

  return (
    <SimpleLayout error={error} userMode={userMode}>
      {/* @ts-ignore TS(2322): Type 'string | ApolloError | undefined' is not ass... Remove this comment to see the full error message */}
      <StickyHeader error={error} />
      <NotFoundContent data={data} title={title} message={message} />
    </SimpleLayout>
  );
}
