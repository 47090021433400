import { Button } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import { Banner } from "@outschool/ui-legacy-component-library";
import React from "react";

type EmailBannerProps = {
  children: React.ReactNode;
  showResendButton?: boolean;
  onResendButtonClick?: () => void;
};

export default function EmailBanner({
  children,
  showResendButton,
  onResendButtonClick
}: EmailBannerProps) {
  const { t } = useTranslation("client\\components\\banners\\EmailBanner");
  return (
    <Banner data-test-id="email-warning" variant={"warning"}>
      {children}{" "}
      {showResendButton && (
        <Button
          variant="link"
          sx={{
            alignSelf: "start",
            fontSize: "1em"
          }}
          onClick={onResendButtonClick}
        >
          {t("Resend.")}
        </Button>
      )}
    </Banner>
  );
}
