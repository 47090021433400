import { gql } from "@outschool/ui-apollo";

export const ActiveClassesQuery = gql`
  query ActiveClasses {
    currentUser {
      uid
      futureEndingActivities {
        uid
        age_max
        age_min
        weekly_meetings
        duration_weeks
        duration_minutes
        details {
          photo
        }
        title
        classFormat
        hasTeacherSchedule
      }
    }
  }
`;

export const ActiveEnrollmentsByClassQuery = gql`
  query ActiveEnrollmentsByClass($activityUid: ID!) {
    activity(uid: $activityUid) {
      currentAndFutureSections {
        uid
        start_time
        end_time
        filledSpaceCount
        enrollments(status: Enrolled) {
          parent {
            uid
          }
          learner {
            name
          }
        }
      }
    }
  }
`;
