import { Box, Button } from "@outschool/backpack";
import { ShouldShowEnrollmentPausedDueToPaymentFailureAlertQueryQuery } from "@outschool/gql-frontend-generated";
import { useTranslation } from "@outschool/localization";
import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";
import { useSession } from "@outschool/ui-auth";
import { useFeatureFlag } from "@outschool/ui-components-shared";
import { usePageAlerts } from "@outschool/ui-components-website";
import React from "react";

import * as Env from "../../../shared/Env";
import { UpdatePaymentMethodDueToPaymentFailureModal } from "../account/UpdatePaymentMethodDueToPaymentFailure";

const shouldShowEnrollmentPausedDueToPaymentFailureAlertQuery = gql`
  query ShouldShowEnrollmentPausedDueToPaymentFailureAlertQuery {
    currentUser {
      uid
      shouldShowEnrollmentPausedDueToPaymentFailureAlert
    }
  }
`;

export const useShouldShowEnrollmentPausedDueToPaymentFailureAlertQuery =
  () => {
    const { currentUser } = useSession();
    const paymentFailureFeatureFlag =
      useFeatureFlag("ppp-ongoing-enrollment-payment-failure-pause-rollout") ||
      Env.PAUSE_PAYMENT_FAILURES;

    return useQueryWithPreviousData<ShouldShowEnrollmentPausedDueToPaymentFailureAlertQueryQuery>(
      shouldShowEnrollmentPausedDueToPaymentFailureAlertQuery,
      {
        // Should not load for non-logged in people
        skip: !currentUser || !paymentFailureFeatureFlag,
        // This hook will pull from cache first so we aren't hitting the backend for no reason
        fetchPolicy: "cache-and-network"
      }
    );
  };

const PaymentFailureAlertAction = () => {
  const { t } = useTranslation(
    "client\\components\\alerts\\PaymentFailurePageAlert"
  );
  const [isPaymentMethodModalOpen, setIsPaymentMethodModalOpen] =
    React.useState(false);

  return (
    <Box
      sx={{
        // We need 12px of top padding so that the Alert action is in line with the Alert title and icon
        // This should probably be done in the Alert component itself? Just not sure how.
        paddingTop: "12px"
      }}
    >
      <Button
        variant="link"
        color="error"
        onClick={() => setIsPaymentMethodModalOpen(true)}
      >
        {t("Update Payment Method")}
      </Button>
      <UpdatePaymentMethodDueToPaymentFailureModal
        formOptions={{
          alertMessage: t(
            "Update your payment method to resume weekly subscriptions paused due to payment issue."
          )
        }}
        open={isPaymentMethodModalOpen}
        onClose={() => setIsPaymentMethodModalOpen(false)}
      />
    </Box>
  );
};

const paymentFailurePageAlertId = "payment-failure-page-alert";

export const usePaymentFailurePageAlert = () => {
  const { addPageAlert, removePageAlert } = usePageAlerts();

  const { data, loading } =
    useShouldShowEnrollmentPausedDueToPaymentFailureAlertQuery();

  const { currentUser: queryCurrentUser } = data || {};
  const { shouldShowEnrollmentPausedDueToPaymentFailureAlert } =
    queryCurrentUser || {};

  const { t } = useTranslation(
    "client\\components\\alerts\\PaymentFailurePageAlert"
  );

  React.useEffect(() => {
    if (!loading && shouldShowEnrollmentPausedDueToPaymentFailureAlert) {
      addPageAlert({
        id: paymentFailurePageAlertId,
        severity: "error",
        title: t("Subscriptions paused due to payment issue"),
        content: t(
          "Your weekly subscriptions have been paused. To resume your subscription and regain full access to these classes, please update your payment method."
        ),
        dismissible: false,
        persistant: true,
        action: <PaymentFailureAlertAction />
      });
    } else {
      // This query will get refreshed by graphql cache if it's fetched elsewhere.
      // We want to make sure we dismiss the alert if it gets updated to be false.
      removePageAlert(paymentFailurePageAlertId);
    }
  }, [
    addPageAlert,
    removePageAlert,
    shouldShowEnrollmentPausedDueToPaymentFailureAlert,
    t,
    loading
  ]);
};
