import * as Auth from "@outschool/auth-shared";
import { isLiveOneTime } from "@outschool/business-rules";
import { refund_policy } from "@outschool/db-queries/dist/generated/types";
import {
  Activity,
  DeliveryType,
  User as UserType
} from "@outschool/gql-backend-generated";
import { participationGuidelinesUrl } from "@outschool/routes";
import _ from "lodash";

import * as User from "./User";

import type { ActivitiesRow, UsersRow } from "@outschool/db-queries";

/**
 * @deprecated use: `const { Format } = useActivityString()`
 */
export const Format = [
  {
    value: "Live online",
    label: "Live online meetings"
  },
  "Flexible schedule",
  "Self-Paced"
];

export const ACTIVITY_DELIVERY: Readonly<Record<DeliveryType, string>> =
  Object.freeze({
    [DeliveryType.OneOnOneClass]: "One-on-one class", // deprecated - use capacityMax instead
    [DeliveryType.OneTimeClass]: "One-time class",
    [DeliveryType.OngoingClass]: "Ongoing class",
    [DeliveryType.SemesterCourse]: "Semester course",
    [DeliveryType.ShortCourse]: "Short course",
    [DeliveryType.Camp]: "Camp",
    [DeliveryType.Club]: "Group"
  });

/**
 * @deprecated use: `const { Delivery } = useActivityString()`
 */
export const Delivery = Object.values(ACTIVITY_DELIVERY);

export const classAgeMin = 3;
export const clubAgeMin = 8;
export const ageMax = 18;
export const ageOptions = _.range(classAgeMin, ageMax + 1);
export const clubAgeOptions = _.range(clubAgeMin, ageMax + 1);
export const suggestedWeeklyMeetingsOptions = _.range(1, 7 + 1);

export enum USGradeLevel {
  Preschool = "Preschool",
  PreKindergarten = "Pre-Kindergarten",
  Kindergarten = "Kindergarten",
  FirstGrade = "1st grade",
  SecondGrade = "2nd grade",
  ThirdGrade = "3rd grade",
  FourthGrade = "4th grade",
  FifthGrade = "5th grade",
  SixthGrade = "6th grade",
  SeventhGrade = "7th grade",
  EighthGrade = "8th grade",
  NinthGrade = "9th grade",
  TenthGrade = "10th grade",
  EleventhGrade = "11th grade",
  TwelfthGrade = "12th grade"
}

export const usGradeLevels = Object.values(USGradeLevel);

/**
 Max number of consecutive grade levels for a class
 */
export const MAX_GRADE_LEVELS = 4;
export const MAX_ONE_ON_ONE_GRADE_LEVELS = usGradeLevels.length;

export const gradeLevelSubjects = [
  "Science & Nature",
  "English",
  "Social Studies",
  "Math"
];

export const gradeLevelSort = (gradeLevels: Array<USGradeLevel>) =>
  gradeLevels.sort(
    (a, b) => usGradeLevels.indexOf(a) - usGradeLevels.indexOf(b)
  );

export const gradeLevelsAllConsecutive = (gradeLevels: Array<USGradeLevel>) => {
  const sortedGradeLevels = gradeLevelSort(gradeLevels);
  // compare adjacent grade levels to find any non-consecutive ones
  for (let i = 1; i < sortedGradeLevels.length; i++) {
    if (
      usGradeLevels.indexOf(sortedGradeLevels[i]) -
        usGradeLevels.indexOf(sortedGradeLevels[i - 1]) >
      1
    ) {
      return false;
    }
  }
  return true;
};

export const gradeLevelToAges = Object.freeze({
  [USGradeLevel.Preschool]: [3, 4],
  [USGradeLevel.PreKindergarten]: [4, 5],
  [USGradeLevel.Kindergarten]: [5, 6],
  [USGradeLevel.FirstGrade]: [6, 7],
  [USGradeLevel.SecondGrade]: [7, 8],
  [USGradeLevel.ThirdGrade]: [8, 9],
  [USGradeLevel.FourthGrade]: [9, 10],
  [USGradeLevel.FifthGrade]: [10, 11],
  [USGradeLevel.SixthGrade]: [11, 12],
  [USGradeLevel.SeventhGrade]: [12, 13],
  [USGradeLevel.EighthGrade]: [13, 14],
  [USGradeLevel.NinthGrade]: [14, 15],
  [USGradeLevel.TenthGrade]: [15, 16],
  [USGradeLevel.EleventhGrade]: [16, 17],
  [USGradeLevel.TwelfthGrade]: [17, 18]
});

export const gradeLevelsFromAge = (age: number) => {
  if (age === 3) {
    return [USGradeLevel.Preschool];
  } else if (age === 18) {
    return [USGradeLevel.TwelfthGrade];
  } else if (age > 3 && age < 18) {
    return [
      usGradeLevels[age - 4] as USGradeLevel,
      usGradeLevels[age - 3] as USGradeLevel
    ];
  } else {
    return [];
  }
};

/**
 * Returns the grade level the specified number of years before `gradeLevel`.
 * If years is too big, returns the minimum grade level (Preschool)
 * @param {USGradeLevel} gradeLevel
 * @param {number} years - The number of years
 * @returns {USGradeLevel} The resulting grade level, which is lower than or equal to the input
 */
export const subtractYears = (gradeLevel: USGradeLevel, years: number) => {
  return usGradeLevels[
    Math.max(0, usGradeLevels.indexOf(gradeLevel) - years)
  ] as USGradeLevel;
};
/**
 * Returns the grade level the specified number of years after `gradeLevel`.
 * If years is too big, returns the maximum grade level (TwelfthGrade)
 * @param {USGradeLevel} gradeLevel
 * @param {number} years - The number of years
 * @returns {USGradeLevel} The resulting grade level, which is higher than or equal to the input
 */
export const addYears = (gradeLevel: USGradeLevel, years: number) => {
  return usGradeLevels[
    Math.min(
      usGradeLevels.length - 1,
      usGradeLevels.indexOf(gradeLevel) + years
    )
  ] as USGradeLevel;
};

/**
 * Returns true if grade level a is after grade level b.
 * @param {USGradeLevel} a
 * @param {USGradeLevel} b
 * @returns {boolean}
 */
export const gradeLevelIsAfter = (a: USGradeLevel, b: USGradeLevel) => {
  return usGradeLevels.indexOf(a) > usGradeLevels.indexOf(b);
};

/**
 * Returns true if grade level a is before grade level b.
 * @param {USGradeLevel} a
 * @param {USGradeLevel} b
 * @returns {boolean}
 */
export const gradeLevelIsBefore = (a: USGradeLevel, b: USGradeLevel) => {
  return usGradeLevels.indexOf(a) < usGradeLevels.indexOf(b);
};

export const proficiencyLevelOptions = ["Beginner", "Intermediate", "Advanced"];

/**
 * @deprecated use: `const { languageOptions } = useActivityString()`
 */
export const languageOptions = ["English", "Spanish"];

/**
 * @deprecated use: `const { strictAgeRangeOptions } = useActivityString()`
 */
export const strictAgeRangeOptions = [
  { value: "FLEXIBLE", label: "Flexible Age Range" },
  { value: "STRICT", label: "Strict Age Range" }
];
export const strictAgeRangeMaxOffset = 4;

export const titleLengthMin = 10;
export const classTitleLengthMax = 80;
export const clubTitleLengthMax = 60;
export const summaryLengthMin = 40;
export const summaryLengthMax = 240;
export const classExperienceLengthMin = 300;

export const classAgeRangeMin = 5;
export const clubAgeRangeMin = 5;

export const sizeMin = 1;
export const sizeMax = 18;
export const sizeOptions = _.range(sizeMin, sizeMax + 1);

export const weeklyMeetingsOptions = _.range(1, 20 + 1);

export const minuteOptions = _.range(0, 55 + 5, 5);

export const hourOptions = _.range(0, 9 + 1, 1);

export const asyncTimeOptions = ["0", "0 - 1", "1 - 2", "2 - 4", "4+"];

export const totalClassesString = "140,000";

const minSpaces = 0;
const maxSpaces = 18;

export const sizeMaxOptions = (activity: { size_max: number }) =>
  _.range(minSpaces, offeredSpaces(activity) + 1);

export const offeredSpaces = (activity: { size_max: number }) =>
  Math.min(activity.size_max + 1, maxSpaces);

export const isArchived = (
  activity: Pick<ActivitiesRow, "archived_at"> | Pick<Activity, "archived_at">
) => Boolean(activity.archived_at);

export const isPublished = (
  activity: Pick<ActivitiesRow, "published_at"> | Pick<Activity, "published_at">
) => Boolean(activity?.published_at);

export const isRejected = (activity: Pick<Activity, "approvalStatus">) =>
  activity.approvalStatus && activity.approvalStatus.action === "reject";

export const isOnline = (
  activity: Pick<ActivitiesRow, "is_online"> | Pick<Activity, "is_online">
) => Boolean(activity.is_online);

export const isFlexSchedule = (
  activity:
    | Pick<ActivitiesRow, "weekly_meetings" | "duration_weeks">
    | Pick<Activity, "weekly_meetings" | "duration_weeks">
) => !activity?.weekly_meetings && !!activity?.duration_weeks;

export const isOneOnOne = (activity: Pick<Activity, "size_max">) =>
  activity.size_max === 1;

export const isSeries = (
  activity:
    | Pick<
        ActivitiesRow,
        "weekly_meetings" | "duration_weeks" | "is_ongoing_weekly" | "is_club"
      >
    | Pick<
        Activity,
        "weekly_meetings" | "duration_weeks" | "is_ongoing_weekly" | "isClub"
      >
) => !isFlexSchedule(activity) && !isClub(activity) && !isLiveOneTime(activity);

export const isOngoing = (
  activity:
    | Pick<ActivitiesRow, "is_ongoing_weekly">
    | Pick<Activity, "is_ongoing_weekly">
) => !!activity.is_ongoing_weekly;

export const isCamp = (activity: {
  weekly_meetings: number;
  duration_weeks: number;
  title: string;
}) =>
  (activity.weekly_meetings >= 2 && activity.title.includes("camp")) ||
  (activity.duration_weeks <= 7 && activity.weekly_meetings > 1);

export function shouldAlwaysEnableZoom(
  activity:
    | Pick<ActivitiesRow, "weekly_meetings">
    | Pick<Activity, "weekly_meetings">
) {
  return !!activity?.weekly_meetings;
}

export const ACTIVITY_STATUS = Object.freeze({
  LISTED: "Listed",
  ARCHIVED: "Archived",
  NOT_LISTED: "Not listed",
  REQUESTED_LISTING: "Requested listing"
} as const);

/**
 * @deprecated use: `const { status } = useActivityString()`
 */
export const status = (
  activity: Pick<
    Activity,
    "archived_at" | "published_at" | "requested_listing_at"
  > & {
    leaderDetails?: {
      requestedListingAt?: Date;
    };
    requested_listing_at?: Date;
  }
) => {
  if (isArchived(activity)) {
    return ACTIVITY_STATUS.ARCHIVED;
  } else if (isPublished(activity)) {
    return ACTIVITY_STATUS.LISTED;
  } else if (requestedListingAt(activity)) {
    return ACTIVITY_STATUS.REQUESTED_LISTING;
  } else {
    return ACTIVITY_STATUS.NOT_LISTED;
  }
};

export enum ACTIVITY_FORMAT {
  FLEX = "Flexible Schedule",
  ONGOING = "Ongoing",
  ONE_TIME = "One-Time",
  MULTI_DAY = "Multi-Day",
  CLUB = "Group",
  ONE_ON_ONE = "1-on-1",
  SELF_PACED = "Self-Paced Course"
}

export type ActivityFormatType =
  | Pick<
      ActivitiesRow,
      | "weekly_meetings"
      | "is_ongoing_weekly"
      | "duration_weeks"
      | "uses_teacher_scheduling"
    >
  | Pick<
      Activity,
      | "weekly_meetings"
      | "duration_weeks"
      | "is_ongoing_weekly"
      | "isClub"
      | "hasTeacherSchedule"
    >
  | Pick<
      Activity,
      "weekly_meetings" | "duration_weeks" | "is_ongoing_weekly" | "isClub"
    >;

export const format = (activity: ActivityFormatType) => {
  // @ts-ignore
  if (activity["isClub"] || activity["is_club"]) {
    return ACTIVITY_FORMAT.CLUB;
  } else if (
    // @ts-ignore
    activity["hasTeacherSchedule"] ||
    // @ts-ignore
    activity["uses_teacher_scheduling"]
  ) {
    return ACTIVITY_FORMAT.ONE_ON_ONE;
  } else if (isFlexSchedule(activity)) {
    return ACTIVITY_FORMAT.FLEX;
  } else if (!!activity.is_ongoing_weekly) {
    return ACTIVITY_FORMAT.ONGOING;
  } else if (isLiveOneTime(activity)) {
    return ACTIVITY_FORMAT.ONE_TIME;
  } else {
    return ACTIVITY_FORMAT.MULTI_DAY;
  }
};

export const formatString = (activity: ActivityFormatType) => {
  // @ts-ignore
  if (activity["hasTeacherSchedule"]) {
    return "1-on-1 Class";
  }

  // @ts-ignore
  if (activity["isClub"] || activity["is_club"]) {
    return "Group";
  }

  const formatClassOrCourse =
    format(activity) === ACTIVITY_FORMAT.ONE_TIME ? "Class" : "Course";
  return `${format(activity)} ${formatClassOrCourse}`;
};

/**
 * @deprecated use: `const { frequencyLanguage } = useActivityString()`
 */
export const frequencyLanguage = (numMeetings?: number) => {
  if (numMeetings === undefined) {
    return "";
  }
  if (numMeetings === 1) {
    return "Once per week";
  } else if (numMeetings === 2) {
    return "Twice per week";
  } else {
    return `${numMeetings}x per week`;
  }
};

/**
 * class size is determined at the activity level
 * Some sections may override size_min, size_max
 *
 * @deprecated use: `const { classSizeString } = useActivityString()`
 */

export function classSizeString(
  activity:
    | Pick<ActivitiesRow, "size_min" | "size_max">
    | Pick<Activity, "size_min" | "size_max">
) {
  const minSize = activity.size_min;
  const maxSize = activity.size_max;

  if (!minSize && !maxSize) {
    return "TBD";
  }

  let result = minSize || "1";
  if (maxSize) {
    if (maxSize === 1) {
      return "1";
    } else if (minSize === undefined || minSize === null || maxSize > minSize) {
      result += "-" + maxSize;
    }
  } else {
    result += " or more";
  }

  return result;
}

export function createClassDraft(
  title: string,
  email: string,
  age_min = 11,
  age_max = 14
) {
  return {
    title: title,
    details: {
      email: email
    },
    checklist: {},
    location: {},
    age_min,
    age_max,
    size_min: 2,
    size_max: 6,
    price_cents: 1000,
    duration_minutes: 55,
    duration_weeks: 1,
    weekly_meetings: 1,
    is_online: true,
    refund_policy_type: refund_policy.limited,
    is_club: false,
    allows_recurring_payment: false,
    allows_late_enrollments_for_fixed_length_classes: false,
    has_building_content: false,
    published_at: null,
    first_listed_at: null,
    archived_at: null
  };
}

export function createClubDraft(title: string, email: string) {
  return {
    title: title,
    details: {
      email: email
    },
    checklist: {},
    location: {},
    size_min: 0,
    // TODO: Allow users to enroll to clubs when size is null
    size_max: 100000000,
    price_cents: 500,
    is_online: true,
    refund_policy_type: refund_policy.limited,
    is_club: true,
    is_auto_scheduling_enabled: false,
    allows_recurring_payment: true,
    has_building_content: false
  };
}

export const isOwner = (
  activity:
    | Pick<ActivitiesRow, "user_uid">
    | Pick<Activity, "user_uid">
    | undefined,
  user: Pick<UsersRow, "uid"> | null
) => activity?.user_uid === user?.uid;

// Use Section.canManage if you are checking seller org teachers as well
export const canManage = (
  activity:
    | Pick<ActivitiesRow, "user_uid">
    | Pick<Activity, "user_uid">
    | undefined,
  userOrAuthToken:
    | Pick<UsersRow, "uid" | "is_admin">
    | Pick<UserType, "uid" | "is_admin">
    | null
) =>
  Boolean(userOrAuthToken) &&
  // @ts-ignore TS(2345): Argument of type 'Pick<UsersRow, "uid" | "is_admin... Remove this comment to see the full error message
  (User.isAdmin(userOrAuthToken) || isOwner(activity, userOrAuthToken));

export const canApprove = (userOrAuthToken: Auth.Auth) =>
  Auth.hasRole(userOrAuthToken, Auth.Roles.ClassApproval);

export const canView = (
  activity:
    | Pick<ActivitiesRow, "user_uid" | "published_at">
    | Pick<Activity, "user_uid" | "published_at">,
  user: Auth.Auth
) =>
  Boolean(activity?.published_at) ||
  isOwner(activity, user) ||
  User.isAdmin(user) ||
  Auth.hasRole(user, Auth.Roles.ClassApproval);

export const hasLocation = (
  activity: Pick<ActivitiesRow, "location"> | Pick<Activity, "location">
) =>
  Boolean(activity.location) &&
  Boolean(activity.location.city) &&
  activity.location.lat !== undefined &&
  activity.location.lng !== undefined;

export const isSemesterCourse = (activity: { duration_weeks: number }) =>
  activity.duration_weeks >= 8;

export const isLive = (activity: { weekly_meetings: number }) => {
  return !!activity?.weekly_meetings;
};

export const isShortCourse = (activity: { duration_weeks: number }) =>
  2 <= activity.duration_weeks && activity.duration_weeks <= 7;

export const isLongCourse = (activity: { duration_weeks: number }) =>
  activity.duration_weeks >= 8;

export const isAppropriateForAge = (
  activity:
    | Pick<ActivitiesRow, "age_min" | "age_max">
    | Pick<Activity, "age_min" | "age_max">,
  age: number
) =>
  (activity.age_min === null ||
    activity.age_min === undefined ||
    age >= activity.age_min) &&
  (activity.age_max === null ||
    activity.age_max === undefined ||
    age <= activity.age_max);

export const isAppropriateForAges = (
  activity:
    | Pick<ActivitiesRow, "age_min" | "age_max">
    | Pick<Activity, "age_min" | "age_max">,
  ages: number[] | string
) => {
  if (typeof ages === "string") {
    ages = ages.split(",").map(e => Number.parseInt(e, 10));
  }

  if (ages && ages.length) {
    return ages.reduce(function (prev, curr) {
      return prev || isAppropriateForAge(activity, curr);
    }, false);
  } else {
    return true;
  }
};

export const isFree = (
  activity: Pick<ActivitiesRow, "price_cents"> | Pick<Activity, "price_cents">
) => activity.price_cents === 0;

export function getDeliveryType(activity: {
  duration_weeks: number;
  weekly_meetings: number;
  title: string;
  is_ongoing_weekly: boolean;
  size_max: number;
}) {
  const values: DeliveryType[] = [];

  if (isCamp(activity)) {
    values.push(DeliveryType.Camp);
  }

  if (
    typeof activity.duration_weeks === "number" &&
    isSemesterCourse({ duration_weeks: activity.duration_weeks })
  ) {
    values.push(DeliveryType.SemesterCourse);
  }

  if (isLiveOneTime(activity)) {
    values.push(DeliveryType.OneTimeClass);
  }

  if (!!activity.is_ongoing_weekly) {
    values.push(DeliveryType.OngoingClass);
  }

  if (
    typeof activity.duration_weeks === "number" &&
    isShortCourse({ duration_weeks: activity.duration_weeks })
  ) {
    values.push(DeliveryType.ShortCourse);
  }

  if (isOneOnOne(activity)) {
    values.push(DeliveryType.OneOnOneClass);
  }

  return values;
}

/**
 *
 * @deprecated use: `const { ageRangeString } = useActivityString()`
 */
export function ageRangeString(
  activity:
    | Pick<ActivitiesRow, "age_min" | "age_max">
    | Pick<Activity, "age_min" | "age_max">,
  useAgesPrefix = true
) {
  const minAge = activity.age_min;
  const maxAge = activity.age_max;
  if (!minAge && !maxAge) {
    return "All ages";
  }

  const prefix = useAgesPrefix ? "Ages " : "";

  if (!minAge) {
    return prefix + maxAge + " or younger";
  }

  if (!maxAge) {
    return prefix + minAge + " and up";
  }

  return prefix + minAge + "-" + maxAge;
}

/**
 *
 * @deprecated use: `const { strictAgeRangeString } = useActivityString()`
 */
export function strictAgeRangeString(
  activity: Pick<Activity, "age_min" | "age_max" | "strictAgeRange">
) {
  const { age_min, age_max, strictAgeRange } = activity;
  if (
    age_min === null ||
    age_min === undefined ||
    age_max === null ||
    age_max === undefined ||
    strictAgeRange === null ||
    strictAgeRange === undefined
  ) {
    return "";
  }
  const minAge = age_min - strictAgeRange.min;
  const maxAge = age_max + strictAgeRange.max;

  if (!minAge) {
    return maxAge + " or younger";
  }

  if (!maxAge) {
    return minAge + " and up";
  }

  return minAge + "-" + maxAge;
}

type AgeType = {
  age_min?: number | null;
  age_max?: number | null;
};
type DBAgeType = AgeType &
  Pick<ActivitiesRow, "strict_age_min" | "strict_age_max">;
type GQLAgeType = AgeType & Pick<Activity, "strictAgeRange">;

function isGQLAgeType(ageType: DBAgeType | GQLAgeType): ageType is GQLAgeType {
  return ageType.hasOwnProperty("strictAgeRange");
}

/**
 * @param {ActivitiesRow|Activity} activity The activity to check for.
 * @param {number} learnerAge - The learner's age.
 * @param {boolean} sectionIsPublished - The learner's age.
 */
export const learnerOutsideStrictAgeRange = (
  activity: DBAgeType | GQLAgeType,
  ignoreStrictAgeRange: boolean,
  learnerAge: number
) => {
  const { age_min, age_max } = activity;
  if (
    age_min === null ||
    age_min === undefined ||
    age_max === null ||
    age_max === undefined
  ) {
    return false;
  }
  //Strict age ranges don't apply to private sections
  const strictAgeMin = isGQLAgeType(activity)
    ? activity.strictAgeRange?.min
    : activity.strict_age_min;
  const strictAgeMax = isGQLAgeType(activity)
    ? activity.strictAgeRange?.max
    : activity.strict_age_max;
  if (ignoreStrictAgeRange) {
    return false;
  }
  if (typeof strictAgeMin !== "number" || typeof strictAgeMax !== "number") {
    return false;
  }
  return (
    learnerAge < age_min - strictAgeMin || learnerAge > age_max + strictAgeMax
  );
};

export function requestedListingAt(activity: {
  leaderDetails?: {
    requestedListingAt?: Date;
  };
  requested_listing_at?: Date;
}) {
  return activity.leaderDetails
    ? activity.leaderDetails.requestedListingAt
    : activity.requested_listing_at;
}

export const MIN_PRICE = `$10 USD`;

/**
 * @deprecated use: `const { WELCOME_MESSAGE } = useActivityString()`
 */
export const WELCOME_MESSAGE = `Welcome! I'm excited that you joined this class. Would you please introduce yourself to me and the other learners? Remember to keep your private information private. For example, no last names, home cities, or gaming handles! I'd also love for you to share any background or questions you have in this topic area.

As a reminder, when posting in the classroom remember to follow the Outschool guidelines to be kind, safe, and respectful: ${participationGuidelinesUrl()}`;

export const WELCOME_MESSAGE_SELF_PACED = `Welcome! I'm excited that you joined this class. Remember to keep your private information private. For example, no last names, home cities, or gaming handles! I'd also love for you to share any background or questions you have in this topic area.

As a reminder, when posting in the classroom remember to follow the Outschool guidelines to be kind, safe, and respectful: ${participationGuidelinesUrl()}`;

// Keep in sync with server-rendered-website/src/website/shared/Activity.ts
// Used on the activity detail page and in ads
export const MAIN_IMAGE_WIDTH = 344;
export const MAIN_IMAGE_WIDTH_1_9_ASPECT_RATIO = 344;
export const MAIN_IMAGE_HEIGHT = 179;

export const SEASONAL_EMAIL_ACTIVITY_START_DATE = "2020-06-25";
export const SEASONAL_EMAIL_ACTIVITY_END_DATE = "2020-07-17";

export function isSoldOut(
  activity: Pick<
    Activity,
    "upcomingSectionCount" | "upcomingAvailableSectionCount"
  >
) {
  return (
    activity.upcomingSectionCount > 0 &&
    activity.upcomingAvailableSectionCount == 0
  );
}

/**
 * @deprecated use: the `isClub` field from the GQL type directly if on client, `is_club` column if on server
 */
export const isClub = (
  activity: Partial<Pick<Activity, "isClub"> & Pick<ActivitiesRow, "is_club">>
) => Boolean(activity.is_club) || Boolean(activity.isClub);

export const getMaxStrictAgeMin = (
  currentAgeRangeMin: number,
  ageMin: number
) =>
  !currentAgeRangeMin
    ? strictAgeRangeMaxOffset
    : Math.min(currentAgeRangeMin - ageMin, strictAgeRangeMaxOffset);

export const getMaxStrictAgeMax = (currentAgeRangeMax: number) =>
  !currentAgeRangeMax
    ? strictAgeRangeMaxOffset
    : Math.min(ageMax - currentAgeRangeMax, strictAgeRangeMaxOffset);

/**
 * @deprecated use: `const { DEFAULT_CLUB_GUIDELINES } = useActivityString()`
 */
export const DEFAULT_CLUB_GUIDELINES = `Welcome to our Group! We want all of our learners to feel comfortable, connected, and safe. That's why we have 3 rules we ask you to follow: Be Kind, Be Safe, and Be Respectful.

Be Kind - Help all learners feel welcome and included.
Learners from all over the world, with different backgrounds, races, ethnicities, abilities, gender identities, religions and body types, participate in our Group. Always be nice and make others, especially new learners, know that they belong and are welcome. Teasing, name calling, or bullying anybody in our community? Nope. Not happening here

Be Safe - Keep your personal information private and never ask other learners for theirs.
Personal things are personal. We want to keep you safe on Outschool, but we also want YOU to understand how to be safe when you're meeting other learners or teachers. Keep your full name, home address, gaming or social media name, email, and school name private.

Be Respectful - Treat others how you want to be treated.
Keep language clean and information you share safe. If you wouldn't say it or share with your grandma, don't do it here. Swear words, racy videos, and violent images are not allowed. Treat Outschool like a public place, and when in doubt, keep things covered.`;

export const LEARNER_COMMUNITY_CHAT_ACTIVITY_UID =
  "53f97d44-7bef-4082-8f43-145bac359865";

export const DEFAULT_ONGOING_DURATION_WEEK = 4;
export const AUTO_SCHEDULING_DAYS_NOTICE_MIN_DEFAULT = 14;
export const AUTO_SCHEDULING_DAYS_NOTICE_MAX_DEFAULT = 90;
export const AUTO_SCHEDULE_DAYS_NOTICE_MIN_MINIMUM_VALUE = 1;
export const AUTO_SCHEDULE_DAYS_NOTICE_MIN_MAXIMUM_VALUE = 21;

export const ACTIVITY_ENROLL_CLICK = "Activity Enroll Click";
export const ACTIVITY_WAITLIST_CLICK = "Activity Waitlist Click";

export function isActivityWorthyForSubscriptions<
  T extends Pick<ActivitiesRow, "is_self_paced" | "tags">
>(activity: T) {
  return activity.is_self_paced && activity.tags?.includes("Subscription");
}
