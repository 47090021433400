import { HOLDOUT } from "@outschool/experiments-shared";
import { useSession } from "@outschool/ui-auth";
import { useExperiment as useExperimentShared } from "@outschool/ui-experiments";

export default function useExperiment(
  experimentName: string,
  defaultVariant: string = HOLDOUT
) {
  const { currentUserHasLoaded } = useSession();
  return useExperimentShared({
    currentUserHasLoaded,
    experimentName,
    defaultVariant
  });
}
