import {
  Box,
  Button,
  Icon,
  SxProps,
  visuallyHidden
} from "@outschool/backpack";
import {
  faBars,
  faChalkboardTeacher,
  faChartLine,
  faGlobeRegular,
  faQuestionCircle,
  faSignIn,
  faUsersCog
} from "@outschool/icons";
import {
  BASE_LOCALE,
  useLocale,
  useTranslation
} from "@outschool/localization";
import {
  leadActivitiesPath,
  sellerOrgPath,
  teacherDashboardPath,
  teacherSchedulePath
} from "@outschool/routes";
import { useSession } from "@outschool/ui-auth";
import { TrackedButton } from "@outschool/ui-components-shared";
import {
  NavHelpLink,
  useShoppingCart,
  useShoppingCartExperiment
} from "@outschool/ui-components-website";
import { useIsTablet } from "@outschool/ui-utils";
import omit from "lodash/omit";
import React, { useState } from "react";

import { FeatureFlag } from "../../../shared/Env";
import * as Routes from "../../../shared/Routes";
import { usePricingOfferAdmin } from "../../hooks/CurrentUser";
import { useUserWalletSummary } from "../../hooks/useUserWalletSummary";
import { isBuyerOrgAdmin } from "../../lib/BuyerOrgAdmins";
import { useFeatureFlag } from "../../lib/FeatureFlags";
import { UserMode, useUserMode } from "../../lib/UserMode";
import JoinAndWelcomeButton from "../JoinAndWelcomeButton";
import Link from "../Link";
import LocalizationPickerMobile from "../localization/LocalizationPickerMobile";
import LocalizationPickerModal from "../localization/LocalizationPickerModal";
import { CartDesktopNavTab, DesktopNavTabs, Divider } from "./DesktopNavTabs";
import { HeaderMenu, HeaderMenuButtonProps } from "./HeaderMenu";
import { JoinSubscriptionNavItem } from "./JoinSubscriptionNavItem";
import { SubscriptionCreditNavItem } from "./SubscriptionCreditNavItem";
import { WalletNavItem } from "./WalletNavItem";

export const DesktopHeaderNavigation = React.memo(() => {
  const navItems = useNavItemsForUser();

  return (
    <>
      {navItems.map((NavItem, index) => (
        <NavItem key={index} />
      ))}
    </>
  );
});

const useNavItemsForUser = (): React.FunctionComponent<{}>[] => {
  const { isLoggedIn, currentUser, currentUserHasLoaded, isLeader } =
    useSession();
  const userMode = useUserMode();
  const {
    totalBalanceCents: walletBalance,
    loading: walletBalanceLoading,
    pricingOffers
  } = useUserWalletSummary();
  const { shouldShowCart } = useShoppingCartExperiment();
  const { cartItems } = useShoppingCart();
  const showCartNavItem =
    shouldShowCart && cartItems && cartItems.items.length > 0;

  const subscriptionEnabled = useFeatureFlag("subscription");

  const hasActiveSubscription = !!currentUser?.hasSubscription;

  const showWalletFunds =
    (walletBalance > 0 || pricingOffers.length > 0) && !walletBalanceLoading;

  const isOrgApplicant = !!currentUser?.latestSellerOrgApplication;

  const isSellerOrg = !!currentUser?.sellerOrg?.currentUserIsOwner;

  const buyerOrgAdminModeEnabled = useFeatureFlag(
    FeatureFlag.BuyerOrgAdminMode
  );

  const pricingOfferOrgId = usePricingOfferAdmin();

  const isBuyerOrgAdminMode =
    buyerOrgAdminModeEnabled && isBuyerOrgAdmin(currentUser);

  if (!isLoggedIn || !currentUserHasLoaded) {
    return [LocalizationButton, HelpButton, LoginButton, JoinButton];
  }

  if (isBuyerOrgAdminMode) {
    return [UserHeaderMenu];
  }

  if (userMode === UserMode.Educator) {
    return [
      ...(isSellerOrg ? [SellerOrgManageButton] : []),
      SwitchToParentUserMode,
      ...(pricingOfferOrgId ? [WalletDashboardButton] : []),
      UserHeaderMenu
    ];
  }

  return [
    ...(hasActiveSubscription
      ? [SubscriptionCreditNavItem]
      : subscriptionEnabled
      ? [JoinSubscriptionNavItem]
      : []),
    DesktopNavTabs,
    ...(isOrgApplicant ? [SellerOrgManageButton] : []),
    Divider,
    ...(showCartNavItem ? [CartDesktopNavTab] : []),
    ...(isLeader ? [TeachButton] : []),
    ...(pricingOfferOrgId ? [WalletDashboardButton] : []),
    ...(showWalletFunds ? [() => WalletNavItem({ sx: { my: 10 } })] : []),
    HelpButton,
    UserHeaderMenu
  ];
};

const NAV_BUTTON_SX: SxProps = {
  fontWeight: "fontWeightBold",
  color: "grey.900"
};

const TeachButton = () => {
  const { currentUser } = useSession();
  const { t } = useTranslation("client\\components\\nav\\DesktopHeaderNav");

  const isSellerOrg = !!currentUser?.sellerOrg?.currentUserIsOwner;
  const isLeader = !!currentUser?.leader;

  return (
    <TrackedButton
      variant="link"
      component={Link}
      to={
        isSellerOrg
          ? teacherSchedulePath()
          : isLeader
          ? teacherDashboardPath()
          : leadActivitiesPath()
      }
      trackingName="nav_teach_link"
      startIcon={<Icon icon={faChalkboardTeacher} />}
      sx={{
        fontWeight: "fontWeightBold",
        color: "grey.900",
        py: 10
      }}
      aria-label={t("teach at outschool")}
    >
      {t("Teach")}
    </TrackedButton>
  );
};

const SellerOrgManageButton = () => {
  const { t } = useTranslation("client\\components\\nav\\DesktopHeaderNav");
  return (
    <TrackedButton
      variant="link"
      component={Link}
      to={sellerOrgPath()}
      trackingName="nav_manage_link"
      startIcon={<Icon icon={faUsersCog} />}
      sx={NAV_BUTTON_SX}
      aria-label={t("manage organization")}
    >
      {t("Manage")}
    </TrackedButton>
  );
};

const HelpButton = () => {
  const { t } = useTranslation("client\\components\\nav\\DesktopHeaderNav");
  const locale = useLocale();
  const hideLabel = locale === BASE_LOCALE;

  return (
    <NavHelpLink
      fullWidth={false}
      sx={{
        ...NAV_BUTTON_SX,
        "> span": {
          marginRight: hideLabel ? 0 : undefined
        }
      }}
      aria-label={t("help")}
    >
      <Icon icon={faQuestionCircle} size="large" sx={{ fontSize: "2.25rem" }} />
      {/* Supply a fragment so the NavHelpLink doesn't render default button text */}
      {hideLabel ? <></> : t("Help")}
    </NavHelpLink>
  );
};

const LoginButton = () => {
  const { t } = useTranslation("client\\components\\nav\\DesktopHeaderNav");
  return (
    <Button
      variant="link"
      component={Link}
      to={Routes.loginPath()}
      trackingName="nav_login_link"
      startIcon={<Icon icon={faSignIn} />}
      sx={NAV_BUTTON_SX}
      aria-label={t("log in")}
    >
      {t("Log In")}
    </Button>
  );
};

const WalletDashboardButton = () => {
  const { t } = useTranslation("client\\components\\nav\\DesktopHeaderNav");
  // usePricingOfferAdmin is also used in parent, but will pull from cache so there's no additional query
  const pricingOfferOrgId = usePricingOfferAdmin();
  if (!pricingOfferOrgId) {
    return null;
  }

  return (
    <TrackedButton
      variant="link"
      component={Link}
      to={Routes.pricingOfferAdminDashboardForOrganizationPath(
        pricingOfferOrgId
      )}
      trackingName="nav_wallet_dashboard_link"
      startIcon={<Icon icon={faChartLine} />}
      sx={NAV_BUTTON_SX}
      aria-label={t("pricing offer admin dashboard")}
    >
      {t("Dashboard")}
    </TrackedButton>
  );
};

const UserHeaderMenu = React.memo(() => {
  const { t } = useTranslation("client\\components\\nav\\DesktopHeaderNav");
  const [numCloseCount, setNumCloseCount] = React.useState(0);

  return (
    <HeaderMenu
      onClose={() => {
        setNumCloseCount(prev => prev + 1);
      }}
    >
      {({ buttonRef, ...props }: HeaderMenuButtonProps) => (
        <TrackedButton
          variant="link"
          trackingName="desktop_header_menu_open_button"
          trackingUniqueId={`desktop_header_menu_open_button-${numCloseCount}`}
          trackingSharedProperties={{
            numCloseCount
          }}
          aria-label={t("Menu")}
          ref={buttonRef}
          //eslint-disable-next-line i18next/no-literal-string
          {...omit(props, "isOpen")}
        >
          <Icon
            icon={faBars}
            sx={theme => ({
              fontSize: "2.25rem",
              color: theme.palette.common.black
            })}
          />
        </TrackedButton>
      )}
    </HeaderMenu>
  );
});

const SwitchToParentUserMode = () => {
  const { t } = useTranslation("client\\components\\nav\\DesktopHeaderNav");
  return (
    <TrackedButton
      variant="link"
      component={Link}
      to={Routes.parentRootPath()}
      trackingName="nav_parent_link"
      sx={NAV_BUTTON_SX}
      aria-label={t("switch to parent")}
    >
      {t`Switch to Parent`}
    </TrackedButton>
  );
};

const JoinButton = () => {
  const { t } = useTranslation("client\\components\\nav\\DesktopHeaderNav");
  return (
    <JoinAndWelcomeButton
      sx={{ height: 44, alignSelf: "center" }}
    >{t`Join`}</JoinAndWelcomeButton>
  );
};

function LocalizationButton() {
  const { t } = useTranslation("client\\components\\nav\\DesktopHeaderNav");
  const isTablet = useIsTablet();

  const [localizationMenuOpen, setLocalizationMenuOpen] =
    useState<boolean>(false);
  const openLocalizationMenu = () => setLocalizationMenuOpen(true);
  const closeLocalizationMenu = () => setLocalizationMenuOpen(false);

  const a11yLabel = t`Open currency, time zone, and language settings`;

  return (
    <>
      {
        <Box
          data-test-id="header-localization-picker"
          onClick={openLocalizationMenu}
          display="flex"
          alignItems="center"
        >
          <button
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              color: "grey.900",
              padding: "0"
            }}
          >
            <span style={visuallyHidden}>{a11yLabel}</span>
            <Icon
              icon={faGlobeRegular}
              size="large"
              sx={{ fontSize: "2.175rem" }}
            />
          </button>
        </Box>
      }
      {isTablet ? (
        <LocalizationPickerMobile
          open={localizationMenuOpen}
          onClose={closeLocalizationMenu}
          onBack={closeLocalizationMenu}
          standalone
        />
      ) : (
        <LocalizationPickerModal
          open={localizationMenuOpen}
          onClose={closeLocalizationMenu}
        />
      )}
    </>
  );
}
