import { gql } from "@outschool/ui-apollo";

export const ParentFragment = gql`
  fragment ParentFragment on Parent {
    uid
    name
    photo
    publicName
    introduction
    created_at
    deleted_at
    timeZone
    socialOptOut
    consent_to_share_class_history
    consent_to_share_favourites
  }
`;

export const ParentLeaderFragment = gql`
  fragment ParentLeaderFragment on Parent {
    uid
    name
    photo
    timeZone
  }
`;

export const ParentAdminFragment = gql`
  fragment ParentAdminFragment on Parent {
    uid
    name
    email
    photo
    timeZone
  }
`;
