import { Alert, Box, Typography } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import { useQueryWithPreviousData } from "@outschool/ui-apollo";
import { ExternalLink } from "@outschool/ui-components-shared";
import React from "react";

import HeaderTags from "../components/HeaderTags";
import SimpleLayout from "../components/layouts/SimpleLayout";
import SellerOrgManageToolbar from "../components/nav/SellerOrgManageToolbar";
import StickyMobileHeader from "../components/nav/StickyMobileHeader";
import CredentialItem from "../components/TeacherCredentials/CredentialItem";
import { teacherCredentialsQuery } from "../queries/TeacherCredentialsQueries";

export default function SellerOrgCredentialsPage() {
  const { t } = useTranslation("client\\routes\\SellerOrgCredentialsPage");
  const title = t("Organization Credentials");
  const savedCertificatesTitle = t("Saved degrees and certificates");
  const { data } = useQueryWithPreviousData(teacherCredentialsQuery);
  const credentials = data?.teacherCredentials || [];

  return (
    <SimpleLayout>
      <HeaderTags title={title} />
      <StickyMobileHeader />
      <SellerOrgManageToolbar />
      <Box
        sx={{
          margin: "0 auto",
          maxWidth: "1120px"
        }}
      >
        {credentials.length > 0 ? (
          <>
            <Box
              sx={{
                borderBottom: "1px solid",
                borderColor: "grey.300",
                paddingBottom: "48px"
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  paddingTop: "70px"
                }}
              >
                {savedCertificatesTitle}
              </Typography>

              {credentials.map((credential: $TSFixMe, index: $TSFixMe) => (
                <CredentialItem
                  hideDelete={true}
                  credential={credential}
                  key={index}
                ></CredentialItem>
              ))}
            </Box>
          </>
        ) : (
          <Alert severity="info" sx={{ margin: "50px" }}>
            <Typography variant="inherit">
              {t(
                "It looks like there are no credentials uploaded by your teachers yet. To ensure that all necessary documents are properly uploaded, please reach out to your teachers and remind them to upload their credentials as soon as possible. Reminder that all teachers under your organization that have completed all required trainings and have a non-expired background check must upload their credentials (and keep them up to date) in order for your listings to be eligible for ESA (education savings account) enrollments from ESA programs that have credential requirements. You can read more"
              )}
              <ExternalLink url="https://support.outschool.com/en/articles/8800195-managing-classwallet-and-esa-enrollments-a-quick-guide-for-educators">
                {t(" here ")}
              </ExternalLink>{" "}
              {t("for more information.")}
            </Typography>
          </Alert>
        )}
      </Box>
    </SimpleLayout>
  );
}
