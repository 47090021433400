import {
  Box,
  InAppSearchBox,
  SearchBoxComponentType,
  visuallyHidden
} from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import {
  SearchItem,
  SearchSuggestionsMenu
} from "@outschool/ui-components-website";
import { useComponentTrackingContext } from "@outschool/ui-legacy-component-library";
import { SystemStyleObject } from "@styled-system/css";
import React, { useEffect } from "react";

import { ExtendedSearchFiltersType } from "../../../lib/useSearchFilters";
import ClassSearchForm from "../ClassSearchForm";
import useSearchWithSuggestions, {
  queryFromSearchItem
} from "../useSearchWithSuggestions";

export default function DesktopSearchField({
  searchPlaceholder,
  searchFilters,
  setSearchFilters,
  setImplicitSearchFilters,
  clearDropdowns,
  searchSuggestionsToIgnore = [],
  experimentTrigger,
  SearchBoxComponent = InAppSearchBox,
  searchLabel,
  showSearchSuggestions = false,
  menuStyleSx
}: {
  searchPlaceholder?: string;
  searchFilters: ExtendedSearchFiltersType;
  setSearchFilters: (filters: ExtendedSearchFiltersType) => void;
  setImplicitSearchFilters: (filters: ExtendedSearchFiltersType) => void;
  clearDropdowns?: () => void;
  searchSuggestionsToIgnore?: Array<string>;
  experimentTrigger?: () => void;
  SearchBoxComponent?: SearchBoxComponentType;
  searchLabel?: string;
  showSearchSuggestions: boolean;
  menuStyleSx?: SystemStyleObject;
}) {
  const { t } = useTranslation(
    "client\\components\\search\\Desktop\\DesktopSearchField"
  );
  const track = useComponentTrackingContext();

  const {
    getComboboxProps,
    getLabelProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    inputValue,
    setInputValue,
    onInputFocus,
    loadingSuggestions,
    suggestionsOpen,
    searchSuggestions,
    suggestionsHighlightedIndex,
    error,
    closeMenu,
    onUserSubmit
  } = useSearchWithSuggestions({
    onSearchFieldChange: item =>
      setImplicitSearchFilters(queryFromSearchItem(item, searchFilters)),
    onSearchFieldCommit: item => {
      if (!item.name) {
        return;
      }
      setSearchFilters(queryFromSearchItem(item, searchFilters));
      onUserSubmit(item);
    },
    queryOptions: { ignore: searchSuggestionsToIgnore },
    showSearchSuggestions
  });

  useEffect(() => {
    setInputValue(searchFilters.q || searchFilters.userName || "");
  }, [setInputValue, searchFilters.q, searchFilters.userName]);

  useEffect(() => {
    if (!loadingSuggestions && error == null && searchSuggestions.length > 0) {
      experimentTrigger?.();
    }
  }, [loadingSuggestions, error, searchSuggestions, experimentTrigger]);

  useEffect(() => {
    // detect on enter/return key press
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        clearDropdowns?.();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [clearDropdowns]);

  const isTeacherSearch = !!searchFilters.userUid;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        position: "relative",
        "* > input": {
          fontWeight: isTeacherSearch ? 500 : "unset"
        },
        width: "100%"
      }}
      {...getComboboxProps()}
    >
      <span style={visuallyHidden} {...getLabelProps()}>
        {t("Search for classes:")}
      </span>
      <ClassSearchForm
        SearchBoxComponent={SearchBoxComponent}
        query={inputValue}
        setQuery={setInputValue}
        placeholder={searchPlaceholder}
        onInputFocus={() => {
          // @ts-ignore TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          clearDropdowns();
          onInputFocus();
        }}
        getInputProps={getInputProps}
        onSubmit={query => {
          if (suggestionsOpen) {
            closeMenu();
          }

          const searchItem: SearchItem = {
            // eslint-disable-next-line i18next/no-literal-string
            type: "keyword",
            name: query || undefined,
            uid: ""
          };

          setSearchFilters(queryFromSearchItem(searchItem, searchFilters));

          onUserSubmit(searchItem);

          // @ts-ignore TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          clearDropdowns();
        }}
        spellCheck={!searchFilters.userUid}
        searchLabel={searchLabel}
      />
      <SearchSuggestionsMenu
        getMenuProps={getMenuProps}
        isOpen={suggestionsOpen}
        searchSuggestions={searchSuggestions}
        loadingSuggestions={loadingSuggestions}
        highlightedIndex={suggestionsHighlightedIndex}
        getItemProps={getItemProps}
        keyword={inputValue}
        menuStyle={{
          top: "68px",
          minWidth: 291,
          width: "100%",
          zIndex: 1000,
          ...menuStyleSx
        }}
        track={track}
      />
    </Box>
  );
}
