// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { getReferrerAttribution } from "@outschool/attribution";
import {
  OAUTH2_CAN_CREATE_USER,
  OAUTH2_CREATE_OPTIONS,
  OAUTH2_ERROR,
  OAUTH2_IS_NEW_USER,
  OAUTH2_ON_ERROR,
  OAUTH2_ON_SUCCESS,
  OAUTH2_REFRESH_TOKEN,
  OAUTH2_SESSION_TOKEN,
  decodeToken,
  isLearnerAuthTransfer
} from "@outschool/auth-shared";
import { CreateOrLoginError } from "@outschool/gql-backend-generated";
import {
  LoginOrCreateAccountWithAppleV2Mutation as LoginOrCreateAccountWithAppleV2MutationType,
  LoginOrCreateAccountWithAppleV2MutationVariables
} from "@outschool/gql-frontend-generated";
import { useLookupIP } from "@outschool/iplookup-client";
import {
  browseRootPath,
  joinParentAppleAccountPath,
  joinParentFacebookAccountPath,
  joinParentGoogleAccountPath,
  redirectAfterLoginUrl
} from "@outschool/routes";
import * as Time from "@outschool/time";
import { useAnalytics, useTrackEvent } from "@outschool/ui-analytics";
import { useMutation } from "@outschool/ui-apollo";
import {
  AppleAuthResponse,
  SupportedStrategies,
  getAuthStrategy,
  loginOrCreateAccountWithAppleMutationV2,
  resetAuthStrategy,
  setAuthStrategy,
  useOAuth2TrackingParams,
  useTokenContext
} from "@outschool/ui-auth";
import { useNavigation } from "@outschool/ui-utils";
import Cookies from "js-cookie";
import React from "react";

import { useAppState } from "../stores/AppStateProvider";

interface CreateParams {
  isGiftCardSignup?: boolean;
  isLeader?: boolean;
  trackingParameters?: { [key: string]: string };
  isOnboardingSignup?: boolean;
  shouldCreateDefaultLearner?: boolean;
  defaultLearnerAge?: number;
}

interface AppleLoginSuccessPayload {
  sessionToken: string;
  refreshToken: string;
  postLoginPath: string;
  isLearnerTransfer: boolean;
}

export function useLoginWithApple(
  onSuccess: (payload: AppleLoginSuccessPayload) => void,
  onError: (error: CreateOrLoginError) => void,
  createParams?: CreateParams,
  postLoginPathParam?: string
) {
  const [loginWithAppleV2] = useMutation<
    LoginOrCreateAccountWithAppleV2MutationType,
    LoginOrCreateAccountWithAppleV2MutationVariables
  >(loginOrCreateAccountWithAppleMutationV2);
  const appState = useAppState();
  const trackEvent = useTrackEvent();
  const analytics = useAnalytics();

  return React.useCallback(
    async (response: AppleAuthResponse) => {
      const { code } = response.authorization;
      const createOptions = {
        attribution: await analytics.attribution(),
        browserTimeZone: Time.guessBrowserTimeZone(),
        isGiftCardSignup: createParams?.isGiftCardSignup,
        isLeader: createParams?.isLeader,
        isOnboardingSignup: createParams?.isOnboardingSignup,
        shouldCreateDefaultLearner: createParams?.shouldCreateDefaultLearner,
        defaultLearnerAge: createParams?.defaultLearnerAge
      };
      const name = response.user
        ? `${response.user.name.firstName} ${response.user.name.lastName}`
        : undefined;
      const anonymousId = await analytics.anonymousId();
      const mutationResponse = await loginWithAppleV2({
        variables: {
          code,
          name,
          createOptions,
          osRef: {
            ...getReferrerAttribution(),
            anonymousId
          }
        }
      });
      const result = mutationResponse.data?.loginOrCreateAccountWithAppleV2;
      if (result?.__typename === "LoginError") {
        onError(result.error);
      } else {
        const {
          isNewUser,
          authentication: { sessionToken, refreshToken }
        } = result!;
        if (isNewUser && createParams?.trackingParameters) {
          trackEvent("signup-by-apple", createParams.trackingParameters);
        }
        const decodedToken = decodeToken(sessionToken);
        const isLearnerTransfer = isLearnerAuthTransfer(decodedToken)
          ? decodedToken.isLearnerTransfer
          : false;
        const postLoginPath = isNewUser
          ? joinParentAppleAccountPath()
          : postLoginPathParam || appState.postLoginPath || browseRootPath();
        onSuccess({
          sessionToken,
          refreshToken,
          postLoginPath,
          isLearnerTransfer
        });
      }
    },
    [
      analytics,
      appState.postLoginPath,
      createParams?.isGiftCardSignup,
      createParams?.isLeader,
      createParams?.trackingParameters,
      createParams?.isOnboardingSignup,
      createParams?.shouldCreateDefaultLearner,
      createParams?.defaultLearnerAge,
      loginWithAppleV2,
      onError,
      onSuccess,
      postLoginPathParam,
      trackEvent
    ]
  );
}

// Exported here for easier test setup
export const setAuthStrategyWrapped = setAuthStrategy;

export function useLoginWithRedirect(
  onError: (error: CreateOrLoginError) => void,
  createParams?: CreateParams,
  postLoginAction?: string,
  postLoginPath?: string
) {
  const { getTokens } = useTokenContext();
  const appState = useAppState();
  const { setTrackingParameters } = useOAuth2TrackingParams();
  const { ipInfoLoaded, isInGDPR } = useLookupIP();
  const analytics = useAnalytics();

  React.useEffect(() => {
    const authError = Cookies.get(OAUTH2_ERROR);
    if (onError && authError) {
      Cookies.remove(OAUTH2_ERROR);
      onError(authError as CreateOrLoginError);
    }
  }, [onError]);

  React.useEffect(() => {
    async function fn() {
      Cookies.set(OAUTH2_ON_ERROR, window.location.href);
      Cookies.set(OAUTH2_ON_SUCCESS, redirectAfterLoginUrl());
      Cookies.set(OAUTH2_CAN_CREATE_USER, "true");
      Cookies.set(
        OAUTH2_CREATE_OPTIONS,
        JSON.stringify({
          attribution: await analytics.attribution(),
          browserTimeZone: Time.guessBrowserTimeZone(),
          isGiftCardSignup: createParams?.isGiftCardSignup,
          isLeader: createParams?.isLeader,
          subscribe: ipInfoLoaded ? !isInGDPR : false,
          isOnboardingSignup: createParams?.isOnboardingSignup,
          shouldCreateDefaultLearner: createParams?.shouldCreateDefaultLearner,
          defaultLearnerAge: createParams?.defaultLearnerAge
        })
      );
      const { sessionToken, refreshToken } = getTokens();
      if (sessionToken) {
        Cookies.set(OAUTH2_SESSION_TOKEN, sessionToken);
      } else {
        Cookies.remove(OAUTH2_SESSION_TOKEN);
      }
      if (refreshToken) {
        Cookies.set(OAUTH2_REFRESH_TOKEN, refreshToken);
      } else {
        Cookies.remove(OAUTH2_REFRESH_TOKEN);
      }
      if (createParams?.trackingParameters) {
        setTrackingParameters(createParams?.trackingParameters);
      }
    }
    fn();
  }, [
    analytics,
    appState,
    createParams?.isGiftCardSignup,
    createParams?.isLeader,
    createParams?.trackingParameters,
    createParams?.isOnboardingSignup,
    createParams?.shouldCreateDefaultLearner,
    createParams?.defaultLearnerAge,
    setTrackingParameters,
    getTokens,
    ipInfoLoaded,
    isInGDPR
  ]);

  return React.useCallback(
    (strategy: SupportedStrategies) => {
      setAuthStrategyWrapped(
        strategy,
        postLoginAction || appState.postLoginAction,
        postLoginPath || appState.postLoginPath
      );
    },
    [appState, postLoginPath, postLoginAction]
  );
}

export function useTrackAndRedirectAfterLogin() {
  const appState = useAppState();
  const { trackingParameters, resetTrackingParameters } =
    useOAuth2TrackingParams();
  const trackEvent = useTrackEvent();
  const navigate = useNavigation();
  React.useEffect(
    () => {
      const isNewUser = Boolean(Cookies.get(OAUTH2_IS_NEW_USER));
      Cookies.remove(OAUTH2_IS_NEW_USER);
      const { strategy } = getAuthStrategy();
      if (isNewUser) {
        trackEvent(`signup-by-${strategy}`, trackingParameters);
        if (strategy === "facebook") {
          navigate(joinParentFacebookAccountPath());
        } else if (strategy === "google") {
          navigate(joinParentGoogleAccountPath());
        } else {
          console.error("Unknown auth strategy:", strategy);
          appState.redirectAfterLogin(browseRootPath());
        }
      } else {
        appState.redirectAfterLogin(browseRootPath());
      }
      resetTrackingParameters();
      resetAuthStrategy(strategy);
    },
    // Only run once on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}
