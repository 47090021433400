import { dayjs } from "@outschool/time";

// How many days after the section ended a user can still provide feedback
export const REVIEWS_TIME_LIMIT_IN_DAYS = 30;

// How many days after the feedback was created it can be deleted
export const DELETE_TIME_LIMIT_IN_DAYS = 30;

// How many days after the feedback was created a user can still respond
export const RESPONSE_TIME_LIMIT_IN_DAYS = 30;

export const isInResponseTimeWindow = (review: { created_at: Date }) => {
  return dayjs(review.created_at)
    .add(RESPONSE_TIME_LIMIT_IN_DAYS, "days")
    .isAfter();
};

export const isInDeleteTimeWindow = (review: { created_at: Date }) => {
  return dayjs().diff(review.created_at, "days") < DELETE_TIME_LIMIT_IN_DAYS;
};
