import * as Env from "../../Env";

function useCurrencyEnrollmentEnabled(): boolean {
  return currencyEnrollmentEnabled();
}

export const currencyEnrollmentEnabled = () => {
  return Boolean(Env.FEATURE_FLAGS.CURRENCY_ENROLLMENT);
};

export default useCurrencyEnrollmentEnabled;
