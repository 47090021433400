import { useLocalStorageState } from "@outschool/local-storage";
import { OUTSCHOOL_TIMEZONE, guessBrowserTimeZone } from "@outschool/time";
import { useSession } from "@outschool/ui-auth";
import React from "react";

import ActionType from "../actions/ActionType";
import { useUpdateCurrentUserMutation } from "../queries/CurrentUser";
import { useAppState } from "../stores/AppStateProvider";

const TIME_ZONE_LOCAL_STORAGE_KEY = "timeZone";

const TimeZoneContext = React.createContext<{
  timeZone: string;
  setTimeZone: (arg0: string) => Promise<void>;
}>({
  timeZone: OUTSCHOOL_TIMEZONE,
  setTimeZone: async () => {}
});

export const TimeZoneProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { isLoggedIn, userTimeZone } = useSession();
  const [updateCurrentUser] = useUpdateCurrentUserMutation();
  const appState = useAppState();

  const [localStorageTimeZone, setLocalStorageTimeZone] =
    // Default to browser TZ if no previously stored TZ.
    useLocalStorageState<string>(
      TIME_ZONE_LOCAL_STORAGE_KEY,
      guessBrowserTimeZone()
    );

  const timeZone = isLoggedIn ? userTimeZone : localStorageTimeZone;

  const setTimeZone = React.useCallback(
    async (newTimeZone: string) => {
      if (newTimeZone !== timeZone) {
        if (isLoggedIn) {
          const { data } = await updateCurrentUser({
            variables: { location: { timeZone: newTimeZone } },
            refetchQueries: ["ClassDetailsSections"]
          });
          if (data) {
            appState.appDispatcher.dispatch(
              ActionType.User.CURRENT_USER_UPDATED,
              {
                currentUser: data.updateCurrentUser,
                property: "time_zone",
                value: newTimeZone
              }
            );
          }
        }
        setLocalStorageTimeZone(newTimeZone);
      }
    },
    [updateCurrentUser, appState, isLoggedIn, timeZone, setLocalStorageTimeZone]
  );

  return (
    <TimeZoneContext.Provider
      value={{
        // @ts-ignore TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        timeZone,
        setTimeZone
      }}
    >
      {children}
    </TimeZoneContext.Provider>
  );
};

export const useTimeZone = () => React.useContext(TimeZoneContext);
