import {
  BASE_LOCALE,
  SUPPORTED_LOCALES,
  useLocale
} from "@outschool/localization";
import { NavigateFunction } from "@outschool/ui-utils";
import React from "react";
import { matchPath, useHistory } from "react-router";

const URL_SUPPORTED_LOCALES = SUPPORTED_LOCALES.filter(l => l !== BASE_LOCALE);

/** Utility function for performing window navigations */
function navigateWithWindow(to: string, replace?: boolean, newTab?: boolean) {
  /* eslint-disable no-restricted-syntax */
  newTab
    ? window.open(to, "_blank")
    : window.location[replace ? "replace" : "assign"](to);
}

const OPTIONAL_ROUTE_LOCALE_PATH = `/:locale(${URL_SUPPORTED_LOCALES.join(
  "|"
)})?/*`;

/**
 * A website-specific implementation of `useNavigation`. Each client-side app that uses
 * the NavigationProvider should have its own implementation of this.
 *
 * DO NOT IMPORT DIRECTLY. Import `useNavigation` from `@outschool/ui-utils` instead.
 */
export function useWebsiteNavigation() {
  const history = useHistory();
  const locale = useLocale();

  return React.useCallback<NavigateFunction>(
    /**
     * Navigate to the URL provided. Uses window methods (`open`, `location.assign`, etc) for internal
     * URLS, and react-router (`history` methods) for internal URLs. Retains the current site locale.
     * @param linkTo URL to visit
     * @param options Config options
     * @param options.hardNav Override to use window methods (fully reload the page) even for internal URLS
     * @param options.newTab When using window navigation, open URL in a new tab (default is same tab)
     * @param options.replace Replace the current history entry instead of adding a new one. Applies to both
     * `window` and `history` navigation. Disables using the back button to return to the previous URL.
     */
    function navigate(linkTo, { hardNav, replace, newTab } = {}) {
      // For external URLs, always use window navigation
      if (/^https?/.test(linkTo)) {
        navigateWithWindow(linkTo, replace, newTab);
      } else {
        // Allow home page navigation with "" or "/"
        let path: string;
        if (linkTo === "") {
          path = "/";
        } else {
          path = linkTo;
        }

        // Extract the locale from the URL (https://v5.reactrouter.com/web/api/matchPath)
        const match = matchPath<Record<string, string>>(path, {
          path: OPTIONAL_ROUTE_LOCALE_PATH
        });
        // Throw an error if the path could not be parsed
        if (!match) {
          throw new Error(`Invalid OS route passed to navigate(): "${path}"`);
        }

        const { locale: routeLocale } = match.params;
        // Use window methods if `hardNav` is passed, otherwise use react-router
        if (hardNav) {
          // Add the current locale if none is present
          const to = routeLocale
            ? linkTo
            : locale === BASE_LOCALE
            ? linkTo || "/"
            : `/${locale}${linkTo}`;
          navigateWithWindow(to, replace, newTab);
        } else {
          // React-router will prepend the locale if needed
          history[replace ? "replace" : "push"](linkTo);
        }
      }
    },
    [locale, history]
  );
}
