import {
  Box,
  Button,
  Checkbox,
  Modal,
  ModalContent,
  Typography
} from "@outschool/backpack";
import { Trans, useTranslation } from "@outschool/localization";
import { privacyPath, termsPath } from "@outschool/routes";
import { Integration, useTrackEvent } from "@outschool/ui-analytics";
import { useSession } from "@outschool/ui-auth";
import { useLinkComponent } from "@outschool/ui-utils";
import React, { useCallback, useEffect, useState } from "react";

import { useUpdateCurrentUserMutation } from "../queries/CurrentUser";

export const ONE_TAP_SIGNUP_STORAGE_KEY = "showOneTapConsentModal";

interface OneTapConsentModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function OneTapConsentModal({
  setShowModal
}: OneTapConsentModalProps) {
  const { t } = useTranslation("client\\components\\OneTapConsentModal");
  const { currentUser } = useSession();
  const Link = useLinkComponent();
  const trackEvent = useTrackEvent();
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [updateCurrentUser] = useUpdateCurrentUserMutation();

  const hasCurrentUser = !!currentUser;
  useEffect(() => {
    if (hasCurrentUser) {
      trackEvent("view_one_tap_terms");
    }
  }, [hasCurrentUser, trackEvent]);

  const onModalClose = useCallback(async () => {
    if (isSubscribing) {
      await updateCurrentUser({
        variables: { subscribe: true }
      });
      trackEvent(
        "Updated preference",
        { property: "subscribe" },
        {
          integrations: {
            [Integration.Intercom]: true
          }
        }
      );
    }
    trackEvent("accept_one_tap_terms", { subscribed: isSubscribing });
    setShowModal(false);
  }, [isSubscribing, updateCurrentUser, trackEvent, setShowModal]);

  if (hasCurrentUser) {
    return (
      <Modal open={true} sx={{ marginX: "1em" }}>
        <ModalContent sx={{ maxWidth: 450 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingY: "1em"
            }}
          >
            <Typography
              variant="h5"
              sx={{ marginBottom: "1.5em", fontWeight: 700 }}
            >{t`Welcome to Outschool!`}</Typography>
            <Trans t={t}>
              <Typography sx={{ color: "gray.600", textAlign: "center" }}>
                By clicking “Agree and Continue”, I agree to Outschool’s{" "}
                <Link target="_blank" to={termsPath()}>
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link target="_blank" to={privacyPath()}>
                  Privacy Policy
                </Link>
                .
              </Typography>
            </Trans>
            {!currentUser.subscribed_at && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginTop: "1.5em"
                }}
              >
                <Checkbox
                  checked={isSubscribing}
                  onChange={e => setIsSubscribing(e.target.checked)}
                  sx={{ paddingTop: 0, paddingLeft: 0 }}
                />
                <Typography sx={{ color: "gray.600" }}>
                  {t`I agree to receive marketing communications, including recommendations and offers, from Outschool. Opt-out anytime by going to your setting preferences.`}
                </Typography>
              </Box>
            )}
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={onModalClose}
              sx={{ marginTop: "1.5em" }}
            >{t`Agree and Continue`}</Button>
          </Box>
        </ModalContent>
      </Modal>
    );
  }

  return null;
}
