import { useTranslation } from "@outschool/localization";
import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";
import { FacebookButton } from "@outschool/ui-components-shared";
import { Loading, Modal } from "@outschool/ui-legacy-component-library";
import qs from "qs";
import React, { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import * as helpers from "../../../shared/helpers";
import * as Routes from "../../../shared/Routes";
import { ActivityHeader, DefaultHeader } from "../SignupModal";

const giftCardModalQuery = gql`
  query GiftCardModal($activityUid: ID, $activitySlugId: String) {
    activity(uid: $activityUid, slugId: $activitySlugId) {
      title
      details {
        photo
      }
    }
  }
`;

type Params = {
  activitySlugOrUid?: string;
};

type searchParams = {
  giftcard?: string;
};

export const GiftCardModal = function () {
  const { t } = useTranslation("client\\components\\GiftCardModal");
  const title = t("Buy an Outschool Gift Card");
  const subtitle = t("A gift for kids that lasts a lifetime");
  const { activitySlugOrUid } = useParams<Params>();
  const { search } = useLocation();
  const activityUid =
    // @ts-ignore TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    (helpers.isUuid(activitySlugOrUid) && activitySlugOrUid) || null;
  const activitySlugId =
    (!activityUid &&
      activitySlugOrUid &&
      Routes.getIdFromSlug(activitySlugOrUid)) ||
    null;
  const hasActivity = !!(activityUid || activitySlugId);
  const params: searchParams = qs.parse(search, { ignoreQueryPrefix: true });
  const [showModal, setShowModal] = useState(!!params.giftcard);
  const { loading, data } = useQueryWithPreviousData(giftCardModalQuery, {
    fetchPolicy: "no-cache",
    variables: {
      activitySlugId,
      activityUid
    },
    skip: !hasActivity || !showModal
  });

  if (!showModal) {
    return null;
  }

  return (
    <Modal
      ariaLabel={t`Give a Gift Card`}
      open={showModal}
      onClose={() => setShowModal(false)}
      fullBleed={false}
      hasCloseButton={true}
      closeViaMaskClick={true}
      closeViaEscButton={true}
      iconButtonSx={{
        position: "relative",
        zIndex: 2
      }}
      sx={{
        maxWidth: 420,
        borderRadius: "large"
      }}
    >
      {loading && <Loading />}
      {!loading && (
        <>
          {hasActivity && (
            <ActivityHeader
              activity={data?.activity}
              title={title}
              subtitle={t`Give access to classes like this:`}
            />
          )}
          {!hasActivity && <DefaultHeader title={title} subtitle={subtitle} />}
          <FacebookButton
            component={Link}
            trackingName="buy-gift-card-from-modal"
            to={Routes.buyGiftCardPath()}
            fullWidth
          >
            {t`Give a Gift Card`}
          </FacebookButton>
        </>
      )}
    </Modal>
  );
};
