import { Modal, ModalProps } from "@outschool/ui-legacy-component-library";
import React from "react";

import TopNoticeBar from "./TopNoticeBar";

function PageModal({ children, ...props }: ModalProps) {
  return (
    <Modal {...props}>
      <TopNoticeBar />
      {children}
    </Modal>
  );
}

export default PageModal;
