import { Box, Tab, Tabs, Typography } from "@outschool/backpack";
import { Trans, useTranslation } from "@outschool/localization";
import {
  leaderPath,
  pathnameWithoutLocale,
  teacherSchedulePath
} from "@outschool/routes";
import { useSession } from "@outschool/ui-auth";
import { useIsMobile } from "@outschool/ui-utils";
import React from "react";

import * as Routes from "../../../shared/Routes";
import * as User from "../../../shared/User";
import { useBroadcastMessageFlag } from "../../routes/TeacherBroadcastPage";
import Link from "../Link";
import Toolbar from "../Toolbar";

/**
 * Seller org navigation toolbar including a news banner and page title header.
 */
export function SellerOrgToolbar() {
  const { t } = useTranslation("client\\components\\nav\\SellerOrgToolbar");
  const { currentUser, currentUserIsLoading } = useSession();

  // Don't show if user is not a seller org owner
  if (!currentUser?.sellerOrg?.currentUserIsOwner) {
    return null;
  }

  const isOrgTeacher =
    currentUser &&
    currentUser.sellerOrg &&
    currentUser.sellerOrg.currentUserIsTeacher;

  const currentUserSellerOrgName = isOrgTeacher && currentUser?.sellerOrg?.name;
  const titleContent = isOrgTeacher ? (
    <Trans t={t}>
      Teaching{" "}
      <Typography
        variant="inherit"
        sx={{
          marginLeft: "0.25em"
        }}
      >
        for{" "}
        <Link
          to={leaderPath(
            currentUser.sellerOrg.uid,
            currentUser.sellerOrg.leader_link
          )}
        >
          {{ currentUserSellerOrgName }}
        </Link>
      </Typography>
    </Trans>
  ) : (
    <>{t("Teaching")}</>
  );
  return (
    <>
      <Toolbar>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            maxWidth: "1024px",
            margin: "0 auto",
            padding: "0 1em"
          }}
        >
          <Typography
            variant="h2"
            sx={{
              marginBottom: "0.5em"
            }}
          >
            {titleContent}
          </Typography>
          {!currentUserIsLoading && <SellerOrgToolbarTabs />}
        </Box>
      </Toolbar>
    </>
  );
}

const useSellerOrgToolbarTabs = () => {
  const { currentUser } = useSession();
  const canUseBroadcastMessaging = useBroadcastMessageFlag(currentUser);
  const isOrgTeacher = currentUser?.sellerOrg?.currentUserIsTeacher;
  const hasTeacherApplication = User.hasTeacherApplication(currentUser);

  const { t } = useTranslation("client\\components\\nav\\SellerOrgToolbar");

  const toolbarItems: {
    value: string;
    label: string;
    to: string;
  }[] = [
    ...(hasTeacherApplication
      ? [
          {
            value: "apply",
            label: t("Apply"),
            to: Routes.teacherApplyPath()
          }
        ]
      : []),
    {
      value: "schedule",
      label: t("Schedule"),
      to: teacherSchedulePath()
    },
    {
      value: "sections",
      label: t("Sections"),
      to: Routes.teacherSectionsPath()
    },
    ...(!isOrgTeacher
      ? [
          {
            value: "coupons",
            label: t("Coupons"),
            to: Routes.teacherCouponsPath()
          }
        ]
      : []),
    {
      value: "marketing",
      label: t("Marketing"),
      to: Routes.teacherMarketingPath()
    },
    ...(canUseBroadcastMessaging
      ? [
          {
            value: "broadcast message",
            label: t("Broadcast Message"),
            to: Routes.teacherBroadcastMessagePath()
          }
        ]
      : [])
  ];

  return toolbarItems;
};

function SellerOrgToolbarTabs() {
  const isMobile = useIsMobile();
  const tabs = useSellerOrgToolbarTabs();
  const { value: selectedTab } =
    tabs.find(tab =>
      pathnameWithoutLocale(window.location.pathname).startsWith(tab.to)
    ) || {};

  return (
    <Tabs
      variant={isMobile ? "scrollable" : undefined}
      value={selectedTab || false}
    >
      {tabs.map(tab => (
        <Tab key={tab.value} {...tab} component={Link} />
      ))}
    </Tabs>
  );
}
