import { Box, Icon } from "@outschool/backpack";
import { faGlobe } from "@outschool/icons";
import {
  SUPPORTED_LANGUAGES,
  SupportedLanguage,
  useTranslation
} from "@outschool/localization";
import {
  ConditionalTrackingPageSectionOrSubSection,
  useImpressionTracking
} from "@outschool/ui-legacy-component-library";
import _ from "lodash";
import * as React from "react";

import { useLanguagePreference } from "../../hooks/Localization";
import Select, { SelectOption } from "../lib/Select";

export type LanguageOption = SelectOption<SupportedLanguage>;

type LanguageSelectorProps = {
  selectedLanguage: SupportedLanguage;
  setSelectedLanguage: (locale: SupportedLanguage) => void;
};

export default function LanguageSelector({
  selectedLanguage,
  setSelectedLanguage
}: LanguageSelectorProps) {
  const { t } = useTranslation(
    "client\\components\\localization\\LanguageSelector"
  );
  const menuTrackingNodeRef = React.useRef<HTMLSelectElement | null>(null);
  const { trackTouch } = useImpressionTracking({
    node: menuTrackingNodeRef.current,
    uniqueId: "language-selector",
    trackingLabel: "languageSelector"
  });
  const { browserLanguages } = useLanguagePreference();

  const onSelectHandler = (newValue: SupportedLanguage) => {
    if (newValue) {
      trackTouch({ newLanguage: newValue.i18nLocale });
      setSelectedLanguage(newValue);
    }
  };
  const languageOptions: LanguageOption[] = SUPPORTED_LANGUAGES.map(
    language => ({ value: language, label: language.localLanguageName })
  );

  const languagePreferences = _.fromPairs(
    browserLanguages.map((language, idx) => [
      `language_preference_${idx}`,
      language
    ])
  );

  return (
    <ConditionalTrackingPageSectionOrSubSection
      name="language_selector_section"
      uniqueId="language_selector"
      // @ts-ignore TS(2322): Type 'Dictionary<string | undefined>' is not assig... Remove this comment to see the full error message
      sharedProperties={languagePreferences}
    >
      {trackingNode => (
        <Box flex>
          <Box
            ref={trackingNode}
            data-test-id="activity-description-language-selector"
            sx={{
              position: "relative",
              flex: "auto"
            }}
          >
            <Select
              options={languageOptions}
              value={selectedLanguage}
              handleChangeOnBlur={false}
              onChange={onSelectHandler}
              sx={{
                display: "block",
                paddingLeft: "22px",
                borderRadius: "5px"
              }}
              placeholder={t`Select a language...`}
              selectRef={menuTrackingNodeRef}
            />
            <Icon
              aria-hidden
              icon={faGlobe}
              sx={{
                position: "absolute",
                left: 15,
                top: "13px",
                color: "primary.500"
              }}
            />
          </Box>
        </Box>
      )}
    </ConditionalTrackingPageSectionOrSubSection>
  );
}
