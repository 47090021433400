import {
  Alert,
  Box,
  Button,
  Chip,
  Theme,
  Typography
} from "@outschool/backpack";
import { centsToDollars } from "@outschool/business-rules";
import {
  ActiveOngoingEnrollmentsPausedDueToPaymentFailureQuery,
  ActiveOngoingEnrollmentsPausedDueToPaymentFailureQueryVariables,
  EnrollmentSummaryFragmentFragment,
  UnpauseOngoingEnrollmentsPausedForPaymentFailureMutation,
  UnpauseOngoingEnrollmentsPausedForPaymentFailureMutationVariables
} from "@outschool/gql-frontend-generated";
import { Trans, useTranslation } from "@outschool/localization";
import { enrolledPath } from "@outschool/routes";
import { dayjs, formatDate, formatShortWeekday } from "@outschool/time";
import {
  gql,
  useMutation,
  useQueryWithPreviousData
} from "@outschool/ui-apollo";
import { useSession } from "@outschool/ui-auth";
import { HelpLink, usePageAlerts } from "@outschool/ui-components-website";
import { LoadingIcon, Modal } from "@outschool/ui-legacy-component-library";
import { useNavigation } from "@outschool/ui-utils";
import React from "react";

import * as Env from "../../../shared/Env";
import * as Routes from "../../../shared/Routes";
import {
  useCreateSetupIntentWithPaymentElement,
  usePaymentMethods
} from "../../hooks/CurrentUser";
import { unpauseOngoingEnrollmentsPausedForPaymentFailureMutation } from "../../queries/EnrollmentMutations";
import { PriceDisplay } from "../enrolling/enrollmentForm/priceBreakdown/PriceDisplay";
import ErrorMessage from "../ErrorMessage";
import LoadingWithMessage from "../LoadingWithMessage";
import ResumeSubscriptionsAuthorization from "../payments/ResumeSubscriptionsAuthorization";
import {
  BillingDetails,
  StripeProvider,
  useDefaultStripeErrorMessage
} from "../payments/Stripe";
import StripeNewCard from "../payments/StripeNewCard";
import { StripeReplaceOrTryAgainCard } from "../payments/StripeSavedCards";

export const activeOngoingEnrollmentsPausedDueToPaymentFailureQuery = gql`
  query ActiveOngoingEnrollmentsPausedDueToPaymentFailure(
    $meetingsDateRangeStart: DateTime!
    $meetingsDateRangeEnd: DateTime!
  ) {
    currentUser {
      uid
      activeOngoingEnrollmentsPausedDueToPaymentFailure {
        uid
        priceCents
        ongoingEndTime
        section_uid
        section {
          uid
          activity {
            uid
            title
          }
          meetings(
            startAfter: $meetingsDateRangeStart
            startBefore: $meetingsDateRangeEnd
          ) {
            uid
          }
        }
        learner {
          uid
          name
          age
        }
      }
    }
  }
`;

function ResumeYourSubscriptionsPriceBreakdown({
  totalPayNowCents,
  totalNextWeekCents,
  pausedEnrollmentDetailsByLearner,
  hasPausedEnrollments
}: {
  totalPayNowCents: number;
  totalNextWeekCents: number;
  pausedEnrollmentDetailsByLearner: Record<
    string,
    {
      totalPriceCents: number;
      learnerName: string;
      learnerAge: number;
    }
  >;
  hasPausedEnrollments: boolean;
}) {
  const { t } = useTranslation(
    "client\\components\\account\\UpdatePaymentMethodDueToPaymentFailure"
  );
  const periodText = t`charged weekly`;
  const totalPayNow = `$${centsToDollars(totalPayNowCents)} USD`;
  const totalNextWeek = `$${centsToDollars(totalNextWeekCents)} USD`;

  const followingSundayDayjs = dayjs().utc().endOf("isoWeek");
  const followingSunday = `${formatDate(
    followingSundayDayjs
  )} (${formatShortWeekday(followingSundayDayjs)})`;

  return (
    <Box
      sx={{
        backgroundColor: "#F0F3FF",
        borderRadius: 8,
        padding: 16
      }}
    >
      {hasPausedEnrollments ? (
        Object.entries(pausedEnrollmentDetailsByLearner).map(
          ([learnerUid, { totalPriceCents, learnerName, learnerAge }]) => (
            <Box
              key={learnerUid}
              flex
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "0.25em"
              }}
            >
              <Box data-private>
                {t("{{learnerName}} (age {{learnerAge}})", {
                  learnerName,
                  learnerAge
                })}
              </Box>
              <PriceDisplay
                amountCents={totalPriceCents}
                periodText={periodText}
              />
            </Box>
          )
        )
      ) : (
        <Box>
          <Trans t={t}>
            Your weekly subscriptions have no meetings for the rest of this
            week. You will be charged {{ totalNextWeek }} for next week on{" "}
            {{ followingSunday }}.
          </Trans>
        </Box>
      )}
      <hr
        color="primary.200"
        style={{ marginTop: "12px", marginBottom: "12px" }}
      />
      <Box
        flex
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "0.25em"
        }}
      >
        <Typography emphasized>{t`Total due today:`}</Typography>{" "}
        <Typography emphasized>{totalPayNow}</Typography>
      </Box>
    </Box>
  );
}

const useEnrollmentsPausedDueToPaymentFailure = () => {
  const { currentUser } = useSession();

  const [meetingsDateRangeStart] = React.useState(dayjs().utc().toDate());
  const { data, loading: loadingEnrollmentsPausedDueToPaymentFailure } =
    useQueryWithPreviousData<
      ActiveOngoingEnrollmentsPausedDueToPaymentFailureQuery,
      ActiveOngoingEnrollmentsPausedDueToPaymentFailureQueryVariables
    >(activeOngoingEnrollmentsPausedDueToPaymentFailureQuery, {
      variables: {
        meetingsDateRangeStart,
        meetingsDateRangeEnd: dayjs().utc().endOf("isoWeek").toDate()
      },
      skip: !currentUser
    });

  return React.useMemo(() => {
    const allEnrollmentsPaused =
      data?.currentUser?.activeOngoingEnrollmentsPausedDueToPaymentFailure ??
      [];

    const pausedEnrollmentDetailsByLearner = allEnrollmentsPaused.reduce(
      (acc, enrollment) => {
        const learnerUid = enrollment?.learner?.uid;
        if (!learnerUid) {
          return acc;
        }
        const previousDetails = acc[learnerUid] ?? {};
        const {
          totalPriceCents: prevTotalPriceCents = 0,
          learnerName,
          learnerAge
        } = previousDetails;

        const totalPriceCents =
          (enrollment?.priceCents ?? 0) + (prevTotalPriceCents ?? 0);
        return {
          ...acc,
          [learnerUid]: {
            totalPriceCents,
            learnerName: learnerName ?? enrollment?.learner?.name ?? "-----",
            learnerAge: learnerAge ?? enrollment?.learner?.age ?? "--"
          }
        };
      },
      {} as Record<
        string,
        {
          totalPriceCents: number;
          learnerName: string;
          learnerAge: number;
        }
      >
    );

    const enrollmentsPausedWithMeetingsThisWeek = allEnrollmentsPaused?.filter(
      enrollment => enrollment?.section?.meetings?.length !== 0
    );

    const costOfResumingEnrollmentsThisWeek =
      enrollmentsPausedWithMeetingsThisWeek?.reduce((curr, enrollment) => {
        const enrollmentHasMeetingsThisWeek =
          enrollment?.section?.meetings?.length !== 0;
        return enrollmentHasMeetingsThisWeek
          ? curr + (enrollment?.priceCents ?? 0)
          : curr;
      }, 0) ?? 0;

    const costOfResumingEnrollmentsNextWeek =
      allEnrollmentsPaused?.reduce(
        (curr, enrollment) => curr + (enrollment?.priceCents ?? 0),
        0
      ) ?? 0;

    return {
      allEnrollmentsPausedUids: allEnrollmentsPaused.map(({ uid }) => uid),
      pausedEnrollmentDetailsByLearner,
      hasPausedEnrollments: allEnrollmentsPaused.length > 0,
      costOfResumingEnrollmentsThisWeek,
      costOfResumingEnrollmentsNextWeek,
      loadingEnrollmentsPausedDueToPaymentFailure
    };
  }, [data, loadingEnrollmentsPausedDueToPaymentFailure]);
};

const useResumeEnrollments = () => {
  const [unpauseEnrollments, { loading: isResumeInProgress, data }] =
    useMutation<
      UnpauseOngoingEnrollmentsPausedForPaymentFailureMutation,
      UnpauseOngoingEnrollmentsPausedForPaymentFailureMutationVariables
    >(unpauseOngoingEnrollmentsPausedForPaymentFailureMutation, {
      refetchQueries: [
        "ShouldShowEnrollmentPausedDueToPaymentFailureAlertQuery",
        "ParentUpcomingClassesQuery"
      ]
    });

  const resumeEnrollmentsNow = (enrollmentUids: string[]) => {
    // If there are no enrollments to resume, return early
    if (enrollmentUids.length === 0) {
      return;
    }

    return unpauseEnrollments({
      variables: {
        input: {
          enrollmentUids
        }
      }
    });
  };

  const resumeEnrollmentsSunday = (enrollmentUids: string[]) => {
    // If there are no enrollments to resume, return early
    if (enrollmentUids.length === 0) {
      return;
    }

    return unpauseEnrollments({
      variables: {
        input: {
          enrollmentUids,
          // end of week
          pauseEndDate: dayjs().utc().endOf("isoWeek").format("YYYY-MM-DD")
        }
      }
    });
  };

  return {
    resumeEnrollmentsNow,
    resumeEnrollmentsSunday,
    isResumeInProgress,
    isResumeSuccessful: !!data
  };
};

function ResumeYourSubscriptionsContent({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation(
    "client\\components\\account\\UpdatePaymentMethodDueToPaymentFailure"
  );

  const [isAuthorizationChecked, setIsAuthorizationChecked] =
    React.useState<boolean>(false);

  const {
    allEnrollmentsPausedUids,
    pausedEnrollmentDetailsByLearner,
    hasPausedEnrollments,
    costOfResumingEnrollmentsThisWeek,
    costOfResumingEnrollmentsNextWeek,
    loadingEnrollmentsPausedDueToPaymentFailure
  } = useEnrollmentsPausedDueToPaymentFailure();

  const {
    resumeEnrollmentsNow,
    resumeEnrollmentsSunday,
    isResumeInProgress,
    isResumeSuccessful
  } = useResumeEnrollments();

  const handlePayOnSunday = async () => {
    const { data, errors } =
      (await resumeEnrollmentsSunday(allEnrollmentsPausedUids)) || {};
    // If we got back a response, the mutation was successful
    if (data) {
      onClose();
    } else {
      console.error(errors);
    }
  };

  const { addPageAlert } = usePageAlerts();

  const handlePayNowAndResume = async () => {
    const { data, errors } =
      (await resumeEnrollmentsNow(allEnrollmentsPausedUids)) || {};
    // If we got back a response, the mutation was successful
    if (data) {
      if (
        data.unpauseOngoingEnrollmentsPausedForPaymentFailure
          ?.unsubscribedEnrollments?.length > 0
      ) {
        addPageAlert({
          id: "payment-failure-unsubscribed-alert",
          severity: "info",
          title: t("Subscriptions ended due to full classes"),
          content: t(
            "Some of your subscriptions have been ended because there are no seats left in the section. You were not charged for these classes."
          ),
          dismissible: true,
          persistant: true,
          action: (
            <UnenrolledClassesAlertAction
              unsubscribedEnrollments={
                data.unpauseOngoingEnrollmentsPausedForPaymentFailure
                  .unsubscribedEnrollments
              }
            />
          )
        });
      } else {
        onClose();
      }
    } else {
      console.error(errors);
    }
  };

  const disableActions = !isAuthorizationChecked || isResumeInProgress;

  const followingSundayDayjs = dayjs().utc().endOf("isoWeek");
  const followingSunday = `${formatDate(
    followingSundayDayjs
  )} (${formatShortWeekday(followingSundayDayjs)})`;

  return (
    <Box flex sx={{ flexDirection: "column", gap: "16px" }}>
      <Typography
        variant="h4"
        sx={{
          paddingBottom: "0.5em",
          borderBottom: "1px solid",
          borderColor: "grey.100"
        }}
      >
        {t`Resume Your Subscriptions`}
      </Typography>

      {loadingEnrollmentsPausedDueToPaymentFailure ? (
        <LoadingWithMessage>{t`Please wait while we load your subscriptions.`}</LoadingWithMessage>
      ) : (
        <Box>
          <Box flex sx={{ flexDirection: "column", gap: 21 }}>
            <ResumeYourSubscriptionsPriceBreakdown
              pausedEnrollmentDetailsByLearner={
                pausedEnrollmentDetailsByLearner
              }
              hasPausedEnrollments={hasPausedEnrollments}
              totalPayNowCents={costOfResumingEnrollmentsThisWeek}
              totalNextWeekCents={costOfResumingEnrollmentsNextWeek}
            />
            <ResumeSubscriptionsAuthorization
              setIsChecked={setIsAuthorizationChecked}
              isChecked={isAuthorizationChecked}
              disabled={isResumeInProgress || isResumeSuccessful}
              totalCents={costOfResumingEnrollmentsNextWeek}
            />
          </Box>
          <Box
            flex
            sx={(theme: Theme) => ({
              justifyContent: "space-between",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                gap: 16
              }
            })}
          >
            <Button
              variant="outlined"
              disabled={disableActions}
              onClick={handlePayOnSunday}
              startIcon={isResumeInProgress ? <LoadingIcon /> : undefined}
              fullWidth={!hasPausedEnrollments}
            >
              {t("Resume on {{followingSunday}}", {
                followingSunday
              })}
            </Button>
            {hasPausedEnrollments && (
              <Button
                variant="contained"
                disabled={disableActions}
                onClick={handlePayNowAndResume}
                startIcon={isResumeInProgress ? <LoadingIcon /> : undefined}
              >
                {t`Pay and Resume Now`}
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

const UnenrolledClassesAlertAction = ({
  unsubscribedEnrollments
}: {
  unsubscribedEnrollments: EnrollmentSummaryFragmentFragment[];
}) => {
  const { t } = useTranslation(
    "client\\components\\account\\UpdatePaymentMethodDueToPaymentFailure"
  );
  const [
    isUnsubscribedEnrollmentsModalOpen,
    setUnsubscribedEnrollmentsModalOpen
  ] = React.useState(true);

  return (
    <Box
      sx={{
        // We need 12px of top padding so that the Alert action is in line with the Alert title and icon
        // This should probably be done in the Alert component itself? Just not sure how.
        paddingTop: "12px"
      }}
    >
      <Button
        variant="link"
        color="info"
        onClick={() => setUnsubscribedEnrollmentsModalOpen(true)}
      >
        {t("View Unsubscribed Classes")}
      </Button>
      <UnsubscribedEnrollmentsModal
        open={isUnsubscribedEnrollmentsModalOpen}
        onClose={() => setUnsubscribedEnrollmentsModalOpen(false)}
        unsubscribedEnrollments={unsubscribedEnrollments}
      />
    </Box>
  );
};

function UnsubscribedEnrollmentsModal({
  open,
  onClose,
  unsubscribedEnrollments
}: {
  open: boolean;
  onClose: () => void;
  unsubscribedEnrollments: EnrollmentSummaryFragmentFragment[];
}) {
  return (
    <Modal open={open} onClose={onClose}>
      <UnsubscribedEnrollmentsList
        onClose={onClose}
        unsubscribedEnrollments={unsubscribedEnrollments}
      />
    </Modal>
  );
}

function UnsubscribedEnrollmentsList({
  onClose,
  unsubscribedEnrollments
}: {
  onClose: () => void;
  unsubscribedEnrollments: EnrollmentSummaryFragmentFragment[];
}) {
  const { t } = useTranslation(
    "client\\components\\account\\UpdatePaymentMethodDueToPaymentFailure"
  );
  const navigate = useNavigation();
  return (
    <Box flex sx={{ flexDirection: "column", gap: "16px" }}>
      <Typography
        variant="h4"
        sx={{
          paddingBottom: "0.5em",
          borderBottom: "1px solid",
          borderColor: "grey.100"
        }}
      >
        {t`Review Your Updated Subscriptions`}
      </Typography>

      <Box sx={{ display: "grid", gap: "13px" }}>
        <Typography variant="h6">{t`Unsubscribed Classes`}</Typography>
        <Typography>
          <Trans t={t}>
            You were unsubscribed to the following class(es) because the section
            is full.{" "}
            <b>Please note that you were not charged for these classes.</b> We
            recommend messaging your teachers for open seats in the coming weeks
            or checking the class page to find available sections.
          </Trans>
        </Typography>
        <Box
          sx={{
            display: "flex",
            padding: "8px 0px",
            alignItems: "flex-start",
            alignSelf: "stretch",
            borderRadius: "8px",
            border: "2px solid #E3E3E3"
          }}
        >
          {unsubscribedEnrollments.map(enrollment => (
            <Box
              key={enrollment.uid}
              sx={{
                display: "flex",
                padding: "16px",
                alignItems: "center",
                gap: "24px",
                alignSelf: "stretch",
                backgroundColor: "grey.50",
                lineHeight: "180%",
                width: "100%"
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Button
                  variant={"link"}
                  onClick={() => {
                    onClose();
                    if (enrollment.section?.uid) {
                      navigate(enrolledPath(enrollment.section?.uid));
                    }
                  }}
                  sx={{ textAlign: "start", textWrap: "balance" }}
                >
                  {enrollment.section?.activity.title}
                </Button>
                <Box>
                  <Typography
                    variant="inherit"
                    sx={{
                      fontWeight: "fontWeightBold"
                    }}
                  >{t`Unenrolled:`}</Typography>{" "}
                  <Typography>{enrollment.learner?.name}</Typography>
                </Box>
              </Box>
              <Chip label={t("Subscription Ended")} color={"info"} />
            </Box>
          ))}
        </Box>
        <Typography>
          <Trans t={t}>
            You have questions or need help?{" "}
            <HelpLink
              fullWidth={false}
              sx={{
                display: "inline",
                verticalAlign: "baseline",
                width: "fit-content"
              }}
              color="primary"
            >
              Contact the support team
            </HelpLink>
            .
          </Trans>
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row-reverse", gap: "13px" }}>
        <Button variant="contained" onClick={onClose}>{t`Done`}</Button>
        <Button
          variant="text"
          onClick={() => navigate(Routes.learnSchedulePath())}
        >{t`Manage My Classes`}</Button>
      </Box>
    </Box>
  );
}

type PaymentMethodFormOptions = {
  title?: string;
  subtitle?: React.ReactNode;
  description?: string;
  alertMessage?: string;
  buttonLabel?: string;
  savePaymentMethodsLoadingText?: string;
};
type PaymentMethodModalProps = {
  open: boolean;
};
type PaymentMethodModalContentProps = {
  onClose?: () => void;
  onPaymentSaved?: () => Promise<void>;
  onGoBack?: () => void;
  formOptions?: PaymentMethodFormOptions;
};

/**
 * This is a consumer for <StripeProvider /> thus the nested components
 */
function SavePaymentMethodModalContent({
  onClose = () => {},
  onPaymentSaved = async () => {},
  onGoBack,
  formOptions = {}
}: PaymentMethodModalContentProps) {
  const { t } = useTranslation(
    "client\\components\\account\\UpdatePaymentMethodDueToPaymentFailure"
  );
  const [isComplete, setIsComplete] = React.useState(false);
  const errorMessage = useDefaultStripeErrorMessage();
  const [resumeYourSubscriptions, setResumeYourSubscriptions] =
    React.useState(false);

  const {
    loading: paymentMethodsLoading,
    error: paymentMethodsError,
    paymentMethods
  } = usePaymentMethods();
  const [defaultPaymentMethod] = paymentMethods;

  let {
    title = null,
    subtitle = defaultPaymentMethod
      ? t`This is your default card.`
      : t`This will be your default card.`,
    description = defaultPaymentMethod
      ? t`We use this card to pay for renewing all of your classes with recurring weekly payments.`
      : t`We will use this card to pay for renewing all of your classes with recurring weekly payments.`,
    alertMessage = null,
    buttonLabel = t`Save Card`,
    savePaymentMethodsLoadingText = t`Please wait while we save your credit card`
  } = formOptions;

  const [createSetupIntentWithPaymentElement, { error, loading }] =
    useCreateSetupIntentWithPaymentElement(defaultPaymentMethod?.uid);

  const handleSaveCard = React.useCallback(async () => {
    await createSetupIntentWithPaymentElement();
    await onPaymentSaved();
    setResumeYourSubscriptions(true);
  }, [createSetupIntentWithPaymentElement, onPaymentSaved]);

  if (resumeYourSubscriptions) {
    return <ResumeYourSubscriptionsContent onClose={onClose} />;
  } else {
    return (
      <Box>
        <Typography
          variant="h4"
          sx={{
            paddingBottom: "8px",
            borderBottom: "1px solid",
            borderColor: "grey.100"
          }}
        >
          {title
            ? title
            : defaultPaymentMethod
            ? t`Update Your Payment Method`
            : t`Replace With New Card`}
        </Typography>

        {subtitle && (
          <Box
            sx={{
              marginTop: "1em",
              marginBottom: "1em"
            }}
          >
            <Typography variant="h6" sx={{ paddingBottom: "13px" }}>
              {subtitle}
            </Typography>
            {description}
          </Box>
        )}
        {alertMessage && defaultPaymentMethod && (
          <Alert severity="error" sx={{ marginBottom: "16px" }}>
            {alertMessage}
          </Alert>
        )}
        <Box
          sx={{
            paddingTop: "0.5em",
            paddingBottom: "0.5em"
          }}
        >
          {paymentMethodsError ? (
            <ErrorMessage
              value={errorMessage}
              showStatusPageLink={Env.IS_READ_ONLY_MODE}
            />
          ) : paymentMethodsLoading ? (
            <LoadingWithMessage>
              {t`Please wait while we load your payment methods`}
            </LoadingWithMessage>
          ) : defaultPaymentMethod ? (
            <StripeReplaceOrTryAgainCard
              defaultPaymentMethod={defaultPaymentMethod}
              resumeYourSubscriptionsCallback={() =>
                setResumeYourSubscriptions(true)
              }
              disabled={paymentMethodsLoading}
            />
          ) : (
            <>
              <StripeNewCard
                setIsCardComplete={setIsComplete}
                setBillingDetails={(_billingDetails: BillingDetails) => {}}
              />
              {loading ? (
                <LoadingWithMessage>
                  {savePaymentMethodsLoadingText}
                </LoadingWithMessage>
              ) : (
                <>
                  <ErrorMessage
                    value={error && error.message}
                    showStatusPageLink={Env.IS_READ_ONLY_MODE}
                  />
                  <Button
                    variant="contained"
                    disabled={!isComplete || loading}
                    onClick={handleSaveCard}
                    sx={{
                      width: "100%"
                    }}
                  >
                    {buttonLabel}
                  </Button>
                </>
              )}
            </>
          )}
        </Box>
        {onGoBack && (
          <Button
            variant="link"
            onClick={onGoBack}
            sx={{
              width: "100%",
              lineHeight: 1.5
            }}
            disabled={loading}
          >
            {t("Go Back")}
          </Button>
        )}
      </Box>
    );
  }
}

export function UpdatePaymentMethodDueToPaymentFailureModal({
  open,
  ...props
}: PaymentMethodModalProps & PaymentMethodModalContentProps) {
  return (
    <Modal open={open} onClose={props.onClose}>
      <StripeProvider amountInCents={0}>
        <SavePaymentMethodModalContent {...props} />
      </StripeProvider>
    </Modal>
  );
}
