import { Box, Icon, SxProps, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { fasExclamationTriangle } from "@outschool/icons";
import {
  renderCurrencyString as renderBaseCurrencyString,
  useLocalPriceDisplay,
  useTranslation
} from "@outschool/localization";
import React from "react";

export type PriceDisplayProps = {
  amountCents: number | "-";
  periodText?: string;
  dataTestId?: string;
  proratedAmountCents?: number;
  periodTextSx?: SxProps;
  showPlus?: boolean;
  showWarning?: boolean;
  useFree?: boolean;
};

const PRICE_SX: SxProps = {
  minWidth: "90px",
  textAlign: "right"
};

export function PriceDisplay({
  amountCents,
  periodText,
  dataTestId,
  proratedAmountCents,
  periodTextSx,
  showPlus = false,
  showWarning = false,
  useFree = true
}: PriceDisplayProps) {
  const { isBaseCurrency, renderCurrencyString } = useLocalPriceDisplay();
  const { t } = useTranslation(
    "client\\components\\enrolling\\enrollmentForm\\priceBreakdown\\PriceDisplay"
  );

  function renderCurrencyStringWithUsdEquivalent(amountCentsInUsd: number) {
    const localCurrencyString = renderCurrencyString({
      priceInCents: amountCentsInUsd,
      useFree,
      showCurrencyCode: false
    });
    if (amountCentsInUsd === 0 && useFree) {
      return <>{localCurrencyString}</>;
    } else {
      return (
        <>
          {localCurrencyString}{" "}
          <Typography
            variant="inherit"
            sx={{
              color: "grey.500"
            }}
          >
            ({showPlus && "+"}
            {renderBaseCurrencyString({
              priceInCents: amountCentsInUsd,
              useBaseCurrency: true,
              showCurrencyCode: false
            })}
            )
          </Typography>
        </>
      );
    }
  }
  if (amountCents === "-") {
    return (
      <Box sx={PRICE_SX} data-test-id={dataTestId}>
        {amountCents}
      </Box>
    );
  }

  const totalAmountString = isBaseCurrency
    ? renderCurrencyString({
        priceInCents: amountCents,
        useFree,
        showCurrencyCode: false
      })
    : renderCurrencyStringWithUsdEquivalent(amountCents);

  const proratedAmountString = !proratedAmountCents
    ? null
    : isBaseCurrency
    ? renderCurrencyString({
        priceInCents: proratedAmountCents,
        useFree,
        showCurrencyCode: false
      })
    : renderCurrencyStringWithUsdEquivalent(proratedAmountCents);

  return (
    <Box sx={PRICE_SX} data-test-id={dataTestId}>
      {showWarning && (
        <>
          <Icon
            icon={fasExclamationTriangle}
            sx={{
              color: "#B28B1E"
            }}
          />{" "}
        </>
      )}
      {showPlus && "+"}
      {proratedAmountCents ? (
        <>
          {proratedAmountString} {t("prorated")}{" "}
          <Typography
            sx={{
              color: "grey.600",
              textDecoration: "line-through"
            }}
          >
            {totalAmountString}
          </Typography>
        </>
      ) : (
        totalAmountString
      )}
      {periodText && (
        <Typography variant="body1" sx={periodTextSx}>
          {" "}
          {periodText}
        </Typography>
      )}
    </Box>
  );
}
