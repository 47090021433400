import { gql, useApolloClient } from "@outschool/ui-apollo";
import { useTokenContext } from "@outschool/ui-auth";
import * as SharedFeatureFlags from "@outschool/ui-components-shared";
import React, { PropsWithChildren } from "react";

import { ENABLED_FEATURE_FLAGS } from "../../shared/Env";
import { FeatureFlagsQueryQuery } from "../../shared/generated/graphql";

export const useFeatureFlag = SharedFeatureFlags.useFeatureFlag;
export const useFeatureFlags = SharedFeatureFlags.useFeatureFlags;

const FeatureFlagsQuery = gql`
  query FeatureFlagsQuery {
    featureFlags
  }
`;

function useFetchFeatureFlags() {
  const client = useApolloClient();
  return React.useCallback(async () => {
    const { data } = await client.query<FeatureFlagsQueryQuery>({
      query: FeatureFlagsQuery,
      fetchPolicy: "network-only"
    });
    return data.featureFlags;
  }, [client]);
}

export const FeatureFlagProvider: React.FC<PropsWithChildren<{}>> = ({
  children
}) => {
  const { authData } = useTokenContext();
  const fetchFeatureFlags = useFetchFeatureFlags();
  return (
    <SharedFeatureFlags.FeatureFlagProvider
      currentUserUid={authData?.uid}
      featureFlagOverrides={ENABLED_FEATURE_FLAGS}
      fetchFeatureFlags={fetchFeatureFlags}
    >
      {children}
    </SharedFeatureFlags.FeatureFlagProvider>
  );
};
