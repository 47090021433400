import { Box } from "@outschool/backpack";
import { ErrorBoundary } from "@outschool/ui-legacy-component-library";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

import { duplicateError } from "../lib/duplicateError";
import { PrerenderErrorMetaTag } from "./PrerenderHeaderTags";

export default function SimpleErrorBoundary({ children }: $TSFixMe) {
  return (
    <ErrorBoundary
      fallback={error => {
        /*
         * Errors are deduplicated by Sentry, which will clobber any tags we
         * apply. To make sure that the tags are included, we'll wrap the
         * original error.
         */
        const wrappedError = duplicateError(
          // @ts-ignore TS(2345): Argument of type 'Error | null' is not assignable ... Remove this comment to see the full error message
          error,
          "Oops page displayed for error: "
        );
        OsPlatform.captureError(wrappedError, {
          tags: {
            oops_page_displayed: true
          }
        });
        return <DefaultErrorBoundaryMessage />;
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

export function DefaultErrorBoundaryMessage() {
  return (
    <>
      <PrerenderErrorMetaTag />
      <Box
        sx={{
          textAlign: "center",
          paddingTop: "2em"
        }}
      >
        <Box
          sx={{
            maxWidth: "360px",
            py: "0.75em",
            px: "1em",
            textAlign: "center",
            margin: "auto"
          }}
        >
          Oops! Something went wrong with the Outschool website. Our engineers
          have been notified. Please reload the page and try again, or contact
          us at <a href="mailto:support@outschool.com">support@outschool.com</a>{" "}
          if the error persists. You can also check{" "}
          <a href="https://status.outschool.com/">status.outschool.com</a> to
          see if there is a broader outage.
          <br />
          <br />
          If you have a class, you should still be able to join the class
          through a banner at the top of{" "}
          <a href="https://outschool.com/">outschool.com</a>.
          <br />
          <br />
          Thanks for bearing with us!
        </Box>
      </Box>
    </>
  );
}
