import {
  ParentSearchSuggestionsQuery as ParentSearchSuggestionsQueryType,
  ParentSearchSuggestionsQueryVariables
} from "@outschool/gql-frontend-generated";
import { useLazyQuery } from "@outschool/ui-apollo";
import {
  ParentSearchSuggestionsQuery,
  SearchItem,
  useSavedSearches
} from "@outschool/ui-components-website";
import isNil from "lodash/isNil";
import omit from "lodash/omit";
import omitBy from "lodash/omitBy";
import { useEffect, useState } from "react";
import { v4 } from "uuid";

import { getSearchHistory } from "../../../client/components/search/RecentSearchHistory";
import { useSearchSuggestionsContext } from "../providers/SearchSuggestionsProvider";

import type { SavedSearch } from "@outschool/gql-backend-generated";

export function useSearchSuggestions(size?: number, ignore: string[] = []) {
  const [fetchSuggestions, { data, loading, error }] = useLazyQuery<
    ParentSearchSuggestionsQueryType,
    ParentSearchSuggestionsQueryVariables
  >(ParentSearchSuggestionsQuery, { variables: { query: "", ignore, size } });

  return {
    loading,
    error,
    fetchSuggestions,
    popularTermSuggestions: data?.searchSuggestions?.popularTermSuggestions,
    topicSuggestions: data?.searchSuggestions?.topicSuggestions,
    teacherSuggestions: data?.searchSuggestions?.teacherSuggestions
  };
}

export function useSetSearchSuggestions({
  popularTermSuggestions,
  topicSuggestions,
  teacherSuggestions,
  setSearchSuggestions,
  placeholderSuggestions,
  inputValue
}: {
  popularTermSuggestions?: ParentSearchSuggestionsQueryType["searchSuggestions"]["popularTermSuggestions"];
  topicSuggestions?: ParentSearchSuggestionsQueryType["searchSuggestions"]["topicSuggestions"];
  teacherSuggestions?: ParentSearchSuggestionsQueryType["searchSuggestions"]["teacherSuggestions"];
  setSearchSuggestions: (value: React.SetStateAction<SearchItem[]>) => void;
  placeholderSuggestions: SearchItem[];
  inputValue?: string;
}) {
  const {
    loading: savedLoading,
    savedSearches,
    refetch: refetchSavedSearches
  } = useSavedSearches();
  const [recentSearches, setRecentSearches] = useState<SavedSearch[]>([]);

  useEffect(() => {
    if (inputValue === "") {
      refetchSavedSearches();
      setRecentSearches(getSearchHistory());
    }
  }, [inputValue, refetchSavedSearches]);

  useEffect(() => {
    let suggestions: SearchItem[] = [];

    if (inputValue) {
      topicSuggestions?.forEach(t => {
        suggestions.push({
          uid: t.uid,
          name: t.label,
          category: t.category ?? undefined,
          type: "topic"
        });
      });

      popularTermSuggestions
        ?.filter(t => !suggestions.some(el => el.name == t.subCategory))
        .forEach(t => {
          suggestions.push({
            uid: t.uid,
            category: t.category,
            name: t.subCategory,
            type: "popular"
          });
        });

      teacherSuggestions?.forEach(t => {
        suggestions.push({
          uid: t.uid,
          name: t.name || "",
          type: "teacher",
          photo: t.photo,
          leaderLink: t.leader_link
        });
      });

      suggestions.push({
        uid: "keyword",
        name: inputValue,
        type: "keyword"
      });
    } else {
      suggestions = addRecentAndSavedSearches(
        recentSearches,
        savedSearches,
        placeholderSuggestions
      );
    }

    setSearchSuggestions(suggestions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    topicSuggestions,
    popularTermSuggestions,
    teacherSuggestions,
    inputValue,
    setSearchSuggestions,
    savedLoading,
    savedSearches,
    recentSearches
  ]);
}

export function addRecentAndSavedSearches(
  recentSearches: SavedSearch[],
  savedSearches: SavedSearch[],
  placeholderSuggestions: SearchItem[]
): SearchItem[] {
  type SearchItemWithCreatedAt = SearchItem & { createdAt: Date };

  let unsortedSuggestions: SearchItemWithCreatedAt[] = [];

  if (savedSearches) {
    savedSearches.forEach((s: SavedSearch) => {
      const newEntry: SearchItemWithCreatedAt = {
        uid: s.filters.userUid ?? v4(),
        name: s.filters.q || s.filters.userName || "",
        type: "saved",
        createdAt: new Date(s.createdAt),
        searchFilters: omitBy(omit(s.filters, ["__typename", "q"]), isNil)
      };

      if (isEmptySearchItem(newEntry, ["order"])) {
        return;
      }

      // there shouldn't be duplicates among saved searches
      // and we don't remove similar searches
      unsortedSuggestions.push(newEntry);
    });
  }

  if (recentSearches) {
    recentSearches.forEach((s: SavedSearch) => {
      const newEntry: SearchItemWithCreatedAt = {
        uid: s.filters.userUid ?? v4(),
        name: s.filters.q || s.filters.userName || "",
        type: "recent",
        createdAt: new Date(s.createdAt),
        searchFilters: omitBy(omit(s.filters, ["__typename", "q"]), isNil)
      };

      if (isEmptySearchItem(newEntry, ["order"])) {
        return;
      }

      const similarSearchIndex = unsortedSuggestions.findIndex(
        el => el.name === newEntry.name
      );
      const similarSearch = unsortedSuggestions[similarSearchIndex];

      if (similarSearch) {
        if (
          similarSearch.type !== newEntry.type &&
          newEntry.createdAt.getTime() > similarSearch.createdAt.getTime()
        ) {
          // if types are different, this means one must be
          // a recent search and the other a saved search
          // in this case, we want to keep the saved search
          // but with the datetime of the newer search
          unsortedSuggestions.splice(similarSearchIndex, 1);
          newEntry.type = similarSearch.type;
          newEntry.searchFilters = similarSearch.searchFilters;
          unsortedSuggestions.push(newEntry);
        } else if (
          newEntry.createdAt.getTime() > similarSearch.createdAt.getTime()
        ) {
          // if types are the same, we want to keep the newer search
          unsortedSuggestions.splice(similarSearchIndex, 1);
          unsortedSuggestions.push(newEntry);
        }
      } else {
        unsortedSuggestions.push(newEntry);
      }
    });
  }

  const sortedSuggestions: SearchItem[] = unsortedSuggestions.sort((a, b) => {
    return b.createdAt.getTime() - a.createdAt.getTime();
  });

  if (placeholderSuggestions) {
    placeholderSuggestions.forEach((s: SearchItem) => {
      // if there is an existing similar search, we don't add it

      const similarSearch = unsortedSuggestions.find(
        el => el.name?.toLowerCase() === s.name?.toLowerCase()
      );

      if (similarSearch) {
        return;
      }

      sortedSuggestions.push(s);
    });
  }

  return sortedSuggestions.slice(0, 10);
}

export function isEmptySearchItem(
  searchItem: SearchItem,
  filtersToIgnore: string[] = []
): boolean {
  if (searchItem.name !== "") {
    return false;
  }

  const searchItemWithoutFilters = omitBy(
    omit(searchItem.searchFilters, filtersToIgnore),
    isNil
  );

  if (searchItemWithoutFilters.q === "") {
    delete searchItemWithoutFilters.q;
  }

  return (
    !searchItemWithoutFilters ||
    Object.keys(searchItemWithoutFilters).length === 0
  );
}

export function useSelectInitialSearchItem({
  selectComboboxItem,
  selectedComboboxItem,
  setInputValue,
  setPrevSearchQuery
}: {
  selectComboboxItem: (item: SearchItem) => void;
  selectedComboboxItem: SearchItem | null;
  setInputValue: (inputValue: string) => void;
  setPrevSearchQuery: (prevSearchQuery: string) => void;
}) {
  const searchSuggestionsContext = useSearchSuggestionsContext();

  useEffect(() => {
    if (
      !selectedComboboxItem &&
      searchSuggestionsContext?.initialSelectedItem
    ) {
      const comboboxItemToSelect = searchSuggestionsContext.initialSelectedItem;
      selectComboboxItem(comboboxItemToSelect);
      if (comboboxItemToSelect && comboboxItemToSelect.name) {
        setInputValue(comboboxItemToSelect.name);
        setPrevSearchQuery(comboboxItemToSelect.name);
      }
    }
  }, [
    searchSuggestionsContext,
    selectComboboxItem,
    selectedComboboxItem,
    setInputValue,
    setPrevSearchQuery
  ]);
}
