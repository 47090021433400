// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
/** @jsx jsxPragma */

import { Container, jsxPragma } from "@outschool/ui-legacy-component-library";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  // TODO: Import type when Container becomes TS
  containerProps?: any;
  sx?: SystemStyleObject;
}

const PageTools = React.forwardRef<HTMLDivElement, Props>(
  ({ containerProps, sx, children, ...rest }, ref) => {
    return (
      <div
        /* eslint-disable react/no-unknown-property */
        // @ts-ignore
        sx={{
          /* eslint-enable react/no-unknown-property */
          backgroundColor: "gray6",
          borderBottomColor: "gray5",
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          ...sx
        }}
        ref={ref}
        {...rest}
      >
        <Container sx={{ paddingTop: "large" }} {...containerProps}>
          {children}
        </Container>
      </div>
    );
  }
);

export default PageTools;
