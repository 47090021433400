import { Box, Button, Theme, Typography } from "@outschool/backpack";
import { PaymentMethod } from "@outschool/gql-backend-generated";
import { Trans, useTranslation } from "@outschool/localization";
import {
  Modal,
  ModalActionButtons
} from "@outschool/ui-legacy-component-library";
import { pxToRem } from "@outschool/ui-utils";
import _ from "lodash";
import React from "react";

import * as Env from "../../../shared/Env";
import { useDeletePaymentMethod } from "../../hooks/CurrentUser";
import ErrorMessage from "../ErrorMessage";
import LoadingWithMessage from "../LoadingWithMessage";
import { useDefaultStripeErrorMessage } from "./Stripe";

function RemovePaymentMethodModal({
  paymentMethod,
  open,
  onClose
}: {
  paymentMethod: Pick<
    PaymentMethod,
    "uid" | "brand" | "last4" | "expirationMonth" | "expirationYear"
  >;
  open: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation(
    "client\\components\\payments\\StripeSavedCards"
  );
  const [
    deletePaymentMethod,
    { error: deletePaymentMethodError, loading: deletePaymentMethodLoading }
  ] = useDeletePaymentMethod();
  const errorMessage = useDefaultStripeErrorMessage();

  return (
    <Modal open={open} onClose={onClose}>
      <Typography variant="h3">
        {t("Remove credit card from account?")}
      </Typography>
      <Box
        sx={{
          marginBottom: "1em"
        }}
      >
        <Trans t={t}>
          Are you sure you want to remove{" "}
          <PaymentMethodLineItem paymentMethod={paymentMethod} /> from your
          account? Any active subscriptions will be canceled on Sunday unless
          you add another card to your profile before then.
        </Trans>
      </Box>
      {deletePaymentMethodError && (
        <ErrorMessage
          value={errorMessage}
          showStatusPageLink={Env.IS_READ_ONLY_MODE}
        />
      )}
      {deletePaymentMethodLoading ? (
        <LoadingWithMessage>
          {t`Please wait while we remove the credit card from your account.`}
        </LoadingWithMessage>
      ) : (
        <ModalActionButtons
          disabled={deletePaymentMethodLoading}
          primaryVariant="danger"
          primaryLabel={t`Remove card`}
          onPrimary={async () => {
            await deletePaymentMethod(paymentMethod.uid);
            onClose();
          }}
          onCancel={() => onClose()}
        />
      )}
    </Modal>
  );
}

function PaymentMethodLineItem({
  paymentMethod
}: {
  paymentMethod: Pick<
    PaymentMethod,
    "brand" | "last4" | "expirationMonth" | "expirationYear"
  >;
}) {
  const { t } = useTranslation(
    "client\\components\\payments\\StripeSavedCards"
  );
  return (
    <React.Fragment>
      {_.capitalize(paymentMethod.brand)} **** {paymentMethod.last4} {t("Exp.")}{" "}
      {/* eslint-disable-next-line i18next/no-literal-string */}
      {paymentMethod.expirationMonth.toFixed().padStart(2, "0")}/
      {paymentMethod.expirationYear.toString().substring(2)}
    </React.Fragment>
  );
}

interface StripeEditSavedCardProps {
  defaultPaymentMethod: PaymentMethod;
  editCallback: () => void;
  disabled: boolean;
  replaceDescription?: string;
}

export function StripeEditSavedCard({
  defaultPaymentMethod,
  editCallback,
  disabled,
  replaceDescription
}: StripeEditSavedCardProps) {
  const { t } = useTranslation(
    "client\\components\\payments\\StripeSavedCards"
  );

  return (
    <Box
      sx={{
        borderTop: "1px solid #E3E3E3",
        borderBottom: "1px solid #E3E3E3",
        marginTop: "0.5em",
        marginBottom: "2em"
      }}
    >
      <Box
        flex
        sx={{
          justifyContent: "space-between",
          py: "0.5em"
        }}
      >
        <PaymentMethodLineItem paymentMethod={defaultPaymentMethod} />
        {!disabled && (
          <Button variant="link" onClick={editCallback} disabled={disabled}>
            {t("Edit")}
          </Button>
        )}
      </Box>

      <Box
        sx={{
          marginBottom: "1em",
          padding: "0.5em",
          borderRadius: pxToRem(8),
          backgroundColor: "grey.50",
          fontSize: "0.875em"
        }}
      >
        {replaceDescription
          ? replaceDescription
          : t(
              "Editing a saved card will change the card for all active enrollments."
            )}
      </Box>
    </Box>
  );
}

interface StripeReplaceOrTryAgainCardProps {
  defaultPaymentMethod: PaymentMethod;
  resumeYourSubscriptionsCallback: () => void;
  disabled: boolean;
}

export const StripeReplaceOrTryAgainCard = ({
  defaultPaymentMethod,
  resumeYourSubscriptionsCallback,
  disabled
}: StripeReplaceOrTryAgainCardProps) => {
  const { t } = useTranslation(
    "client\\components\\payments\\StripeSavedCards"
  );

  const [deletePaymentMethod, { error, loading }] = useDeletePaymentMethod();
  const errorMessage = useDefaultStripeErrorMessage();

  const disableActions: boolean = loading || disabled || !!error;

  return (
    <Box>
      <Box
        flex
        sx={(theme: Theme) => ({
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 16
          }
        })}
      >
        <Typography variant="body">
          <PaymentMethodLineItem paymentMethod={defaultPaymentMethod} />
        </Typography>
        <Button
          variant="link"
          color="error"
          onClick={resumeYourSubscriptionsCallback}
          disabled={disableActions}
          sx={{
            lineHeight: 1.5
          }}
        >
          {t("Try Again")}
        </Button>
        <Button
          variant="link"
          color="primary"
          onClick={async () => {
            // We delete the payment method immediately and skip the RemovePaymentMethodModal following new designs
            await deletePaymentMethod(defaultPaymentMethod.uid);
          }}
          disabled={disableActions}
          sx={{
            lineHeight: 1.5
          }}
        >
          {t("Replace with New Card")}
        </Button>
      </Box>
      {error && (
        <ErrorMessage
          value={errorMessage}
          showStatusPageLink={Env.IS_READ_ONLY_MODE}
        />
      )}
    </Box>
  );
};

interface StripeRemoveSavedCardProps {
  defaultPaymentMethod: PaymentMethod;
  disabled: boolean;
}

export const StripeRemoveSavedCard = ({
  defaultPaymentMethod,
  disabled
}: StripeRemoveSavedCardProps) => {
  const [showRemoveCardModal, setShowRemoveCardModal] = React.useState(false);
  const { t } = useTranslation(
    "client\\components\\payments\\StripeSavedCards"
  );

  return (
    <Box
      flex
      sx={{
        flexDirection: "row",
        gap: 16,
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <Typography variant="body">
        <PaymentMethodLineItem paymentMethod={defaultPaymentMethod} />
      </Typography>
      <Box>
        <Button
          variant="link"
          color="error"
          onClick={() => setShowRemoveCardModal(true)}
          disabled={disabled}
          sx={{
            lineHeight: 1.5
          }}
        >
          {t("Remove")}
        </Button>
      </Box>
      <RemovePaymentMethodModal
        paymentMethod={defaultPaymentMethod}
        open={showRemoveCardModal}
        onClose={() => setShowRemoveCardModal(false)}
      />
    </Box>
  );
};
