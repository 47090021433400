import { Box, Image, Typography } from "@outschool/backpack";
import { Trans, useTranslation } from "@outschool/localization";
import { browseRootPath, searchUrl } from "@outschool/routes";
import { ExternalLink } from "@outschool/ui-components-shared";
import { cleanErrorMessage } from "@outschool/ui-utils";
import React from "react";
import { useLocation } from "react-router-dom";

import notFoundImage from "../../images/not-found-404.png";
import { UserFacingError } from "../../shared/client/ApolloClient";
import * as Routes from "../../shared/Routes";
import HeaderTags from "./HeaderTags";
import {
  PrerenderErrorMetaTag,
  PrerenderNotFoundMetaTag
} from "./PrerenderHeaderTags";

export function isEmailLink(location: $TSFixMe) {
  return location && location.query && location.query.utm_medium === "email";
}

interface NotFoundContentProps {
  data?: any;
  title?: string;
  message: React.ReactNode;
}

export function NotFoundContent({
  data, // TODO: The structure of GraphQL data field may not be the same.
  title: titleProp,
  message
}: NotFoundContentProps) {
  const { t } = useTranslation("client\\components\\NotFoundContent");
  const location = useLocation();
  React.useEffect(() => {
    if (isEmailLink(location)) {
      OsPlatform.captureError(new Error("Broken email link"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const title = titleProp ?? t`Whoops! That page doesn't exist.`;

  if (data && data.error && data.error.networkError) {
    return (
      <Box
        data-test-id="not-found"
        sx={{
          marginTop: "60px",
          marginBottom: "80px",
          textAlign: "center",
          paddingY: "2em",
          position: "relative",
          width: "100%",
          maxWidth: "1024px",
          padding: "0 1em"
        }}
      >
        <PrerenderErrorMetaTag />
        <Typography
          variant="h3"
          sx={{
            marginBottom: "1em"
          }}
        >
          {t`Disconnected from Outschool`}
        </Typography>
        <div>
          {t`Please check your Internet connection and try reloading this page.`}
        </div>
        <div>({new UserFacingError(data.error).toString()})</div>
      </Box>
    );
  }

  const errorMessage = message?.toString
    ? cleanErrorMessage(message.toString())
    : message;

  const messageComponent =
    errorMessage ||
    (isEmailLink(location) ? (
      /* eslint-disable react/no-unescaped-entities */
      <Trans t={t}>
        It looks like you clicked on a broken link in an email. Our team has
        already been notified and will investigate this problem. You're also
        welcome to contact{" "}
        <a href="mailto:support@outschool.com">support@outschool.com</a> if
        you'd like more help.
      </Trans>
    ) : (
      /* eslint-enable react/no-unescaped-entities */
      t`That link is outdated or incorrect, but have no fear, inspiration is right around the corner.`
    ));

  return (
    <Box
      data-test-id="not-found"
      style={{ marginBottom: "80px", textAlign: "center" }}
      sx={{
        position: "relative",
        width: "100%",
        maxWidth: "1024px",
        margin: "0 auto",
        padding: "0 1em"
      }}
    >
      <PrerenderNotFoundMetaTag />
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <HeaderTags meta={[{ content: "noindex", name: "robots" }]} />
      <Image
        src={notFoundImage}
        sx={{
          width: "256px",
          marginBottom: "0.5em"
        }}
      />
      <Typography variant="h2">{title}</Typography>
      <p>{messageComponent}</p>
      <Box
        sx={{
          display: "grid",
          gap: "1em",
          marginTop: "2em",

          a: {
            fontSize: "1em"
          }
        }}
      >
        <ExternalLink url={browseRootPath()}>{t`Home`}</ExternalLink>
        <ExternalLink url={searchUrl()}>{t`Explore Classes`}</ExternalLink>
        <ExternalLink
          url={Routes.supportUrl()}
        >{t`Contact Support`}</ExternalLink>
      </Box>
    </Box>
  );
}
