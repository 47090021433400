// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, Button, Typography } from "@outschool/backpack";
import {
  isTeacherCertificateCredentialGql,
  isTeacherDegreeCredentialGql,
  isTeacherOtherCredentialGql
} from "@outschool/business-rules";
import { TeacherCredential } from "@outschool/gql-backend-generated";
import {
  DeleteTeacherCredentialMutation,
  DeleteTeacherCredentialMutationVariables
} from "@outschool/gql-frontend-generated";
import { dayjs } from "@outschool/time";
import { gql, useLazyQuery, useMutation } from "@outschool/ui-apollo";
import { useSession } from "@outschool/ui-auth";
import { useCredentialString } from "@outschool/ui-components-website";
import { FormField } from "@outschool/ui-legacy-component-library";
import { Screen, useNavigation } from "@outschool/ui-utils";
import { SystemStyleObject } from "@styled-system/css";
import ldStartCase from "lodash/startCase";
import React from "react";

import { deleteTeacherCredentialMutation } from "../../queries/TeacherCredentialsMutations";
import { teacherCredentialsQuery } from "../../queries/TeacherCredentialsQueries";

type CredentialsItemProps = {
  hideDelete?: boolean;
  credential: TeacherCredential;
};

function BulletPoint({ children }: $TSFixMe) {
  return (
    <>
      <span
        style={{
          fontSize: "12px",
          color: "#808080",
          display: "inline-block",
          margin: "0 10px"
        }}
      >
        •
      </span>
      {children}
    </>
  );
}

const FILE_PREVIEW_URL_QUERY = gql`
  query FilePreviewURL($uid: String!) {
    teacherCredentialFilePreviewURL(uid: $uid)
  }
`;

export default function CredentialItem(props: CredentialsItemProps) {
  const [
    deleteCredentialRecord,
    { error: deleteError, loading: isDeletingCredential }
  ] = useMutation<
    DeleteTeacherCredentialMutation,
    DeleteTeacherCredentialMutationVariables
  >(deleteTeacherCredentialMutation, {
    refetchQueries: [
      {
        query: teacherCredentialsQuery
      }
    ]
  });
  const {
    translateDegreeType,
    translateDegreeSubject,
    translateCredentialDiscipline
  } = useCredentialString();
  const navigate = useNavigation();
  const { isAdmin } = useSession();
  const isMobile = Screen.useIsMobile();

  const boxStyle: SystemStyleObject = {
    maxWidth: isMobile ? "265px" : "800px",
    overflow: isMobile ? "visible" : "hidden",
    textOverflow: isMobile ? "unset" : "ellipsis",
    whiteSpace: isMobile ? "unset" : "break-spaces"
  };

  const getCredentialInfo = (credential: TeacherCredential) => {
    if (isTeacherCertificateCredentialGql(credential)) {
      return (
        <>
          <Typography
            variant="h5"
            sx={{
              marginBottom: "5px"
            }}
          >
            {ldStartCase(credential.certificateState)}{" "}
            {ldStartCase(credential.credentialType)}
          </Typography>
          {credential.name && (
            <Box sx={boxStyle}>Teacher: {credential.name}</Box>
          )}
          <Box sx={boxStyle}>
            ID: {credential.certificateNumber}
            {credential.certificateIssueDate && (
              <BulletPoint>
                Issued:{" "}
                {dayjs(credential.certificateIssueDate).format("MMM D, YYYY")}{" "}
              </BulletPoint>
            )}
            {credential.certificateExpireDate && (
              <BulletPoint>
                Expires:{" "}
                {dayjs(credential.certificateExpireDate).format("MMM D, YYYY")}
              </BulletPoint>
            )}
          </Box>
          {credential.certificateDiscipline && (
            <Box sx={boxStyle}>
              {translateCredentialDiscipline(credential.certificateDiscipline)}
              {credential.certificateSecondaryDiscipline && (
                <BulletPoint>
                  {" "}
                  {translateCredentialDiscipline(
                    credential.certificateSecondaryDiscipline
                  )}
                </BulletPoint>
              )}
            </Box>
          )}
        </>
      );
    }
    if (isTeacherDegreeCredentialGql(credential)) {
      return (
        <>
          <Typography
            variant="h5"
            sx={{
              marginBottom: "5px"
            }}
          >
            {translateDegreeType(credential.degreeType)}
          </Typography>
          {credential.name && (
            <Box sx={boxStyle}>Teacher: {credential.name}</Box>
          )}
          <Box sx={boxStyle}>
            {credential.degreeInstitution}
            <BulletPoint>
              {translateDegreeSubject(credential.degreeSubject)}
            </BulletPoint>
            {credential.degreeIssueDate && (
              <BulletPoint>
                Issued {dayjs(credential.degreeIssueDate).format("MMM D, YYYY")}
              </BulletPoint>
            )}
          </Box>
        </>
      );
    }
    if (isTeacherOtherCredentialGql(credential)) {
      return (
        <>
          <Typography
            variant="h5"
            sx={{
              marginBottom: "5px"
            }}
          >
            {ldStartCase(credential.credentialType)}
          </Typography>
          {credential.name && (
            <Box sx={boxStyle}>Teacher: {credential.name}</Box>
          )}
          <Box sx={boxStyle}>
            {credential.otherType}
            <BulletPoint>{credential.otherDetails}</BulletPoint>
          </Box>
        </>
      );
    }
    return (
      <Typography
        variant="h4"
        sx={{
          marginBottom: "5px"
        }}
      >
        {credential.credentialType}
      </Typography>
    );
  };

  const [getFilePreviewUrl, { loading: isLoadingCredentialPreview }] =
    useLazyQuery(FILE_PREVIEW_URL_QUERY, {
      onCompleted: data => {
        navigate(data.teacherCredentialFilePreviewURL, { newTab: true });
      },
      fetchPolicy: "network-only"
    });

  return (
    <Box
      sx={{
        margin: "12px 0",
        padding: "24px",
        borderRadius: "16px",
        backgroundColor: "#F0F3FF",
        display: isMobile ? "block" : "flex",
        justifyContent: "space-between"
      }}
    >
      <div>{getCredentialInfo(props.credential)}</div>
      <FormField
        error={
          deleteError
            ? "We were unable to complete your request. Try again later."
            : null
        }
        sx={{
          justifyContent: isMobile ? "start" : "center",
          alignItems: isMobile ? "start" : "end",
          maxWidth: "220px",
          textAlign: isMobile ? "start" : "end",
          marginTop: isMobile ? "20px" : "0",
          display: "flex"
        }}
      >
        {isAdmin && (
          <Button
            variant="link"
            sx={{
              color: "primary.500",
              width: "fit-content",

              "&:active:not(:disabled)": {
                color: "primary.500"
              },

              "&:focus:not(:disabled)": {
                color: "primary.500"
              }
            }}
            disabled={isLoadingCredentialPreview}
            onClick={() => {
              getFilePreviewUrl({
                variables: {
                  uid: props.credential.uid
                }
              });
            }}
          >
            View
          </Button>
        )}
        {!props.hideDelete && (
          <Button
            variant="link"
            sx={{
              color: "error.600",
              width: "fit-content",

              "&:active:not(:disabled)": {
                color: "error.600"
              },

              "&:focus:not(:disabled)": {
                color: "error.600"
              }
            }}
            disabled={isDeletingCredential}
            onClick={async () => {
              await deleteCredentialRecord({
                variables: { uid: props.credential.uid }
              });
            }}
          >
            Delete
          </Button>
        )}
      </FormField>
    </Box>
  );
}
