import { ActivitiesRow } from "@outschool/db-queries/dist/generated/types";
import {
  Activity as ActivityType,
  Leader,
  Meeting,
  Section
} from "@outschool/gql-backend-generated";
import { useTranslation } from "@outschool/localization";
import * as Time from "@outschool/time";
import { dayjs } from "@outschool/time";

import * as Activity from "../../Activity";
import {
  ActivityDuration,
  ActivityFormatAndFrequency,
  SectionMaxSize,
  SectionSize,
  SectionStatus,
  SectionTime,
  SectionTimeAndGqlStrings,
  availableSpaces,
  durationInMinutes,
  isCanceled,
  isComplete,
  isDeleted,
  isDraft,
  isFull,
  isFuture,
  isInProgress,
  isPast,
  isPromotable,
  offeredSpaces
} from "../../Section";

type SectionTimeAndLeader = SectionTime & {
  leader: { __typename?: "Leader" } & Pick<Leader, "uid" | "name">;
};

interface DurationStringArgs {
  section?: SectionTime;
  nextOngoingMeeting?: Pick<Meeting, "start_time"> | null;
  userTimeZone?: string;
  showTimes?: boolean;
  showTz?: boolean;
}

export default function useSectionString() {
  const { t } = useTranslation("shared\\Section");

  const fullDurationStringNotClub = ({
    activity,
    section,
    nextOngoingMeeting,
    userTimeZone,
    showTimes = true,
    showTz = true
  }: DurationStringArgs & {
    activity?: ActivityFormatAndFrequency;
  }): string => {
    if (!activity || !section) {
      return t("Suggest a time that works for you");
    }

    if (!section.start_time) {
      return t("Not yet scheduled");
    }

    if (!!activity.is_ongoing_weekly || activity.hasTeacherSchedule) {
      if (isFuture(section)) {
        return t("Starts on: {{firstMeeting}}", {
          firstMeeting: Time.formatDateTimeWithWeekday(
            section.start_time,
            userTimeZone
          )
        });
      } else {
        return nextOngoingMeeting
          ? t("Next session: {{nextMeeting}}", {
              nextMeeting: Time.formatDateTimeWithWeekday(
                nextOngoingMeeting.start_time,
                userTimeZone
              )
            })
          : t("Started on: {{firstMeeting}}", {
              firstMeeting: Time.formatDateTimeWithWeekday(
                section.start_time,
                userTimeZone
              )
            });
      }
    } else if (Activity.isFlexSchedule(activity) || !showTimes) {
      return Time.fullDurationStringWithoutTimes(
        section.start_time,
        section.end_time,
        userTimeZone
      );
    } else {
      return Time.fullDurationStringWithStartTime(
        section.start_time,
        section.end_time,
        userTimeZone,
        showTz
      );
    }
  };

  /**
   * Constructs a short summary string for a section. Falls back to fullDurationString
   * Takes advantage of graphql string fields from section, like section.meetingDays
   * @example "Thursdays, 10:00 - 10:50 AM", "Mo, We, Th, 1:15 - 2:00 PM"
   * @todo Add more GQL fields and eventually use this method to construct section strings
   */
  const fullDurationStringFromGql = ({
    activity,
    section,
    nextOngoingMeeting,
    userTimeZone,
    showTimes = true,
    showTz = true
  }: DurationStringArgs & {
    activity: ActivityFormatAndFrequency;
    section: SectionTimeAndGqlStrings;
    nextOngoingMeeting?: Pick<Meeting, "end_time"> | null;
  }) => {
    let startTime: Time.dayjs.ConfigType, endTime: Time.dayjs.ConfigType;
    if (nextOngoingMeeting) {
      // Ongoing
      startTime = nextOngoingMeeting.start_time;
      endTime = nextOngoingMeeting.end_time;
    } else if (activity.duration_weeks && activity.duration_weeks > 1) {
      // Fixed
      startTime = Time.dayjs(section.start_time);
      endTime = Time.dayjs(section.end_time)
        .year(startTime.year())
        .date(startTime.date());
    }
    if (startTime && endTime && section.meetingDays) {
      return (
        section.meetingDays +
        ", " +
        Time.briefDurationTimesString(startTime, endTime, userTimeZone, false)
      );
    }
    // Fall back to fullDurationString
    return fullDurationString({
      activity,
      section,
      nextOngoingMeeting,
      userTimeZone,
      showTimes,
      showTz
    });
  };

  const fullDurationString = ({
    activity,
    section,
    nextOngoingMeeting,
    userTimeZone,
    showTimes = true,
    showTz = true
  }: DurationStringArgs & {
    activity?: ActivityFormatAndFrequency;
  }): string => {
    if (!activity || !section) {
      return t("Suggest a time that works for you");
    }
    if (Activity.isClub(activity) && Activity.isPublished(activity)) {
      return t("Started on: {{firstMeeting}}", {
        firstMeeting: Time.formatDateTimeWithWeekday(
          activity.published_at,
          userTimeZone
        )
      });
    }
    return fullDurationStringNotClub({
      activity,
      section,
      nextOngoingMeeting,
      userTimeZone,
      showTimes,
      showTz
    });
  };

  /**
   * Flex: "Jan 1 - Feb 1"
   * Fixed: "Jan 1 - Feb 1, 10:00 - 10:50 PM"
   */
  const briefDurationString = (
    activity: Pick<
      ActivityType,
      "weekly_meetings" | "duration_weeks" | "duration_minutes"
    > &
      Partial<Pick<ActivityType, "isClub"> & Pick<ActivitiesRow, "is_club">>,
    section: SectionTime,
    userTimeZone: string
  ): string => {
    if (!activity || Activity.isClub(activity) || !section) {
      return "";
    }
    if (!section.start_time || !section.end_time) {
      return t("Not yet scheduled");
    }
    const datesDuration = Time.briefDurationStringWithoutTimes(
      section.start_time,
      section.end_time,
      userTimeZone
    );
    if (Activity.isFlexSchedule(activity)) {
      return datesDuration;
    } else {
      const sectionEndTime = Time.dayjs(section.start_time).add(
        durationInMinutes(activity) ?? 0,
        "minutes"
      );
      const timesDuration = Time.briefDurationTimesString(
        section.start_time,
        sectionEndTime,
        userTimeZone
      );
      return `${datesDuration}, ${timesDuration}`;
    }
  };

  const formatNextSessionStartTime = (
    date: string,
    timeZone: string
  ): string | null => {
    const currentDate = dayjs().tz(timeZone);
    const sessionDate = dayjs(date).tz(timeZone);
    const minutesFromNow = sessionDate.diff(currentDate, "minutes");
    const sessionTime = sessionDate.format("LT").replace(":00", "");

    if (minutesFromNow < 0) {
      return null;
    } else if (minutesFromNow < 3) {
      return t("Next session starting now");
    } else if (minutesFromNow <= 30) {
      return t("Next session in {{minutesFromNow}} minutes", {
        minutesFromNow
      });
    } else if (Time.isToday(sessionDate.toISOString(), timeZone)) {
      return t("Next session at {{sessionTime}} today", {
        sessionTime
      });
    } else if (currentDate.add(1, "day").isSame(sessionDate, "day")) {
      return t("Next session at {{sessionTime}} tomorrow", {
        sessionTime
      });
    } else if (currentDate.add(6, "day").isSameOrAfter(sessionDate, "day")) {
      return t("Next session at {{sessionTime}} on {{sessionDate}}", {
        sessionTime,
        sessionDate: sessionDate.format("dddd")
      });
    } else {
      return t("Next session at {{sessionTime}} on {{sessionDate}}", {
        sessionTime,
        sessionDate: sessionDate.format("ddd M/D")
      });
    }
  };

  const formatUpcomingStartTime = (
    date: string,
    timeZone: string
  ): string | null => {
    const currentDate = dayjs().tz(timeZone);
    const sessionDate = dayjs(date).tz(timeZone);
    const minutesFromNow = sessionDate.diff(currentDate, "minutes");
    const sessionTime = sessionDate.format("LT").replace(":00", "");

    if (minutesFromNow < 0) {
      return null;
    } else if (minutesFromNow < 3) {
      return t("starting now");
    } else if (minutesFromNow <= 30) {
      return t("in {{minutesFromNow}} minutes", {
        minutesFromNow
      });
    } else if (Time.isToday(sessionDate.toISOString(), timeZone)) {
      return t("at {{sessionTime}} today", {
        sessionTime
      });
    } else if (currentDate.add(1, "day").isSame(sessionDate, "day")) {
      return t("at {{sessionTime}} tomorrow", {
        sessionTime
      });
    } else if (currentDate.add(6, "day").isSameOrAfter(sessionDate, "day")) {
      return t("at {{sessionTime}} on {{sessionDate}}", {
        sessionTime,
        sessionDate: sessionDate.format("dddd")
      });
    } else {
      return t("at {{sessionTime}} on {{sessionDate}}", {
        sessionTime,
        sessionDate: sessionDate.format("ddd M/D")
      });
    }
  };

  const emailSharingSubject = (
    activity: ActivityType,
    section: Section,
    isEnrolled: boolean,
    userTimeZone: string
  ): string => {
    const nextSessionStartTime = formatUpcomingStartTime(
      section.start_time,
      userTimeZone
    );
    let subject = t('Take a look at "{{activityTitle}}"', {
      activityTitle: activity.title
    });
    if (nextSessionStartTime) {
      subject = isEnrolled
        ? t('Join us {{nextSessionStartTime}} for "{{activityTitle}}"', {
            nextSessionStartTime,
            activityTitle: activity.title
          })
        : t('Coming up {{nextSessionStartTime}}: "{{activityTitle}}"', {
            nextSessionStartTime,
            activityTitle: activity.title
          });
    }
    return subject;
  };

  const emailSharingMessage = (
    senderName: string,
    isEnrolled: boolean,
    isFree?: boolean
  ): string => {
    return isEnrolled
      ? isFree
        ? t(
            "Hi! We would love to have you join us as our guest in this class for free!\n- {{senderName}}",
            { senderName }
          )
        : t(
            "Hi! We signed up for this class on Outschool. It would be great if you could join us!\n- {{senderName}}",
            { senderName }
          )
      : t(
          "Hi! I saw this class on Outschool and thought you might be interested.\n-{{senderName}}",
          { senderName }
        );
  };

  const remainingSpotsMessage = (
    section: SectionSize,
    filledSpaceCount: number,
    minSize?: number
  ): string => {
    if (!section) {
      return "";
    }
    const remaining = availableSpaces(section, filledSpaceCount);

    // 1:1 classes should have no message
    if (offeredSpaces(section) === 1 && remaining === 1) {
      return "";
    }

    if (remaining > 3) {
      if (filledSpaceCount >= (minSize || section?.size_min || 0)) {
        return t("{{numEnrolledLearners}} learner enrolled", {
          count: filledSpaceCount,
          numEnrolledLearners: filledSpaceCount,
          defaultValue_plural: "{{numEnrolledLearners}} learners enrolled"
        });
      } else {
        return "";
      }
    } else if (remaining <= 0) {
      return t("This section is sold out.");
    } else {
      return t("Only one spot remaining", {
        count: remaining,
        numRemainingSpots: remaining,
        defaultValue_plural: "Only {{numRemainingSpots}} spots remaining"
      });
    }
  };

  return {
    fullDurationString,
    fullDurationStringNotClub,
    fullDurationStringFromGql,
    briefDurationString,
    formatNextSessionStartTime,
    emailSharingSubject,
    emailSharingMessage,
    remainingSpotsMessage,

    fullDurationStringWithTeacherName({
      activity,
      section,
      nextOngoingMeeting,
      userTimeZone,
      showTimes = true
    }: {
      activity?: ActivityFormatAndFrequency;
      section?: SectionTimeAndLeader;
      nextOngoingMeeting?: Pick<Meeting, "start_time" | "end_time"> | null;
      userTimeZone: string;
      showTimes?: boolean;
    }): string {
      let formattedString = fullDurationString({
        activity,
        section,
        nextOngoingMeeting,
        userTimeZone,
        showTimes
      });

      if (!activity || !section || !section.start_time || !section.end_time) {
        return formattedString;
      } else {
        const taughtBy = t("Taught by {{teacherName}}", {
          teacherName: section.leader.name
        });
        return `${formattedString} - ${taughtBy}`;
      }
    },

    statusMessage(
      activity: ActivityDuration,
      section: SectionStatus & SectionSize,
      filledSpaceCount: number
    ): string {
      if (isComplete(section)) {
        return t("This section has ended.");
      } else if (isInProgress(section) && !activity.is_ongoing_weekly) {
        return t("This section has already started.");
      } else if (isCanceled(section)) {
        return t("This section has been canceled.");
      } else if (isFull(section, filledSpaceCount)) {
        return t("This section is full.");
      } else if (isPromotable(activity, section, filledSpaceCount)) {
        return remainingSpotsMessage(section, filledSpaceCount);
      } else {
        return "";
      }
    },

    statusLabel(
      section: SectionStatus & SectionMaxSize,
      filledSpaceCount: number,
      hasEnrollment: boolean = false
    ): string {
      if (!section) {
        return "";
      } else if (isDeleted(section)) {
        return t("Deleted");
      } else if (isCanceled(section)) {
        return t("Canceled");
      } else if (isPast(section)) {
        return t("Complete");
      } else if (isInProgress(section)) {
        return t("In Progress");
      } else if (isDraft(section)) {
        return t("Draft");
      } else if (isFull(section, filledSpaceCount)) {
        return t("Full");
      } else if (isFuture(section) && hasEnrollment) {
        return t("Upcoming");
      } else {
        return t("Bookable");
      }
    }
  };
}
