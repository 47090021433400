import { useMachineTranslation, useTranslation } from "@outschool/localization";
import * as Time from "@outschool/time";
import { useSession } from "@outschool/ui-auth";
import { Banner } from "@outschool/ui-legacy-component-library";
import React from "react";
import { useLocation } from "react-router";

import * as Routes from "../../shared/Routes";
import Link from "../components/Link";
import { useNextMeetingReminder } from "../hooks/JoinMeeting";

export default function SectionStartReminder() {
  const { t } = useTranslation("client\\containers\\SectionStartReminder");
  const { translate } = useMachineTranslation();
  const location = useLocation();
  const { isLoggedIn, userTimeZone } = useSession();
  const { data, loading } = useNextMeetingReminder(!isLoggedIn);

  const startTime = data?.nextMeetingReminder?.startTime;
  const sectionUid = data?.nextMeetingReminder?.sectionUid;
  const activityTitle = data?.nextMeetingReminder?.activityTitle;

  const fifteenMinutesAfterStart = Time.createDayjs(startTime).add(
    15,
    "minutes"
  );
  const fortyFiveMinutesBeforeStart = Time.createDayjs(startTime).subtract(
    45,
    "minutes"
  );

  const isInDisplayRange = Time.createDayjs().isBetween(
    fortyFiveMinutesBeforeStart,
    fifteenMinutesAfterStart
  );

  if (loading || !sectionUid || !activityTitle || !isInDisplayRange) {
    return null;
  }
  if (Routes.isSectionDetailPageOfSection(location, { uid: sectionUid })) {
    return null;
  }

  const fromNow = Time.createDayjs(startTime).fromNow();
  // @ts-ignore TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  const time = Time.formatTime(startTime, userTimeZone, true);
  return (
    <Banner>
      <Link to={Routes.classroomPath(sectionUid)}>
        {translate(activityTitle)}
      </Link>
      &nbsp;
      {Time.createDayjs().isBefore(startTime) ? (
        <span>{t("starts {{fromNow}}, at {{time}}", { fromNow, time })}</span>
      ) : (
        <span>{t("started {{fromNow}}, at {{time}}", { fromNow, time })}</span>
      )}
    </Banner>
  );
}
