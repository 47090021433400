import { Box, NavbarSearchBox, Theme } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import { searchPath } from "@outschool/routes";
import { useSession } from "@outschool/ui-auth";
import { useMobileSearchBar } from "@outschool/ui-components-website";
import { useIsMobile, useNavigation } from "@outschool/ui-utils";
import React from "react";
import { useLocation } from "react-router-dom";

import useExperiment from "../../hooks/useExperiment";
import { SearchFiltersContextProvider } from "../../lib/useSearchFilters";
import DesktopSearchField from "../search/desktop/DesktopSearchField";

function useGlobalSearchTeachersExperiment() {
  const { isLoggedIn } = useSession();
  const { variant, trigger } = useExperiment("GlobalSearchTeachers");
  const isTreatment = isLoggedIn && variant === "treatment";
  return {
    isTreatment,
    trigger
  };
}

export default function GlobalSearchBar({
  showSearchBox
}: {
  showSearchBox: boolean;
}) {
  const nav = useNavigation();
  const { t } = useTranslation("client\\components\\nav\\GlobalSearchBar");

  const { isTreatment, trigger } = useGlobalSearchTeachersExperiment();

  return (
    <Box
      sx={(theme: Theme) => ({
        width: "100%",
        paddingBottom: 5,
        [theme.breakpoints.down("md")]: {
          display: "none"
        }
      })}
    >
      {showSearchBox ? (
        <SearchFiltersContextProvider>
          <DesktopSearchField
            searchFilters={{}}
            setImplicitSearchFilters={() => {}}
            setSearchFilters={filters => {
              nav(searchPath(filters));
            }}
            clearDropdowns={() => {}}
            /* eslint-disable i18next/no-literal-string */
            searchSuggestionsToIgnore={isTreatment ? [] : ["teachers"]}
            experimentTrigger={trigger}
            showSearchSuggestions
            SearchBoxComponent={NavbarSearchBox}
            searchPlaceholder={t("Search online classes and tutors")}
            menuStyleSx={{
              top: "45px"
            }}
          />
        </SearchFiltersContextProvider>
      ) : (
        // simple box to keep header sizing the same with/without search
        <Box sx={{ height: 55 }} />
      )}
    </Box>
  );
}

export function MobileSearchBar() {
  const ref = React.useRef<HTMLElement>(null);
  const { showMobileSearchBar, setVerticalOffset } = useMobileSearchBar();
  const isMobile = useIsMobile();
  const location = useLocation();
  const isOnIgnoredPage = ["/search", "/users/login"].includes(
    location.pathname
  );

  React.useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const { height } = ref.current.getBoundingClientRect();

    setVerticalOffset(height);
  }, [setVerticalOffset]);
  if (!isMobile || isOnIgnoredPage || !showMobileSearchBar) {
    return null;
  }

  return (
    <Box
      ref={ref}
      sx={(theme: Theme) => ({
        width: "100%",
        position: "fixed",
        padding: "5px 10px",
        top: 0,
        left: 0,
        zIndex: 2,
        backgroundColor: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.grey[200]}`
      })}
    >
      <SearchBar />
    </Box>
  );
}

export function SearchBar() {
  const nav = useNavigation();
  const { t } = useTranslation("client\\components\\nav\\GlobalSearchBar");
  return (
    <SearchFiltersContextProvider>
      <DesktopSearchField
        searchFilters={{}}
        setImplicitSearchFilters={() => {}}
        setSearchFilters={filters => {
          nav(searchPath(filters));
        }}
        clearDropdowns={() => {}}
        /* eslint-disable i18next/no-literal-string */
        showSearchSuggestions
        SearchBoxComponent={NavbarSearchBox}
        searchPlaceholder={t("Search online classes and tutors")}
        menuStyleSx={{
          top: "45px"
        }}
      />
    </SearchFiltersContextProvider>
  );
}
