import { SearchFilters as SearchFiltersType } from "@outschool/gql-backend-generated";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { useTrackEvent } from "@outschool/ui-analytics";
import isEqual from "lodash/isEqual";
import mapKeys from "lodash/mapKeys";
import React from "react";

import * as helpers from "../../shared/helpers";

export default function useTrackSearchFiltersApplied() {
  const trackEvent = useTrackEvent();
  const [searchUid, setSearchUid] = React.useState(helpers.uuidv4());

  const trackSearchFiltersApplied = React.useCallback(
    ({
      filterCategory,
      oldFilters,
      newFilters
    }: {
      filterCategory?: string;
      oldFilters: SearchFiltersType;
      newFilters: SearchFiltersType;
    }) => {
      if (!isEqual(oldFilters, newFilters)) {
        const nextSearchUid = helpers.uuidv4();
        trackEvent("Search filters applied", {
          filterCategory,
          ...mapKeys(oldFilters, (_value, key) => `old_${key}`),
          ...mapKeys(newFilters, (_value, key) => `new_${key}`),
          old_searchUid: searchUid,
          new_searchUid: nextSearchUid
        });
        setSearchUid(nextSearchUid);
        return nextSearchUid;
      }
      return searchUid;
    },
    [searchUid, trackEvent]
  );

  return {
    searchUid,
    trackSearchFiltersApplied
  };
}
