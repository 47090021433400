import { Box } from "@outschool/backpack";
import {
  useMobileSearchBar,
  useTopNoticeContext
} from "@outschool/ui-components-website";
import {
  Banner,
  ConditionalTrackingPageSection
} from "@outschool/ui-legacy-component-library";
import { Screen, useDimensions, useOnScreen } from "@outschool/ui-utils";
import React from "react";
import ReactDOM from "react-dom";

import * as Env from "../../../../shared/Env";
import ErrorMessage from "../../ErrorMessage";

interface Props {
  watchNode?: React.RefObject<HTMLElement>;
  error?: string;
  children?: React.ReactNode;
}

export default function StickyHeader({ watchNode, error, children }: Props) {
  const topNode = React.useRef<HTMLElement>();
  const containerNode = React.useRef<HTMLElement>();
  const shouldBeHidden = useOnScreen(
    watchNode?.current || topNode?.current,
    true
  );
  const containerDimensions = useDimensions(containerNode.current);
  const topOffset = containerDimensions.height
    ? -containerDimensions.height
    : "-100vh";
  const isMobile = Screen.useIsMobile();
  const { topNotice, timedTopNotice } = useTopNoticeContext();
  const { verticalOffset } = useMobileSearchBar();
  const displayedError = error || topNotice || timedTopNotice;

  if (!isMobile) {
    return null;
  }

  return ReactDOM.createPortal(
    <ConditionalTrackingPageSection
      name="sticky_mobile_header"
      uniqueId={"sticky_mobile_header"}
    >
      {setImpressionNode => (
        <Box ref={setImpressionNode}>
          <Box
            ref={topNode}
            sx={{
              position: "absolute",
              top: 0
            }}
          />
          <Box
            ref={containerNode}
            sx={{
              position: "fixed",
              top: shouldBeHidden ? topOffset : verticalOffset,
              left: 0,
              right: 0,
              zIndex: shouldBeHidden ? -1000 : 1,
              transition: "top 0.3s ease"
            }}
          >
            {children}
            {displayedError && (
              <Banner>
                <ErrorMessage
                  value={displayedError}
                  showStatusPageLink={Env.IS_READ_ONLY_MODE}
                />
              </Banner>
            )}
          </Box>
        </Box>
      )}
    </ConditionalTrackingPageSection>,
    document.body
  );
}
