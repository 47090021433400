import { Box, Icon, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { CurrencyCode } from "@outschool/gql-backend-generated";
import { fasExclamationTriangle } from "@outschool/icons";
import { CURRENCIES_INFO, useTranslation } from "@outschool/localization";
import { Select, SelectOption } from "@outschool/ui-legacy-component-library";
import React from "react";

interface currencySelectorProps {
  selectedCurrency: CurrencyCode;
  setSelectedCurrency: React.Dispatch<React.SetStateAction<CurrencyCode>>;
  hideCurrencyDisclaimer: Boolean;
}

export default function CurrencySelector({
  selectedCurrency,
  setSelectedCurrency,
  hideCurrencyDisclaimer
}: currencySelectorProps) {
  const { t } = useTranslation(
    "client\\components\\localization\\CurrencySelector"
  );
  const { disclaimer, symbol } = CURRENCIES_INFO[selectedCurrency];
  const showDisclaimer = !!disclaimer && !hideCurrencyDisclaimer;

  const makeCurrencyLabel = (currencyCode: CurrencyCode) => {
    const { name } = CURRENCIES_INFO[currencyCode];
    return `${name} (${currencyCode})`;
  };

  const options: SelectOption<CurrencyCode>[] = Object.keys(
    CURRENCIES_INFO
  ).map((currencyCode: CurrencyCode) => {
    return {
      label: makeCurrencyLabel(currencyCode),
      value: currencyCode
    };
  });

  const currencySelectedHandler = (value: CurrencyCode) => {
    if (value) {
      setSelectedCurrency(value);
    }
  };

  return (
    <>
      <Box flex>
        <Box
          sx={{
            position: "relative",
            flex: "auto"
          }}
        >
          <Select
            data-test-id="currency-selector"
            options={options}
            value={selectedCurrency}
            handleChangeOnBlur={false}
            onChange={currencySelectedHandler}
            sx={{
              display: "block",
              paddingLeft: "22px",
              borderRadius: "5px"
            }}
            placeholder={t`Select a currency...`}
          />
          <Box
            sx={{
              color: "primary.700",
              fontWeight: "fontWeightBold",
              textAlign: "center",
              position: "absolute",
              width: "48px",
              top: 8
            }}
          >
            {symbol}
          </Box>
        </Box>
      </Box>

      {showDisclaimer && (
        <Box
          flex
          data-test-id="currency-picker-menu-disclaimer"
          sx={{
            marginTop: "10px"
          }}
        >
          <Box
            sx={{
              marginTop: "4px",
              marginRight: "4px"
            }}
          >
            <Icon
              icon={fasExclamationTriangle}
              sx={{
                color: "#B28B1E"
              }}
            />
          </Box>
          <Typography
            variant="inherit"
            sx={{
              maxWidth: "300px"
            }}
          >
            {disclaimer}
          </Typography>
        </Box>
      )}
    </>
  );
}
