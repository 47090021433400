import { gql } from "@outschool/ui-apollo";

export default gql`
  fragment EnrollmentMeetingPurchaseFragment on EnrollmentMeetingPurchase {
    uid
    activityUid
    enrollmentUid
    meetingUid
    orderSlugId
    confirmedAt
  }
`;
