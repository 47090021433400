import { UtmCls } from "@outschool/attribution";
import { queryStringToObject } from "@outschool/routes";
import Cookies from "js-cookie";
import lodashClone from "lodash/clone";
import lodashGet from "lodash/get";

import { isUuid } from "../../shared/helpers";
import { hrefToQueryString } from "../../shared/Routes";

import type { AnalyticsInterface } from "@outschool/ui-analytics";

/**
 * These functions store and retrieve attribution on the client that persists across multiple
 * browser sessions. They use Segment traits, which get persisted in localStorage.
 */
export async function updateFromLandingPageLocation(
  analytics: AnalyticsInterface,
  location: { href: string },
  referer: string
): Promise<Attribution | undefined> {
  if (Cookies.get("ignoreAttribution")) {
    Cookies.remove("ignoreAttribution");
    return undefined;
  }
  const anonymousId = await analytics.anonymousId();
  const newAttribution = createAttribution({
    location,
    referer,
    anonymousId
  });

  const oldAttribution = await analytics.attribution();
  if (!hasAttribution(newAttribution) && hasAttribution(oldAttribution)) {
    // Attribution was previously set already
    return undefined;
  }

  return newAttribution;
}

function scrubUsid(usid: string) {
  return usid?.split("#")?.[0];
}

interface CreateAttribution {
  location: { href: string };
  referer: string;
  anonymousId?: string;
}

export interface Attribution {
  created_at?: Date;
  referer?: string;
  landingPage?: string;
  anonymousId?: string;
  addressBar?: boolean;
  addressBarUserSlugId?: string;
  activity_uid?: string;
  usid?: string;
  ruid?: string;
  campaign?: string | null;
  content?: string | null;
  source?: string | null;
  medium?: string | null;
  term?: string | null;
}

export function createAttribution({
  location,
  referer,
  anonymousId
}: CreateAttribution): Attribution {
  const utm = new UtmCls(location.href);
  const attribution: Attribution = utm.isValid() ? lodashClone(utm) : {};
  attribution.created_at = new Date();
  attribution.referer = referer;
  attribution.landingPage = location.href;
  attribution.anonymousId = anonymousId;

  if (utm.isValid() || referer) {
    const { ruid, usid: rawUsid } = queryStringToObject(
      hrefToQueryString(location.href)
    );
    // edge case: scrub the users # out of the usid
    const usid = scrubUsid(rawUsid);
    attribution.ruid = ruid;
    // TODO: A bug caused some share urls to have usid to set to user.uid
    //       This code block will set ruid to user.uid instead of usid
    if (usid && isUuid(usid)) {
      attribution.ruid = usid;
    } else {
      attribution.usid = usid;
    }

    const activity_uid = (location.href.match(
      /(?:activity|activities)\/([a-zA-Z0-9\-]{36})/
    ) || [])[1];

    if (activity_uid) {
      attribution.activity_uid = activity_uid;
    }
  }

  return attribution;
}

function hasAttribution(attribution: Attribution | undefined) {
  attribution = attribution || {};
  const { campaign, source, medium, term, content, referer } = attribution;
  return Boolean(campaign || source || medium || term || content || referer);
}

/**
 * Remove attribution.ruid if it is equal to currentUser's uid,
 *    attribution.usid if it is equal to currentUser's slug_id,
 *    and attribution.addressBarUserSlugId if it is equal to currentUser's slug_id
 */
export async function updateAttributionForCurrentUser(
  analytics: AnalyticsInterface,
  currentUser: {
    uid: string;
    slug_id: string;
  }
) {
  let attribution = await analytics.attribution();
  const anonymousId = await analytics.anonymousId();
  let isModified = false;
  if (
    anonymousId &&
    (!attribution || attribution?.anonymousId !== anonymousId)
  ) {
    // attribution could be empty if updateFromLandingPageLocation did not set
    // any attribution
    attribution = attribution || {};
    attribution.anonymousId = anonymousId;
    isModified = true;
  }
  const userUid = currentUser ? currentUser.uid : undefined;
  const userSlugId = currentUser ? currentUser.slug_id : undefined;
  if (attribution && attribution.ruid === userUid) {
    delete attribution.ruid;
    isModified = true;
  }
  if (attribution && attribution.usid === userSlugId) {
    delete attribution.usid;
    isModified = true;
  }
  if (attribution && attribution.addressBarUserSlugId === userSlugId) {
    delete attribution.addressBar;
    delete attribution.addressBarUserSlugId;
    isModified = true;
  }
  if (attribution && isModified) {
    analytics.traits({ attribution });
  }
}

export function dict(analytics: AnalyticsInterface) {
  return get(analytics);
}

async function get(analytics: AnalyticsInterface) {
  const traits = analytics.traits();
  return lodashGet(traits, "attribution", null);
}
