import { I18nLocale } from "@outschool/localization";
import React from "react";
import { matchPath } from "react-router-dom";

import ROUTES from "../routes";

import type { History } from "history";

interface PageInfo {
  name?: string;
  category?: string;
  path?: string;
}

export default function usePageInfo(
  history: History,
  userSelectedLocale?: I18nLocale
) {
  const [info, setInfo] = React.useState<PageInfo>(
    getPageInfo(userSelectedLocale)
  );

  history.listen(() => {
    setInfo(getPageInfo(userSelectedLocale));
  });

  return info;
}

function getPageInfo(userSelectedLocale?: I18nLocale): PageInfo {
  const pattern = new RegExp(`^/${userSelectedLocale}/`);
  let pathname = window.location?.pathname?.replace(pattern, "/");
  const definition = ROUTES.find(route => {
    const match = matchPath(pathname, route);
    return match && (route.exact === false || match.isExact);
  });
  return {
    category: definition?.category,
    name: definition?.name,
    path: pathname
  };
}
