import { Box } from "@outschool/backpack";
import { browseRootPath } from "@outschool/routes";
import { useSession } from "@outschool/ui-auth";
import {
  useMobileSearchBar,
  useTopNoticeContext
} from "@outschool/ui-components-website";
import {
  Banner,
  ConditionalTrackingPageSection
} from "@outschool/ui-legacy-component-library";
import { Screen, useDimensions, useOnScreen } from "@outschool/ui-utils";
import React from "react";
import ReactDOM from "react-dom";

import * as Env from "../../../shared/Env";
import ErrorMessage from "../ErrorMessage";
import Link from "../Link";
import { StandardHeader } from "./Header";
import HeaderLogo from "./HeaderLogo";

function StickyMobileHeaderLeft() {
  const { isLoggedIn } = useSession();
  return isLoggedIn ? (
    <Link
      to={browseRootPath()}
      trackingName="nav_logo"
      style={{ display: "flex" }}
    >
      <HeaderLogo isWhiteText={false} />
    </Link>
  ) : null;
}

export default function StickyMobileHeader({
  hideFindClasses = false,
  leftChildren = <StickyMobileHeaderLeft />,
  watchNode = null,
  error = null,
  hideAboveTheFold = false
}: {
  hideFindClasses?: boolean;
  leftChildren?: any;
  watchNode?: any;
  error?: any;
  hideAboveTheFold?: boolean;
}): React.ReactPortal {
  /** Definitions
   * Above the fold: the visible part of a webpage before the user scrolls down.
   * The fold: where the loaded page first cuts off at the bottom of the screen.
   * Anything beneath this point is below the fold.
   * Usage
   * If you pass nothing, sticky header will show up as soon as you start scrolling
   * If you pass watchNode, sticky header will show up when watchNode is off screen
   * If you set hideAboveTheFold = true, sticky header will show up once you scroll
   *    below the fold
   * */
  const [topOfPage, setTopOfPage] = React.useState();
  const [theFold, setTheFold] = React.useState();
  const isWatchNodeOnScreen = useOnScreen(watchNode || topOfPage, true);
  const isTheFoldOnScreen = useOnScreen(theFold, true);
  const hideStickyHeader = hideAboveTheFold
    ? isTheFoldOnScreen || isWatchNodeOnScreen
    : isWatchNodeOnScreen;

  const [containerNode, setContainerNode] = React.useState();
  const containerDimensions = useDimensions(containerNode);
  const topOffset = containerDimensions.height
    ? -containerDimensions.height
    : "-100vh";
  const isMobile = Screen.useIsMobile();
  const { topNotice, timedTopNotice } = useTopNoticeContext();
  const displayedError = error || topNotice || timedTopNotice;
  const { verticalOffset } = useMobileSearchBar();
  // @ts-ignore TS(2322): Type 'ReactPortal | null' is not assignable to typ... Remove this comment to see the full error message
  return !isMobile
    ? null
    : ReactDOM.createPortal(
        <ConditionalTrackingPageSection
          name="sticky_mobile_header"
          uniqueId={"sticky_mobile_header"}
        >
          {setImpressionNode => (
            <Box ref={setImpressionNode}>
              <Box
                ref={setTopOfPage}
                sx={{
                  position: "absolute",
                  top: 0
                }}
              />
              <Box
                ref={setTheFold}
                sx={{
                  position: "absolute",
                  bottom: 0
                }}
              />
              <Box
                ref={setContainerNode}
                sx={{
                  position: "fixed",
                  opacity: hideStickyHeader ? 0 : 1,
                  top: hideStickyHeader ? topOffset : verticalOffset,
                  left: 0,
                  right: 0,
                  zIndex: 1000,
                  transition: "top 0.3s ease, opacity 0.3s ease"
                }}
              >
                <StandardHeader
                  hideFindClasses={hideFindClasses}
                  leftChildren={leftChildren}
                />
                {displayedError && (
                  <Banner>
                    <ErrorMessage
                      value={displayedError}
                      showStatusPageLink={Env.IS_READ_ONLY_MODE}
                    />
                  </Banner>
                )}
              </Box>
            </Box>
          )}
        </ConditionalTrackingPageSection>,
        document.body
      );
}
