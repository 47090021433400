import { useTranslation } from "@outschool/localization";
import { pathnameWithoutLocale } from "@outschool/routes";
import React from "react";
import { useLocation } from "react-router";

import { useSearchFilters } from "../../lib/useSearchFilters";

import type { SearchBoxComponentType } from "@outschool/backpack";

export default function ClassSearchForm({
  query,
  setQuery,
  onSubmit,
  placeholder,
  onInputFocus = () => {},
  getInputProps = () => ({}),
  spellCheck = true,
  SearchBoxComponent,
  searchLabel
}: {
  query: string;
  setQuery: (query: string) => void;
  SearchBoxComponent: SearchBoxComponentType;
  onSubmit?: (query: string) => void;
  placeholder?: string;
  onInputFocus?: () => void;
  getInputProps?: () => object;
  spellCheck?: boolean;
  searchLabel?: string;
  useGlobalNavUi?: boolean;
}) {
  const { t } = useTranslation("client\\components\\search\\ClassSearchForm");
  placeholder ??= t`Any topic or teacher`;
  const { clearSearchInputBoxRef, setFilters: setSearchFilters } =
    useSearchFilters();

  const inputRef = React.createRef<HTMLInputElement>();

  const handleSubmit = (e: $TSFixMe) => {
    e.preventDefault();

    if (inputRef.current) {
      inputRef.current.blur();
    }
    handleBlur();
  };

  const handleBlur = () => {
    if (onSubmit) {
      onSubmit(query);
    } else {
      // Default behavior: redirect to listings page with query & user filters
      setSearchFilters?.("Query", {
        q: query && query.length ? query : undefined
      });
    }
  };

  const handleChange = (e: $TSFixMe) => {
    setQuery(e.target.value || "");
  };

  const location = useLocation();

  const handleClear = () => {
    if (pathnameWithoutLocale(location.pathname) === "/search") {
      setSearchFilters?.("Query", {
        q: undefined
      });
    }
    setQuery("");
  };

  return (
    <SearchBoxComponent
      onSubmit={handleSubmit}
      onClear={handleClear}
      inputProps={{
        ref: inputRef,
        placeholder,
        value: query || "",
        onFocus: onInputFocus,
        onBlur: handleBlur,
        onChange: handleChange,
        spellCheck,
        getInputProps
      }}
      searchLabel={searchLabel || t("Search")}
      clearSearchInputBoxRef={clearSearchInputBoxRef}
      clearSearchAriaLabel={t("Clear")}
    />
  );
}
