import { Box, Image, Typography } from "@outschool/backpack";
import * as FilestackUrls from "@outschool/filestack-urls";
import { SignupModalQuery as SignupModalQueryType } from "@outschool/gql-frontend-generated";
import { useTranslation } from "@outschool/localization";
import { IntegrationCategory, useTrackEvent } from "@outschool/ui-analytics";
import { Query } from "@outschool/ui-apollo";
import {
  AuthTrigger,
  SignupModalQuery,
  useGetAuthReason,
  useLoginFlowContext,
  useSession
} from "@outschool/ui-auth";
import { ReferrerHeader } from "@outschool/ui-components-website";
import {
  Container,
  Loading,
  Modal
} from "@outschool/ui-legacy-component-library";
import { Screen } from "@outschool/ui-utils";
import _ from "lodash";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router";

import PerfectGiftForKids from "../../images/giftcard/PerfectGiftForKids.png";
import { MIN_PRICE, totalClassesString } from "../../shared/Activity";
import * as helpers from "../../shared/helpers";
import * as Routes from "../../shared/Routes";
import SignupForm from "./SignupForm";

export default function SignupModal() {
  const { authTrigger, isSignupModalOpen, exitLoginFlow, closeSignupModal } =
    useLoginFlowContext();
  const { t } = useTranslation("client\\components\\SignupModal");
  const { query } = useLocation();
  const { activitySlugOrUid, uidOrLink } = useParams<{
    activitySlugOrUid?: string;
    uidOrLink?: string;
  }>();
  const { isLoggedIn } = useSession();

  const isTeacherReferral = query?.teacherReferral === "true";
  const isTeacherFlow = query?.isTeacherFlow === "true";
  const isNotCloseable = query?.noClose === "true" || undefined;
  const shouldSkipLearners = query?.skipLearners === "true" || undefined;
  const isGiftCardSignup = query?.isGiftCardSignup === "true" || undefined;

  const activityUid =
    // @ts-ignore TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    (helpers.isUuid(activitySlugOrUid) && activitySlugOrUid) || null;
  const activitySlugId =
    (!activityUid &&
      activitySlugOrUid &&
      Routes.getIdFromSlug(activitySlugOrUid)) ||
    null;
  const skipActivity = !activityUid && !activitySlugId;

  // @ts-ignore TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  const leaderUid = (helpers.isUuid(uidOrLink) && uidOrLink) || null;
  const leaderLink = (!leaderUid && uidOrLink) || null;
  const skipLeader = !leaderUid && !leaderLink;

  // A querystring like ?usid=3&usid=3 can give us an array here instead of a string like we want
  // If this happens, just take the first value out of the array:
  const usid = query?.usid || null;
  const referrerSlugId = Array.isArray(usid) ? usid[0] : usid;
  const skipReferrer = !referrerSlugId;

  const variables = {
    skipActivity,
    activityUid,
    activitySlugId,
    referrerSlugId,
    skipReferrer,
    leaderUid,
    leaderLink,
    skipLeader
  };

  // This const is used to determine if the user is signing up for Outschool from the
  // navbar on desktop or from the mobile menu for the TeacherRegistrationLink experiment.
  // AuthTrigger.JOIN_OUTSCHOOL is used for signup except when the user is signing up via an
  // enrollment in a class. We also want to make sure that the user is not signing in as a teacher.
  const joinFromNavBar =
    authTrigger === AuthTrigger.JOIN_OUTSCHOOL && !isTeacherFlow;

  const [isRedirecting, setIsRedirecting] = useState(false);

  if (isLoggedIn) {
    return <></>;
  }
  return (
    <Modal
      ariaLabel={t`Join Outschool`}
      open={isSignupModalOpen}
      onClose={exitLoginFlow}
      fullBleed={false}
      hasCloseButton={!isNotCloseable}
      closeViaMaskClick={!isNotCloseable}
      closeViaEscButton={!isNotCloseable}
      iconButtonSx={{
        position: "relative",
        zIndex: 2
      }}
      sx={{
        maxWidth: 475
      }}
    >
      <Query
        query={SignupModalQuery}
        skip={!query || !query.signup}
        variables={variables}
        fetchPolicy="no-cache"
      >
        {({
          loading,
          data: {
            activity = undefined,
            referrer = undefined,
            leader = undefined
          } = {}
        }: $TSFixMe) => {
          if (loading || isRedirecting) {
            return (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Loading data-test-id="signup-modal-loading" />
              </Box>
            );
          }

          // @ts-ignore TS(2339): Property 'leader' does not exist on type 'never'.
          const leaderName = leader?.leader?.name;

          return (
            <Box data-test-id="signup-modal-content">
              <SignupModalHeader
                {...{
                  ...variables,
                  activity,
                  referrer,
                  leaderName,
                  authTrigger,
                  isTeacherFlow
                }}
              />

              <SignupForm
                promptLogin
                onRedirect={closeSignupModal}
                isTeacherFlow={isTeacherReferral || isTeacherFlow}
                trackingUniqueId="signup-modal-signup-form"
                signupParams={{
                  // @ts-ignore TS(2322): Type 'true | undefined' is not assignable to type ... Remove this comment to see the full error message
                  skipLearners: shouldSkipLearners,
                  // @ts-ignore TS(2322): Type 'true | undefined' is not assignable to type ... Remove this comment to see the full error message
                  isGiftCardSignup
                }}
                includeEmailSignupWithinModal={true} // Ensure we show Email Signup form within the modal
                joinFromNavBar={joinFromNavBar}
                setIsRedirecting={setIsRedirecting}
              />
            </Box>
          );
        }}
      </Query>
    </Modal>
  );
}

export function SignupModalHeader({
  authTrigger,
  referrer,
  activity,
  isTeacherFlow,
  leaderName
}: $TSFixMe) {
  if (authTrigger === AuthTrigger.JOIN_OUTSCHOOL) {
    return <JoinForFreeHeader isTeacherPath={isTeacherFlow} />;
  }
  if (authTrigger) {
    return (
      <AuthTriggerHeader
        authTrigger={authTrigger}
        leaderName={leaderName}
        referrer={referrer}
      />
    );
  }
  if (referrer) {
    return <ReferrerHeader referrer={referrer} />;
  }

  if (activity) {
    return <ActivityHeader activity={activity} referrer={referrer} />;
  }

  return <DefaultHeader />;
}

type DefaultHeaderProps = {
  title?: string;
  subtitle?: string;
};

export function DefaultHeader({ title, subtitle }: DefaultHeaderProps) {
  const { t } = useTranslation("client\\components\\SignupModal");
  title = title ?? t`Join Outschool`;
  subtitle = subtitle ?? t`Amazing classes and teachers await!`;
  return (
    <div data-test-id="default-signup-header">
      <Box
        sx={{
          marginBottom: "1em",
          marginTop: "1em",
          textAlign: "center"
        }}
      >
        <Typography
          variant="h2"
          sx={{
            marginBottom: "0.5em"
          }}
        >
          {title}
        </Typography>
        {subtitle}
      </Box>
    </div>
  );
}

function AuthTriggerHeader({ authTrigger, leaderName, referrer }: $TSFixMe) {
  const isMobile = Screen.useIsMobile();
  const { t } = useTranslation("client\\components\\SignupModal");
  const getAuthReason = useGetAuthReason();
  const authReason = getAuthReason(authTrigger);
  const trackAdEvent = useTrackEvent(IntegrationCategory.Advertising);
  React.useEffect(() => {
    trackAdEvent("Visit sign up - parent", { authReason });
  }, [authReason, trackAdEvent]);

  return (
    <Container
      sx={{
        display: "grid",
        gap: "small",
        justifyItems: "center",
        marginBottom: "medium"
      }}
      data-test-id="signup-modal-auth-trigger-header"
    >
      {authTrigger === AuthTrigger.ENROLL ? (
        <Box
          sx={{
            width: "100%"
          }}
        >
          <Typography
            variant={isMobile ? "h3" : "h2"}
            sx={{
              textAlign: "center",
              paddingTop: "1em"
            }}
          >
            {t("Join to Enroll")}
          </Typography>
        </Box>
      ) : authTrigger === AuthTrigger.REDEEM_GIFT_CARD ||
        authTrigger === AuthTrigger.BUY_GIFT_CARD ? (
        <Box
          sx={{
            position: "relative"
          }}
        >
          <Box
            sx={{
              position: "absolute",
              left: "-32px",
              width: "calc(100% + 64px)",
              top: "-16px",
              height: "312px"
            }}
          >
            <Box
              sx={(theme: $TSFixMe) => ({
                position: "relative",
                width: "100%",
                height: "100%",
                borderRadius: "0.5em",

                [theme.breakpoints.up("md")]: {
                  borderRadius: "0.5em"
                },

                overflow: "hidden"
              })}
            >
              <Box
                sx={{
                  position: "absolute",
                  width: "1072px",
                  height: "1147px",
                  bottom: "0px",
                  left: "calc(50% - 536px)",
                  borderRadius: "50%",
                  backgroundColor: "primary.200",
                  overflow: "hidden"
                }}
              />
            </Box>
          </Box>
          <Typography
            variant="h3"
            sx={{
              position: "relative",
              textAlign: "center",
              paddingTop: "1em"
            }}
          >
            {t("Join to {{authReason}}", { authReason })}
          </Typography>
          <Box
            sx={{
              position: "relative",
              textAlign: "center",
              width: "100%"
            }}
          >
            {t(
              "Outschool offers thousands of live online classes for kids ages 3–18."
            )}
          </Box>
          <Box
            sx={{
              position: "relative",
              margin: "auto",
              height: "225px",
              width: "225px"
            }}
          >
            <Image
              sx={{
                position: "absolute",
                top: "18px"
              }}
              height={200}
              src={PerfectGiftForKids}
            />
          </Box>
        </Box>
      ) : referrer ? (
        <Box
          sx={{
            marginTop: "1em"
          }}
        >
          <ReferrerHeader referrer={referrer} />
        </Box>
      ) : (
        <Box>
          <Typography
            variant={isMobile ? "h3" : "h2"}
            sx={{
              textAlign: "center",
              paddingTop: "1em"
            }}
          >
            {t("Join to {{authReason}}", {
              authReason: leaderName
                ? // @ts-ignore TS(2531): Object is possibly 'null'.
                  authReason.replace("this teacher", leaderName)
                : authReason
            })}
          </Typography>
          {authTrigger !== AuthTrigger.BUY_SUBSCRIPTION && (
            <Box
              sx={{
                textAlign: "center",
                width: "100%"
              }}
            >
              {t("Free to join, pay as low as {{minimumPrice}} per class.", {
                minimumPrice: MIN_PRICE
              })}
            </Box>
          )}
        </Box>
      )}
    </Container>
  );
}

function JoinForFreeHeader({ isTeacherPath }: $TSFixMe) {
  const isMobile = Screen.useIsMobile();
  const { t } = useTranslation("client\\components\\SignupModal");
  const trackAdEvent = useTrackEvent(IntegrationCategory.Advertising);
  const getAuthReason = useGetAuthReason();
  const authReason = getAuthReason(AuthTrigger.JOIN_OUTSCHOOL);
  React.useEffect(() => {
    if (isTeacherPath) {
      trackAdEvent("Visit sign up - teacher", { authReason });
    } else {
      trackAdEvent("Visit sign up - parent", { authReason });
    }
  }, [authReason, isTeacherPath, trackAdEvent]);

  return (
    <Box
      flex
      sx={{
        display: "grid",
        gap: "0.5em",
        justifyItems: "center",
        marginBottom: "2em"
      }}
      data-test-id="signup-modal-join-free-header"
    >
      <Typography
        variant={isMobile ? "h3" : "h2"}
        sx={{
          textAlign: "center",
          paddingTop: "1em"
        }}
      >
        {t("Join Outschool For Free")}
      </Typography>
      {!isTeacherPath && (
        <Box
          sx={{
            textAlign: "center",
            width: "100%",
            fontWeight: 500
          }}
        >
          {t(
            "Explore any interest with {{numClasses}}+ classes, pay as low as {{minimumPrice}} per class.",
            { numClasses: totalClassesString, minimumPrice: MIN_PRICE }
          )}
        </Box>
      )}
    </Box>
  );
}

type ActivityHeaderProps = {
  // @ts-ignore TS(2344): Type 'string' does not satisfy the constraint 'nev... Remove this comment to see the full error message
  activity: Pick<SignupModalQueryType["activity"], "title" | "details">;
  referrer?: SignupModalQueryType["referrer"];
  title?: string;
  subtitle?: string;
};

export function ActivityHeader({
  activity,
  referrer,
  title,
  subtitle
}: ActivityHeaderProps) {
  const { t } = useTranslation("client\\components\\SignupModal");

  title = title ?? t`Join Outschool`;
  let referrerName;
  if (referrer) {
    referrerName = _.get(referrer, "leader.approved")
      ? // @ts-ignore TS(2531): Object is possibly 'null'.
        referrer.leader.name
      : // @ts-ignore TS(2531): Object is possibly 'null'.
        referrer.parent.name;
  }
  subtitle =
    subtitle ||
    (referrerName
      ? t(
          "Accept {{referrerName}}'s invitation to get $20 off to try your first class",
          { referrerName }
        )
      : t("Discover amazing classes like:"));
  // @ts-ignore TS(2571): Object is of type 'unknown'.
  const imageUrl = FilestackUrls.imageUrl(activity.details.photo, {
    w: 375,
    h: 198,
    fit: "crop"
  });
  return (
    <div data-test-id="signup-modal-activity-header">
      <Box
        sx={{
          marginBottom: "1em",
          textAlign: "center"
        }}
      >
        <div>
          <Typography
            variant="h3"
            sx={{
              marginBottom: "0.5em"
            }}
          >
            {title}
          </Typography>
          <Box
            sx={{
              marginBottom: "1em"
            }}
          >
            {subtitle}
          </Box>
          <Image
            src={imageUrl}
            alt={t("User-provided cover image for this class")}
            sx={{
              width: "100%",
              boxSizing: "border-box",
              borderRadius: "4px",
              marginBottom: "1em"
            }}
          />
          <Box
            sx={{
              fontWeight: 500,
              color: "#808080",
              marginBottom: "1em"
            }}
          >
            {/* @ts-ignore TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message */}
            {activity.titleTranslated}
          </Box>
        </div>
      </Box>
    </div>
  );
}
