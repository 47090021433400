// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
/** @jsx jsxPragma */

import { addParamsToUrl, updateQueryParam } from "@outschool/routes";
import {
  BaseSelectProps,
  Select,
  SelectOption,
  jsxPragma,
  prepareSelectOption
} from "@outschool/ui-legacy-component-library";
import _ from "lodash";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

export default Select;
export { prepareSelectOption };
export type { SelectOption };

type ControlledSelectProps = {
  onChange?: (v: string) => void;
  options: string[];
  value: string;
} & BaseSelectProps;
export const ControlledSelect = ({
  options,
  placeholder,
  includeBlank,
  onChange,
  ...otherProps
}: ControlledSelectProps) => (
  /* eslint-disable-next-line jsx-a11y/no-onchange */
  <select
    onChange={({ target: { value } }) => onChange && onChange(value)}
    {...otherProps}
  >
    {placeholder ? <option value="">{placeholder}</option> : null}
    {options.concat(includeBlank ? [""] : []).map((opt, index) => (
      <option value={opt} key={index}>
        {opt}
      </option>
    ))}
  </select>
);

export function useURLParameter({
  paramName,
  pushHistory,
  defaultValue
}: {
  paramName: string;
  pushHistory?: boolean;
  defaultValue?: string;
}): [string, (v: string) => void] {
  const location = useLocation() as any;
  const history = useHistory();

  return [
    location.query[paramName] ?? defaultValue,
    (value: string) => {
      if (location.query[paramName] === value) {
        return;
      }
      const newLocation = updateQueryParam(location, paramName, value);
      const path = addParamsToUrl(newLocation.pathname, newLocation.query);

      if (pushHistory) {
        history.push(path);
      } else {
        history.replace(path);
      }
    }
  ];
}

type ParamSyncedSelect = {
  pushHistory: boolean;
  param: string;
} & ControlledSelectProps;
/**
 * This is a specialized Select component which uses a specified param in the
 * url as a source of truth, and updates it accordingly.
 */
export const ParamSyncedSelect: React.FC<ParamSyncedSelect> = ({
  param,
  options,
  pushHistory,
  onChange,
  ...selectProps
}: ParamSyncedSelect) => {
  const [paramValue, setParamValue] = useURLParameter({
    paramName: param,
    pushHistory
  });

  function handleSelectChange(paramValue: string) {
    const value = _.includes(options, paramValue) ? paramValue : options[0];
    onChange && onChange(value);
    setParamValue(value);
  }

  return (
    <ControlledSelect
      {...selectProps}
      onChange={handleSelectChange}
      defaultValue={paramValue}
      options={options}
    />
  );
};
