import { Search as SearchFilters } from "@outschool/business-rules";
import {
  Activity,
  Parent,
  SearchFilters as SearchFiltersType
} from "@outschool/gql-backend-generated";
import { TFunction, useTranslation } from "@outschool/localization";
import { Component } from "@outschool/ownership-areas";
import { OsEnvType, OsEnvironment } from "@outschool/platform";
import { DEFAULT_PARENT_NAME } from "@outschool/user";
import _ from "lodash";

import { totalClassesString } from "./Activity";
import * as Env from "./Env";

export function useClassPageTitle(
  activity: Pick<Activity, "age_max" | "age_min" | "isClub" | "titleTranslated">
): string | null {
  const { t } = useTranslation("shared\\PageMetadata");

  if (!activity || !activity.titleTranslated) {
    return null;
  }
  // Build and translate strings in a way that provides the most context.
  let activityDescription: string = "";
  const hasAgeRange = activity.age_min != null && activity.age_max != null;
  if (activity.isClub) {
    if (hasAgeRange) {
      activityDescription = t(
        "Moderated Online Community for Ages {{classAgeMin}}-{{classAgeMax}}",
        {
          classAgeMin: activity.age_min,
          classAgeMax: activity.age_max
        }
      );
    } else {
      activityDescription = t("Moderated Online Community");
    }
  } else {
    if (hasAgeRange) {
      activityDescription = t(
        "Small Online Class for Ages {{classAgeMin}}-{{classAgeMax}}",
        {
          classAgeMin: activity.age_min,
          classAgeMax: activity.age_max
        }
      );
    } else {
      activityDescription = t("Small Online Class");
    }
  }
  return `${activity.titleTranslated} | ${activityDescription}`;
}

export function getDefaultPageTitle(t: TFunction) {
  return t(
    "Set Learning Free: Let kids' curiosity run wild with classes and groups on any topic you can imagine."
  );
}

export function useDefaultPageTitle() {
  const { t } = useTranslation("shared\\PageMetadata");
  return t(
    "Set Learning Free: Let kids' curiosity run wild with classes and groups on any topic you can imagine."
  );
}

export function useDefaultPageDescription() {
  const { t } = useTranslation("shared\\PageMetadata");
  return t(
    "Over {{totalClassesAtOutschool}} classes, endless possibilities. We empower kids 3 to 18 to build their own curriculum of interactive, one-of-a-kind classes.",
    { totalClassesAtOutschool: totalClassesString }
  );
}

export function useSearchPageTitle(filters: SearchFiltersType): string {
  const { t } = useTranslation("shared\\PageMetadata");
  if (_.isEmpty(filters)) {
    return t("Find 5-star online classes for kids at Outschool");
  } else if (filters.theme && _.isEmpty(_.omit(filters, "theme"))) {
    const themeMetaTitles = {
      Arts: t("Online Art Classes for K-12"),
      "Coding & Tech": t("Online Coding Classes for K-12"),
      English: t("Online English Classes for K-12"),
      "Health & Wellness": t("Online Health and Wellness Classes for K-12"),
      "Life Skills": t("Online Life Skills Classes for K-12"),
      Math: t("Online Math Classes for K-12"),
      Music: t("Online Music Classes for K-12"),
      "Science & Nature": t("Online Science Classes for K-12"),
      "Social Studies": t("Online Social Studies Classes for K-12"),
      "World Languages": t("Online Language Classes for K-12")
    };

    // @ts-ignore
    const themePageTitle = themeMetaTitles[filters.theme];
    return (
      themePageTitle ||
      t("{{classesSearchTheme}} | Online Classes for K-12", {
        classesSearchTheme: filters.theme
      })
    );
  } else {
    // TODO: translate summarySentence
    return SearchFilters.summarySentence(filters, true) + "";
  }
}

export function useSearchPageDescription(theme: string): string {
  const { t } = useTranslation("shared\\PageMetadata");
  if (!theme) {
    return t(
      "Search our most popular online classes for kids. Find thousands of academic classes, tutoring, clubs, and more. Signup and set your kids up for success"
    );
  }

  const themeMetaDescriptions = {
    Arts: t(
      "Explore art through tea parties, and architecture history in Minecraft. Learn calligraphy, drawing, origami, acting, filmmaking, photography, and more!"
    ),
    "Coding & Tech": t(
      "Learn to use a computer, make a YouTube channel, game design, web design with HTML and CSS, programming languages like Java and Scratch and Python, and more!"
    ),
    English: t(
      "Learn and practice reading, study a novel, write a story, write an essay, get spelling and grammar help, and more!"
    ),
    "Health & Wellness": t(
      "Learn about nutrition, exercise, puberty, gratitude, social-emotional learning, and more!"
    ),
    "Life Skills": t(
      "Learn and practice logic, critical thinking, entrepreneurship, money management, career planning, and more!"
    ),
    Math: t(
      "Learn numbers, fractions, algebra, calculus, proofs, competitions, and more! Discover new math, reinforce fundamentals, or get extra help."
    ),
    Music: t(
      "Try singing or playing a new instrument, learn songwriting, composition, music theory, music history and more!"
    ),
    "Science & Nature": t(
      "Learn about ourselves and the world around us: the earth, marine and land animals, humans, climate, forensics, chemistry, physics and more!"
    ),
    "Social Studies": t(
      "Civics, world history, American history, geography, psychology, religions, and more."
    ),
    "World Languages": t(
      "Learn a language and practice with fellow students! Spanish, French, Chinese, Italian, and more!"
    )
  };

  const listingsThemeDescriptionIntro = t(
    "We offer a variety of interactive, small-group classes for kids and teens."
  );

  // @ts-ignore
  const themeDescription = themeMetaDescriptions[theme];
  return themeDescription
    ? `${listingsThemeDescriptionIntro} ${themeDescription}`
    : listingsThemeDescriptionIntro;
}

export const useParentPageTitle = (
  parent: Pick<Parent, "publicName">
): string => (!parent ? "" : parent.publicName ?? DEFAULT_PARENT_NAME);

export function useParentPageDescription(
  parent: Pick<Parent, "publicName" | "introduction">
): string {
  const { t } = useTranslation("shared\\PageMetadata");
  return !parent
    ? ""
    : parent.introduction
    ? // TODO translate parent.introduction
      _.truncate(parent.introduction, { length: 280, separator: /,? +/ })
    : t(
        "{{parentName}} is on Outschool to discover our small-group online classes for kids. Join us!",
        { parentName: parent.publicName }
      );
}

export function getClientPlatformEnv(clientWorkerName: string): OsEnvironment {
  const gitCommit = Env.COMMIT_SHA || "000000";
  const envType = (process.env.OS_ENV_TYPE || "dev") as OsEnvType;
  return {
    envType: envType,
    appName: "website",
    appWorker: clientWorkerName,
    workerType: "browser",
    appEnv: Env.APP_NAME || (envType === "test" ? "Test" : "Development"),
    alarmComponent: Component.Website,
    gitCommit,
    osUserAgent: `Website-${clientWorkerName}/${gitCommit} (browser)`
  };
}
