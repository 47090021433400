// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */

import { Box, Icon } from "@outschool/backpack";
import { faClock } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import {
  dayjs,
  formatIANATimeZoneParts,
  formatUTCOffset,
  getAllTimeZoneNames
} from "@outschool/time";
import {
  SelectOption,
  SelectWithOptGroups
} from "@outschool/ui-legacy-component-library";
import { fromPairs, sortBy, toPairs, uniqBy } from "lodash";
import * as React from "react";

export type TimeZoneOption = {
  value: string;
  label: string;
};

export function makeTimeZoneLabel(timeZone: string) {
  return `(${formatUTCOffset(timeZone)}) ${
    formatIANATimeZoneParts(timeZone)[1]
  }`;
}

export default function TimeZoneSelector({
  selectedTimeZone,
  setSelectedTimeZone,
  dataTestId = "timezone-selector"
}: {
  selectedTimeZone: string;
  setSelectedTimeZone(tz: string): void | Promise<void>;
  dataTestId?: string;
}) {
  const { t } = useTranslation(
    "client\\components\\localization\\TimeZoneSelector"
  );

  const timeZones = getAllTimeZoneNames();

  // Create object with lists of timezones keyed by continent name
  let optGroups: Record<string, SelectOption<string>[]> = {};
  for (let tz of timeZones) {
    const [continent] = formatIANATimeZoneParts(tz);
    optGroups[continent] ??= [];
    optGroups[continent].push({
      value: tz,
      label: makeTimeZoneLabel(tz)
    });
  }

  // Sort by continent (sorting objects is not always foolproof but works here)
  const defaultContinent = "US";
  optGroups = fromPairs(
    sortBy(toPairs(optGroups), ([continent]) => {
      // Force the default continent to be sorted first
      return continent === defaultContinent ? "." : continent;
    })
  );

  // Sort timezones within optgroups and remove any duplicates
  for (let continent in optGroups) {
    const thisGroup = optGroups[continent];
    const sortedGroup = sortBy(
      thisGroup,
      ({ value }) => -dayjs.tz(value).utcOffset()
    );
    const dedupedGroup = uniqBy(sortedGroup, "label");
    optGroups[continent] = dedupedGroup;
  }

  const timeZoneSelectedHandler = async (value: TimeZoneOption) => {
    if (value) {
      await setSelectedTimeZone(value as any);
    }
  };

  return (
    <Box flex>
      <Box
        sx={{
          position: "relative",
          flex: "auto"
        }}
      >
        <SelectWithOptGroups
          data-test-id={dataTestId}
          optGroups={optGroups}
          value={selectedTimeZone}
          handleChangeOnBlur={false}
          onChange={timeZoneSelectedHandler}
          sx={{
            display: "block",
            paddingLeft: "22px",
            borderRadius: "5px"
          }}
          placeholder={t`Select a timezone...`}
        />
        <Icon
          aria-hidden
          icon={faClock}
          sx={{
            color: "primary.500",
            position: "absolute",
            left: 15,
            top: 13
          }}
        />
      </Box>
    </Box>
  );
}
