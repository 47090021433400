import { gql } from "@outschool/ui-apollo";

export const createTeacherCredentialMutation = gql`
  mutation createTeacherCredential(
    $credentialType: CredentialType!
    $file: CredentialFileInput!
    $teacherCertificateCredentialInput: TeacherCertificateCredentialInput
    $collegeDegreeCredentialInput: CollegeDegreeCredentialInput
    $otherCredentialInput: OtherCredentialInput
  ) {
    createTeacherCredential(
      credentialType: $credentialType
      file: $file
      teacherCertificateCredentialInput: $teacherCertificateCredentialInput
      collegeDegreeCredentialInput: $collegeDegreeCredentialInput
      otherCredentialInput: $otherCredentialInput
    ) {
      uid
    }
  }
`;

export const deleteTeacherCredentialMutation = gql`
  mutation deleteTeacherCredential($uid: ID!) {
    deleteTeacherCredential(uid: $uid)
  }
`;
