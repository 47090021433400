import { Button, ButtonProps } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { AuthTrigger, useLoginFlowContext } from "@outschool/ui-auth";
import React from "react";

export default function JoinAndWelcomeButton(props: ButtonProps) {
  const { enterLoginFlow } = useLoginFlowContext();

  return (
    <Button
      variant="contained"
      {...props}
      onClick={(e: any) => {
        enterLoginFlow({
          authTrigger: AuthTrigger.JOIN_OUTSCHOOL
        });

        props.onClick?.(e);
      }}
    />
  );
}
