import { ApolloError } from "@outschool/ui-apollo";
import { ErrorMessage } from "@outschool/ui-components-shared";
import { useTopNoticeContext } from "@outschool/ui-components-website";
import { Banner } from "@outschool/ui-legacy-component-library";
import React, { useEffect } from "react";

import * as Env from "../../shared/Env";

type Props = {
  topNotice?: string | ApolloError;
};

export default function TopNoticeBar({ topNotice: propTopNotice }: Props) {
  const { setTopNotice, topNotice: appTopNotice } = useTopNoticeContext();

  useEffect(() => {
    setTopNotice(null);
  }, [setTopNotice]);

  const topNotice = propTopNotice || appTopNotice;

  return topNotice ? (
    <Banner sticky>
      <ErrorMessage
        value={topNotice}
        showStatusPageLink={Env.IS_READ_ONLY_MODE}
      />
    </Banner>
  ) : null;
}
