import { getReferrerAttribution } from "@outschool/attribution";
import { subscriptionsPath } from "@outschool/routes";
import { useSession } from "@outschool/ui-auth";
import { useCurrentSubscription } from "@outschool/ui-components-website";
import { useLocation } from "react-router";

import { FirstMonthOffCoupon } from "../../../../shared/constants/StripeCoupons";

export function useHasFirstMonthOffCouponApplied() {
  const { isLoggedIn } = useSession();
  const { couponCode, landingPage } = getReferrerAttribution();
  const hasFirstMonthCouponApplied =
    landingPage?.startsWith("/unlimited") && couponCode === FirstMonthOffCoupon;

  const location = useLocation();
  const isOnUnlimitedLandingPage = location.pathname === subscriptionsPath();
  const userHasActiveSubscription = useCurrentSubscription({ isLoggedIn });

  return (
    hasFirstMonthCouponApplied &&
    isOnUnlimitedLandingPage &&
    !userHasActiveSubscription.hasActiveSubscription
  );
}
