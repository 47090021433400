import { SystemStyleObject } from "@styled-system/css";

export { default as StripeCardCvc } from "./CardCvc";
export { default as StripeCardExpiry } from "./CardExpiry";
export { default as StripeCardNumber } from "./CardNumber";
export { default as StripeCardZip } from "./CardZip";
export { default as StripePaymentRequestButton } from "./PaymentRequestButton";
export { default as StripeCardBuyerName } from "./CardBuyerName";

export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      height: "24px",
      lineHeight: "24px",
      fontFamily: "Helvetica Neue",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      "::placeholder": {
        color: "#B6BCC4"
      }
    },
    invalid: {
      color: "#2e3238"
    }
  }
};

export enum Variants {
  valid = "valid",
  invalid = "invalid"
}

type VariantDefaultSx = {
  [Variants.valid]: SystemStyleObject;
  [Variants.invalid]: SystemStyleObject;
};

export const VARIANT_DEFAULT_SX: VariantDefaultSx = {
  [Variants.valid]: {
    border: "1px solid rgba(23, 25, 28, 0.1)",
    boxSizing: "border-box",
    boxShadow: "inset 0px 1px 1px rgba(102, 113, 123, 0.21)",
    borderRadius: "4px",
    height: "40px",
    padding: "8px 12.4px 0px 12px"
  },
  [Variants.invalid]: {
    border: "1px solid #f15d5d",
    boxSizing: "border-box",
    boxShadow: "inset 0px 1px 2px rgba(102, 113, 123, 0.21)",
    background: "#fae3e3",
    borderRadius: "4px",
    height: "40px",
    padding: "8px 12.4px 0px 12px",
    transition: "all 0.25s ease-in-out"
  }
};
