import { isHomePage } from "@outschool/routes";
import { useTokenContext } from "@outschool/ui-auth";
import { useNavigation } from "@outschool/ui-utils";
import React from "react";
import { useLocation } from "react-router";

import { loginPath } from "../../shared/Routes";

export default function EsaRedirect() {
  const { getTokens, isEsaSession } = useTokenContext();
  const { sessionToken } = getTokens();
  const { pathname } = useLocation();
  const navigate = useNavigation();

  // If a user lands on the home page (which is where we redirect users to from
  // an ESA site), isn't authenticated and has an ESA session, prompt them to login.
  // This effect will only occur once when the site first loads. Only do this on the
  // home page to reduce the chances them opening up a tab in a new window will again
  // redirect them to the login.
  React.useEffect(() => {
    if (!sessionToken && isHomePage(pathname) && isEsaSession) {
      navigate(loginPath());
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [sessionToken, isEsaSession]);

  return null;
}
