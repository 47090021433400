import * as Auth from "@outschool/auth-shared";
import { isFixedLengthMultiDayLiveClass } from "@outschool/business-rules";
import {
  ActivitiesRow,
  MeetingsRow,
  SectionModeratorsRow,
  SectionsRow
} from "@outschool/db-queries/dist/generated/types";
import {
  Activity as ActivityType,
  Leader,
  Location,
  Meeting,
  OnlineClassroom,
  SearchFilters,
  Section,
  User as UserType
} from "@outschool/gql-backend-generated";
import { FullCurrentUserFragmentFragment } from "@outschool/gql-frontend-generated";
import { plural } from "@outschool/text";
import * as Time from "@outschool/time";
import { dayjs } from "@outschool/time";
import _ from "lodash";

import * as Activity from "./Activity";
import { REVIEWS_TIME_LIMIT_IN_DAYS } from "./Feedback";
import * as Geocoder from "./Geocoder";
import * as User from "./User";

type doesSectionHaveProperty<T> = (section: T) => boolean;
export type SectionTime = Pick<Section, "start_time" | "end_time">;
export type SectionTimeAndGqlStrings = SectionTime &
  Pick<Section, "meetingDays">;
type SectionOngoingTime = SectionTime & Pick<Section, "ongoing_stopped_at">;

type SectionTimeAndLeader = SectionTime & {
  leader: { __typename?: "Leader" } & Pick<Leader, "uid" | "name">;
};

export type SectionStatus = SectionTime &
  Pick<Section, "deleted_at" | "canceled_at" | "published_at"> &
  Partial<Pick<Section, "ongoing_stopped_at">>;

type OptionalFilledSpaceCount = Partial<Pick<Section, "filledSpaceCount">>;
type SectionOfferedSpaces = Pick<Section, "size_max">;
export type SectionMaxSize = SectionOfferedSpaces & OptionalFilledSpaceCount;
type SectionMinSize = Pick<Section, "size_min"> & OptionalFilledSpaceCount;
export type SectionSize = SectionMinSize & SectionMaxSize;

export type ActivityFrequency =
  | Pick<
      ActivitiesRow,
      "weekly_meetings" | "duration_weeks" | "is_ongoing_weekly"
    >
  | Pick<
      ActivityType,
      "weekly_meetings" | "duration_weeks" | "is_ongoing_weekly"
    >;
export type ActivityClubFrequency = ActivityFrequency &
  (
    | Pick<ActivitiesRow, "is_club" | "published_at">
    | Pick<ActivityType, "isClub" | "published_at">
  );

export type ActivityFormatAndFrequency =
  | ActivityClubFrequency & Pick<ActivityType, "hasTeacherSchedule">;

export type ActivityDuration = (
  | Pick<ActivityType, "duration_weeks" | "duration_minutes">
  | Pick<ActivitiesRow, "duration_weeks" | "duration_minutes">
) &
  ActivityFrequency;

export type ActivityWaitlistable = Pick<ActivityType, "allowWaitlist">;
type MeetingTime = Pick<Meeting, "start_time" | "end_time">;

export type SectionSearchFilters = Pick<
  SearchFilters,
  | "dow"
  | "endBy"
  | "endByTime"
  | "startAfter"
  | "startAfterTime"
  | "startBefore"
>;

enum EDITING_STATUS {
  DRAFT = "draft",
  SCHEDULED = "scheduled",
  CANCELED = "canceled",
  DELETED = "deleted"
}

enum TIMING_STATUS {
  PAST = "past", // TODO(cp): Rename to COMPLETE?
  CURRENT = "current", // TODO(cp): Rename to IN_PROGRESS?
  FUTURE = "future"
}

const editingStatus: (
  section: SectionStatus
) => null | EDITING_STATUS = section => {
  if (!section) {
    return null;
  }

  if (section.deleted_at) {
    return EDITING_STATUS.DELETED;
  } else if (section.canceled_at) {
    return EDITING_STATUS.CANCELED;
  } else if (section.start_time && section.end_time) {
    return EDITING_STATUS.SCHEDULED;
  } else {
    return EDITING_STATUS.DRAFT;
  }
};

const timingStatus: (
  section: SectionTime
) => TIMING_STATUS | null = section => {
  const now = new Date();
  if (!!(section && section.end_time && new Date(section.end_time) < now)) {
    return TIMING_STATUS.PAST;
  } else if (
    section &&
    section.start_time &&
    new Date(section.start_time) < now &&
    (!section.end_time || new Date(section.end_time) > now) // no section end_time means ongoing || has not ended
  ) {
    return TIMING_STATUS.CURRENT;
  } else if (
    !!(section && section.start_time && new Date(section.start_time) > now)
  ) {
    return TIMING_STATUS.FUTURE;
  }
  return null;
};

export const isDraft: doesSectionHaveProperty<SectionStatus> = section =>
  editingStatus(section) === EDITING_STATUS.DRAFT;
export const isScheduled: doesSectionHaveProperty<SectionStatus> = section =>
  editingStatus(section) === EDITING_STATUS.SCHEDULED;
export const isCanceled: doesSectionHaveProperty<SectionStatus> = section =>
  editingStatus(section) === EDITING_STATUS.CANCELED;
export const isDeleted: doesSectionHaveProperty<SectionStatus> = section =>
  editingStatus(section) === EDITING_STATUS.DELETED;

export const isPast: doesSectionHaveProperty<SectionTime> = section =>
  timingStatus(section) === TIMING_STATUS.PAST;
export const isComplete: doesSectionHaveProperty<SectionTime> = isPast;
export const isInProgress: doesSectionHaveProperty<SectionTime> = section =>
  timingStatus(section) === TIMING_STATUS.CURRENT;
export const isFuture: doesSectionHaveProperty<SectionTime> = section =>
  timingStatus(section) === TIMING_STATUS.FUTURE;
export const isStopped: doesSectionHaveProperty<
  Pick<Section, "ongoing_stopped_at">
> = section =>
  section.ongoing_stopped_at && dayjs(section.ongoing_stopped_at).isBefore();

export const startedMoreThanNinetyDaysAgo: doesSectionHaveProperty<
  SectionTime
> = ({ start_time }) => {
  const sectionStart = dayjs(start_time);
  const now = dayjs();
  return dayjs.duration(now.diff(sectionStart)).asDays() > 90;
};

const NON_ONGOING_SCHEDULING_DEADLINE_IN_WEEKS = 2;

export const scheduleLocked: (
  activity: ActivityDuration,
  section: SectionOngoingTime
) => boolean = (activity, section) => {
  return Boolean(
    !!activity.is_ongoing_weekly
      ? (section.ongoing_stopped_at &&
          dayjs(section.ongoing_stopped_at).isBefore()) ||
          (section.end_time && dayjs(section.end_time).isBefore())
      : section.end_time &&
          dayjs(section.end_time)
            .add(NON_ONGOING_SCHEDULING_DEADLINE_IN_WEEKS, "week")
            .isBefore()
  );
};

export const canCreateMeetings: (
  activity: ActivityDuration,
  section: SectionOngoingTime,
  times: Pick<MeetingsRow, "start_time" | "end_time">[]
) => boolean = (activity, section, times) => {
  if (!!activity.is_ongoing_weekly) {
    if (Boolean(section.ongoing_stopped_at)) {
      return _.every(
        times,
        meeting =>
          meeting.start_time &&
          meeting.end_time &&
          dayjs(meeting.start_time).isBefore(
            dayjs(Time.endOfIsoWeekUtc(dayjs()))
          ) &&
          dayjs(meeting.end_time).isSameOrBefore(
            dayjs(Time.endOfIsoWeekUtc(dayjs()))
          )
      );
    } else {
      return !Boolean(section.end_time) || dayjs(section.end_time).isAfter();
    }
  } else {
    return (
      !Boolean(section.end_time) ||
      dayjs(section.end_time)
        .add(NON_ONGOING_SCHEDULING_DEADLINE_IN_WEEKS, "week")
        .isAfter()
    );
  }
};

export const canDeleteMeetings: (
  section: SectionOngoingTime
) => boolean = section => {
  return Boolean(section.end_time && dayjs(section.end_time).isAfter());
};

/**
 * @deprecated use `@outschool/section` instead
 */
export const offeredSpaces: (
  section: SectionOfferedSpaces
) => number = section => {
  return section.size_max ?? 0;
};

export const sizeMaxOptions = (section: SectionOfferedSpaces) =>
  _.range(0, offeredSpaces(section) + 1);

/**
 * @deprecated use `@outschool/section` instead
 */
export const availableSpaces = (
  section: SectionMaxSize,
  filledSpaceCount: number = 0
) => {
  const available =
    offeredSpaces(section) -
    (filledSpaceCount || section.filledSpaceCount || 0);
  return Math.max(0, available);
};

// Returns the number of people who still need to enroll for the class to reach its minimum size.
// For cross-listed classes, assumes the non-outschool spaces are filled.
export const enrollmentsNeededForMinimum = (
  section: SectionMinSize,
  filledSpaceCount?: number,
  isCrosslisted?: boolean
) => {
  if (isCrosslisted) {
    return 0;
  }
  filledSpaceCount = filledSpaceCount || section.filledSpaceCount || 0;
  const enrollmentsNeeded = (section.size_min || 0) - filledSpaceCount;
  return Math.max(0, enrollmentsNeeded);
};

export const durationInMinutes = (
  activity: Pick<ActivityType, "duration_minutes">
) => {
  return activity && activity.duration_minutes;
};

export const firstSessionEndTime: (
  activity:
    | Pick<
        ActivitiesRow,
        | "weekly_meetings"
        | "duration_weeks"
        | "duration_minutes"
        | "is_ongoing_weekly"
        | "is_club"
      >
    | Pick<
        ActivityType,
        | "weekly_meetings"
        | "duration_weeks"
        | "duration_minutes"
        | "is_ongoing_weekly"
        | "isClub"
      >,
  section: SectionTime
) => dayjs.ConfigType = (activity, section) => {
  if (Activity.isSeries(activity)) {
    return dayjs(section.start_time)
      .add(durationInMinutes(activity) ?? 0, "minutes")
      .toDate();
  } else {
    return section.end_time;
  }
};

type SectionOnlineClassroom = {
  details: Pick<Section["details"], "onlineClassroom">;
};

export function onlineClassroom(
  section: SectionOnlineClassroom
): OnlineClassroom {
  if (section && section.details && section.details.onlineClassroom) {
    const type = section.details.onlineClassroom.type;
    const url = section.details.onlineClassroom.url;
    if (type === "zoom") {
      return section.details.onlineClassroom;
    } else if (!!url) {
      return section.details.onlineClassroom;
    }
  }
  return {
    type: "other",
    url: null
  };
}

export type SectionUsesOutschoolVideoChat = Partial<
  Pick<Section, "usesOutschoolVideoChat">
> &
  SectionOnlineClassroom;
export const usesOutschoolVideoChat: doesSectionHaveProperty<
  SectionUsesOutschoolVideoChat
> = section => {
  if (!!section.usesOutschoolVideoChat) {
    // Client-side: use GraphQL schema field
    return true;
  }
  // Server-side: use section.details.onlineClassroom.type
  const room = onlineClassroom(section);
  return room.type === "zoom";
};

// Slack thread explanation: https://outschool.slack.com/archives/C012H1L3X0F/p1596727241032200
// a teacher and parent can arrange to “buy out” a private section.  The teacher can set a price and the parent then can pay for everyone
export const isBuyoutSection: doesSectionHaveProperty<
  Pick<Section, "isPublished" | "price_cents">
> = section => Boolean(section && !section.isPublished && section.price_cents);

export const isPromotable: (
  activity: ActivityDuration,
  section: SectionStatus & SectionMaxSize,
  filledSpaceCount?: number
) => boolean = (activity, section, filledSpaceCount?) => {
  return (
    isScheduled(section) &&
    !isFull(section, filledSpaceCount) &&
    isPublished(section) &&
    (isFuture(section) ||
      (!!activity.is_ongoing_weekly && isInProgress(section)))
  );
};

export const isPrivate: doesSectionHaveProperty<SectionStatus> = section =>
  !isDraft(section) && !isPublished(section);

export type IgnoresStrictAgeRange = Parameters<typeof isPublished>[0] &
  Parameters<typeof isAutoScheduledDraft>[0];
export const ignoresStrictAgeRange = (section: IgnoresStrictAgeRange) =>
  !isPublished(section) && !isAutoScheduledDraft(section);

export const isFull = (section: SectionMaxSize, filledSpaceCount?: number) =>
  !!(section && availableSpaces(section, filledSpaceCount) <= 0);
export const isWaitlistable = (
  activity: ActivityWaitlistable,
  section: SectionMaxSize,
  filledSpaceCount?: number
) => {
  return !!(isFull(section, filledSpaceCount) && activity.allowWaitlist);
};

export const isNotAcceptingWaitlistSeats = (
  activity: ActivityWaitlistable & Pick<ActivityType, "isClub">,
  section: SectionMaxSize,
  filledSpaceCount?: number
) => {
  return !!(
    isFull(section, filledSpaceCount) &&
    !activity.allowWaitlist &&
    !activity.isClub
  );
};
export const isPublished: doesSectionHaveProperty<
  Pick<Section, "published_at"> | Pick<Section, "isPublished">
> = section =>
  section &&
  (("isPublished" in section && section.isPublished) ||
    ("published_at" in section && !!section.published_at));

/**
 * @deprecated use: `const { statusLabel } = useSectionString()`
 */
export function statusLabel(
  section: SectionStatus & SectionMaxSize,
  filledSpaceCount: number,
  hasEnrollment?: boolean
): string {
  if (!section) {
    return "";
  } else if (isDeleted(section)) {
    return "deleted";
  } else if (isCanceled(section)) {
    return "canceled";
  } else if (isPast(section)) {
    return "complete";
  } else if (isInProgress(section)) {
    return "in progress";
  } else if (isDraft(section)) {
    return "draft";
  } else if (isFull(section, filledSpaceCount)) {
    return "full";
  } else if (isFuture(section) && hasEnrollment) {
    return "upcoming";
  } else {
    return "bookable";
  }
}

export const canTakeAttendance: doesSectionHaveProperty<
  SectionTime
> = section => {
  if (section && section.start_time) {
    var timeBeforeStart = dayjs(section.start_time).subtract(1, "days");
    var timeAfterStart = dayjs(section.start_time).add(28, "days");
    var now = dayjs();
    if (now > timeBeforeStart && now < timeAfterStart) {
      return true;
    }
  }

  return false;
};

export const allowsLateEnrollment: (
  section: SectionStatus & SectionMaxSize & Pick<Section, "duration_minutes">,
  activity: Pick<
    ActivitiesRow,
    | "allows_recurring_payment"
    | "allows_late_enrollments_for_fixed_length_classes"
    | "weekly_meetings"
    | "is_ongoing_weekly"
    | "duration_weeks"
    | "uses_teacher_scheduling"
    | "duration_minutes"
    | "is_club"
  >
) => boolean = (section, activity) => {
  if (isFixedLengthMultiDayLiveClass(activity)) {
    // ok if activity allows late enrollment and there is one meeting left
    const durationMinutes =
      section.duration_minutes ?? activity.duration_minutes;
    return (
      isInProgress(section) &&
      !isFull(section, section.filledSpaceCount) &&
      !!activity.allows_late_enrollments_for_fixed_length_classes &&
      durationMinutes !== null &&
      dayjs(section.end_time).subtract(durationMinutes, "minutes").isAfter()
    );
  } else {
    // ok if started within a week, and end date is more than a week away
    return (
      isInProgress(section) &&
      dayjs(section.end_time).subtract(1, "week").isAfter() &&
      dayjs(section.start_time).add(1, "week").isAfter() &&
      !isFull(section, section.filledSpaceCount) &&
      !activity.allows_recurring_payment
    );
  }
};

export const hasLocation: doesSectionHaveProperty<
  Pick<Section, "location">
> = section => {
  return Boolean(
    section &&
      section.location &&
      section.location.city &&
      section.location.lat !== undefined &&
      section.location.lng !== undefined
  );
};

export const placeLabel: (
  activity: Pick<ActivityType, "is_online" | "location">,
  section: Pick<Section, "location">
) => string | undefined = (activity, section) => {
  if (Activity.isOnline(activity)) {
    return "Online";
  }

  const location: Location | null =
    (section && hasLocation(section) ? section.location : null) ||
    (activity && Activity.hasLocation(activity) ? activity.location : null);
  if (location) {
    const state =
      location.state ||
      Geocoder.resultToStateAbbreviation((location as any).geo);
    if (location.city && state) {
      return [location.city, state].join(", ");
    }
  }
  const placeName =
    (section && section.location && section.location.placeName) ||
    (activity && activity.location && activity.location.placeName);
  return placeName || undefined;
};

const WEEKDAYS = [1, 2, 3, 4, 5];

export const matchesTimePreferences: (
  activity: ActivityDuration,
  section: SectionTime,
  preferredTimeRanges: string[],
  timeZone: string,
  nextOngoingMeeting?: MeetingTime
) => boolean = (
  activity,
  section,
  preferredTimeRanges,
  timeZone,
  nextOngoingMeeting
) => {
  if (Activity.isFlexSchedule(activity)) {
    return true;
  }
  if (!preferredTimeRanges || (!preferredTimeRanges.length && !timeZone)) {
    return true;
  }
  preferredTimeRanges = preferredTimeRanges || [];
  let startTime = nextOngoingMeeting?.start_time
    ? dayjs(nextOngoingMeeting.start_time)
    : dayjs(section.start_time);
  let day = startTime.day();
  let hour = startTime.hour();
  if (timeZone && startTime.isValid()) {
    startTime = startTime.tz(timeZone);
    day = startTime.day();
    hour = startTime.hour();
    if (hour < Time.DAY_START_HOUR) {
      return false;
    }
    if (hour >= Time.DAY_END_HOUR) {
      return false;
    }
  }
  if (!preferredTimeRanges.length) {
    return true;
  }
  const isSummerSection = isSummer(section);
  return preferredTimeRanges.some(time => {
    switch (time) {
      case "Monday":
        return day === 1;
      case "Tuesday":
        return day === 2;
      case "Wednesday":
        return day === 3;
      case "Thursday":
        return day === 4;
      case "Friday":
        return day === 5;
      case "Saturday":
        return day === 6;
      case "Sunday":
        return day === 0;
      case "Weekend":
        return isSummerSection || day === 6 || day === 0;
      case "School hours":
        return isSummerSection || (day !== 6 && day !== 0);
      case "After school":
        return (
          !timeZone ||
          isSummerSection ||
          (_.includes(WEEKDAYS, day) && hour >= Time.AFTER_SCHOOL_START_HOUR)
        );
      default:
        return true;
    }
  });
};

interface DurationStringArgs {
  section?: SectionTime;
  nextOngoingMeeting?: Pick<Meeting, "start_time"> | null;
  userTimeZone: string;
  showTimes?: boolean;
  useAltText?: boolean;
}

/**
 * @deprecated use: `const { fullDurationStringNotClub } = useSectionString()`
 */
export function fullDurationStringNotClub({
  activity,
  section,
  nextOngoingMeeting,
  userTimeZone,
  showTimes = true,
  useAltText = false
}: DurationStringArgs & { activity?: ActivityFrequency }): string {
  if (!activity || !section) {
    return "Suggest a time that works for you";
  }

  if (!section.start_time || !section.end_time) {
    return "Not yet scheduled";
  }

  if (!!activity.is_ongoing_weekly) {
    if (isFuture(section)) {
      let text = `Starts on`;
      if (useAltText) {
        text = `starting on`;
      }
      return `${text} ${Time.formatDateTimeWithWeekday(
        section.start_time,
        userTimeZone
      )}`;
    } else {
      return nextOngoingMeeting
        ? `${
            useAltText ? `meeting next on` : `Next meets on`
          } ${Time.formatDateTimeWithWeekday(
            nextOngoingMeeting.start_time,
            userTimeZone
          )}`
        : `${
            useAltText ? `started on` : `Started on`
          } ${Time.formatDateTimeWithWeekday(
            section.start_time,
            userTimeZone
          )}`;
    }
  } else if (Activity.isFlexSchedule(activity) || !showTimes) {
    return Time.fullDurationStringWithoutTimes(
      section.start_time,
      section.end_time,
      userTimeZone
    );
  } else {
    return Time.fullDurationStringWithStartTime(
      section.start_time,
      section.end_time,
      userTimeZone
    );
  }
}

/**
 * @deprecated use: `const { fullDurationString } = useSectionString()`
 */
export function fullDurationString({
  activity,
  section,
  nextOngoingMeeting,
  userTimeZone,
  showTimes = true,
  useAltText = false
}: DurationStringArgs & { activity?: ActivityClubFrequency }): string {
  if (!activity || !section) {
    return "Suggest a time that works for you";
  }
  if (Activity.isClub(activity) && Activity.isPublished(activity)) {
    return `Started on ${Time.formatDateTimeWithWeekday(
      activity.published_at,
      userTimeZone
    )}`;
  }
  return fullDurationStringNotClub({
    activity,
    section,
    nextOngoingMeeting,
    userTimeZone,
    showTimes,
    useAltText
  });
}

/**
 * @deprecated use: `const { fullDurationStringWithTeacherName } = useSectionString()`
 */
export const fullDurationStringWithTeacherName: ({
  activity,
  section,
  nextOngoingMeeting,
  userTimeZone,
  showTimes
}: {
  activity?: ActivityClubFrequency;
  section?: SectionTimeAndLeader;
  nextOngoingMeeting?: Pick<Meeting, "start_time"> | null;
  userTimeZone: string;
  showTimes?: boolean;
}) => string = ({
  activity,
  section,
  nextOngoingMeeting,
  userTimeZone,
  showTimes = true
}) => {
  let formattedString = fullDurationString({
    activity,
    section,
    nextOngoingMeeting,
    userTimeZone,
    showTimes
  });

  if (!activity || !section || !section.start_time || !section.end_time) {
    return formattedString;
  } else {
    return `${formattedString} - Taught by ${section.leader.name}`;
  }
};

/**
 * @deprecated use: `const { briefDurationString } = useSectionString()`
 */
export const briefDurationString: (
  activity:
    | Pick<
        ActivitiesRow,
        | "weekly_meetings"
        | "duration_weeks"
        | "duration_minutes"
        | "is_ongoing_weekly"
        | "is_club"
      >
    | Pick<
        ActivityType,
        | "weekly_meetings"
        | "duration_weeks"
        | "duration_minutes"
        | "is_ongoing_weekly"
        | "isClub"
      >,
  section: SectionTime,
  userTimeZone: string
) => string = (activity, section, userTimeZone) => {
  if (!activity || Activity.isClub(activity) || !section) {
    return "";
  }
  if (!section.start_time || !section.end_time) {
    return "Not yet scheduled";
  }
  const datesDuration = Time.briefDurationStringWithoutTimes(
    section.start_time,
    section.end_time,
    userTimeZone
  );
  if (Activity.isFlexSchedule(activity)) {
    return datesDuration;
  } else {
    const timesDuration = Time.briefDurationTimesString(
      section.start_time,
      firstSessionEndTime(activity, section),
      userTimeZone
    );
    return `${datesDuration} from ${timesDuration}`;
  }
};

/**
 * @deprecated use: `const { remainingSpotsMessage } = useSectionString()`
 */
export const remainingSpotsMessage: (
  section: SectionSize,
  filledSpaceCount: number,
  minSize?: number
) => string = (section, filledSpaceCount, minSize) => {
  if (!section) {
    return "";
  }
  const remaining = availableSpaces(section, filledSpaceCount);

  // 1:1 classes should have no message
  if (offeredSpaces(section) === 1 && remaining === 1) {
    return "";
  }

  if (remaining > 3) {
    if (filledSpaceCount >= (minSize || section?.size_min || 0)) {
      return `${filledSpaceCount} ${plural(
        "learner",
        filledSpaceCount
      )} enrolled`;
    } else {
      return "";
    }
  } else if (remaining <= 0) {
    return "This section is sold out.";
  } else if (remaining === 1) {
    return "Only one spot remaining";
  } else {
    return `Only ${remaining} spots remaining`;
  }
};

/**
 * @deprecated use: `const { statusMessage } = useSectionString()`
 */
export const statusMessage: (
  activity: ActivityDuration,
  section: SectionStatus & SectionSize,
  filledSpaceCount: number
) => string = (activity, section, filledSpaceCount) => {
  if (isComplete(section)) {
    return "This section has ended.";
  } else if (isInProgress(section) && !activity.is_ongoing_weekly) {
    return "This section has already started.";
  } else if (isCanceled(section)) {
    return "This section has been canceled.";
  } else if (isFull(section, filledSpaceCount)) {
    return "This section is full.";
  } else if (isPromotable(activity, section, filledSpaceCount)) {
    return remainingSpotsMessage(section, filledSpaceCount);
  } else {
    return "";
  }
};

export const isAutoScheduledDraft: doesSectionHaveProperty<{
  details: Pick<Section["details"], "autoScheduledDraft">;
}> = section => {
  return Boolean(section?.details?.autoScheduledDraft);
};

export const isAutoScheduled: doesSectionHaveProperty<
  Pick<Section, "details">
> = section => {
  return (
    Boolean(section.details) && Boolean(section.details.autoScheduledByUserUid)
  );
};

export const isGrandfathered: doesSectionHaveProperty<
  Pick<Section, "checklist">
> = section => {
  return !!section.checklist && !!section.checklist.grandfatheredAt2017Rate;
};

export const isSummer: doesSectionHaveProperty<SectionTime> = section => {
  return !section.start_time || !section.end_time
    ? false
    : Time.isSummer(section.start_time) && Time.isSummer(section.end_time);
};

export const canBeUncanceled: doesSectionHaveProperty<
  SectionTime & Partial<Pick<Section, "checklist" | "isAutoCanceled">>
> = section => {
  return (
    (section.isAutoCanceled ||
      (!!section.checklist && !!section.checklist.autoCanceledAt)) &&
    isFuture(section)
  );
};

export const isInReviewTimeWindow: doesSectionHaveProperty<
  SectionTime
> = section => {
  return (
    !!section &&
    (isInProgress(section) ||
      (isPast(section) &&
        dayjs(section.end_time)
          .add(REVIEWS_TIME_LIMIT_IN_DAYS, "days")
          .isAfter()))
  );
};

export const MIN_RECORDING_DURATION_IN_MINUTES = 5;

/**
 * @deprecated instead use:
 *   - the GraphQL resolver Section.currentUserCanManage on the frontend
 *   - the DataLoader canManageSection for Queries
 *   - the query SectionQueries.userIsTeacherOfSection with an Auth.isAdmin
 *     check for Mutations or scripts
 *
 * This deprecation is due to the new section_moderators logic. It's okay to
 * pass an empty array in for the sectionModerators argument, as long as you're
 * sure that there aren't any for this section (as of 2022-05-15 this logic is
 * only used for Groups owned by seller orgs)
 */
export function canManage(
  activity: Pick<ActivityType, "user_uid">,
  section: SectionsRow,
  sectionModerators: SectionModeratorsRow[],
  authToken: Auth.Auth
): boolean {
  return Boolean(
    Activity.canManage(activity, authToken) ||
      (section &&
        section.user_uid &&
        authToken &&
        authToken.uid === section.user_uid) ||
      sectionModerators?.some(sm => sm.user_uid === authToken.uid)
  );
}

export function pricingOfferIsValidForSectionStartEnd(
  activity: ActivityDuration,
  section: SectionTime,
  pricingOffer: FullCurrentUserFragmentFragment["pricingOffer"]
): boolean {
  if (!!activity.is_ongoing_weekly) {
    return dayjs(pricingOffer?.endBy).isAfter(section.start_time);
  }

  return (
    dayjs(pricingOffer?.startAfter).isBefore(section.start_time) &&
    dayjs(pricingOffer?.endBy).isAfter(section.end_time)
  );
}

export function pricingOfferIsValid(
  activity: ActivityDuration,
  section: SectionTime,
  pricingOffer: FullCurrentUserFragmentFragment["pricingOffer"],
  user: UserType
): boolean {
  return (
    pricingOffer &&
    pricingOfferIsValidForSectionStartEnd(activity, section, pricingOffer) &&
    !pricingOffer.isOfferLimitReached &&
    !pricingOffer.isTotalCapReached &&
    User.hasConfirmedEmailForPricingOffer(user, pricingOffer)
  );
}

export function getTeacherUid(
  activity: Pick<ActivityType, "user_uid">,
  section: SectionsRow
): string {
  return section.user_uid || activity.user_uid;
}

export function pickSectionSearchFilters(
  searchFilters: SearchFilters
): SectionSearchFilters | null {
  const filters = _.pick(searchFilters, [
    "dow",
    "endBy",
    "endByTime",
    "startAfter",
    "startAfterTime",
    "startBefore",
    "order"
  ]);

  return Object.keys(filters).length > 0 ? filters : null;
}

export const OUTSCHOOL_SUMMER_CAMPOUT_SECTION_UID =
  "3fe0950b-4fdf-42d8-a367-02e955f94d29";
