// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

import HeaderTags from "./HeaderTags";

/**
 * When there is an error we should return 503 indicating the temporary nature of the issue.
 * For 503 discussion see https://productforums.google.com/forum/#!msg/webmasters/ESzKAjTq4Ec/TcFneRxOmDQJ
 *
 * Do not use noindex header for such cases. See:
 * https://support.google.com/webmasters/answer/93710?hl=en
 *
 */
export function PrerenderErrorMetaTag() {
  return (
    <HeaderTags meta={[{ content: "503", name: "prerender-status-code" }]} />
  );
}

export function PrerenderNotFoundMetaTag() {
  return (
    <HeaderTags meta={[{ content: "404", name: "prerender-status-code" }]} />
  );
}
