import { isLocalStorageSupported } from "@outschool/local-storage";

import type {
  SearchFilters,
  SavedSearch
} from "@outschool/gql-backend-generated";

const RECENT_SEARCH_HISTORY = "recentSearchHistory";
const MAX_RECENT_SEARCH_HISTORY = 10;

const localStorage = isLocalStorageSupported();

export function addSearchToHistory(
  searchFilters: SearchFilters | undefined | null
) {
  if (!searchFilters || !localStorage) {
    return;
  }

  let recentSearchHistoryArray: SavedSearch[] = [];
  try {
    const recentSearchHistory = localStorage.getItem(RECENT_SEARCH_HISTORY);
    recentSearchHistoryArray = recentSearchHistory
      ? JSON.parse(recentSearchHistory)
      : [];
  } catch (e) {
    console.error(e);
  }

  try {
    const newSearch: SavedSearch = {
      createdAt: new Date(),
      // @ts-ignore
      filters: searchFilters
    };

    if (isEmptySearch(newSearch, [])) {
      return;
    }

    recentSearchHistoryArray = removeSimilarInSearchHistory(
      newSearch,
      recentSearchHistoryArray
    );

    recentSearchHistoryArray.unshift(newSearch);

    if (recentSearchHistoryArray.length > MAX_RECENT_SEARCH_HISTORY) {
      recentSearchHistoryArray.pop();
    }

    localStorage.setItem(
      RECENT_SEARCH_HISTORY,
      JSON.stringify(recentSearchHistoryArray)
    );
  } catch (e) {
    console.error(e);
  }
}

export function isEmptySearch(
  search: SavedSearch,
  fieldsToIgnore: string[]
): boolean {
  const cleanedSearch = cleanSearchEntryFilters(search, fieldsToIgnore);
  return (
    !cleanedSearch.filters || Object.keys(cleanedSearch.filters).length === 0
  );
}

function removeSimilarInSearchHistory(
  toDedup: SavedSearch,
  searchHistory: SavedSearch[]
): SavedSearch[] {
  return searchHistory.filter(search => {
    return !recentSearchSimilar(search, toDedup);
  });
}

export function cleanSearchEntryFilters(
  search: SavedSearch,
  fieldsToRemove: string[]
): SavedSearch {
  const nonNillSearchFilters = search.filters
    ? Object.keys(search.filters!)
        // @ts-ignore
        .filter(key => !!search.filters![key]) // remove nil or null
        .filter(key => !fieldsToRemove.includes(key)) // remove fields to remove
        .reduce((acc, key) => {
          // @ts-ignore
          acc[key] = search.filters![key];
          return acc;
        }, {})
    : {};

  return {
    ...search,
    // @ts-ignore
    filters: nonNillSearchFilters
  };
}

export function recentSearchSimilar(a: SavedSearch, b: SavedSearch): boolean {
  return a.filters?.q == b.filters?.q;
}

export function getSearchHistory(): SavedSearch[] {
  if (!localStorage) {
    return [];
  }

  try {
    const recentSearchHistory = localStorage.getItem(RECENT_SEARCH_HISTORY);
    return recentSearchHistory ? JSON.parse(recentSearchHistory) : [];
  } catch (e) {
    console.error(e);
    return [];
  }
}
