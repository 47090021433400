import {
  Box,
  Button,
  Icon,
  Menu,
  Typography,
  useTheme
} from "@outschool/backpack";
import { faIdBadgeRegular, faPlusCircle } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { dayjs } from "@outschool/time";
import { CreditIcon, TrackedButton } from "@outschool/ui-components-shared";
import { useLinkComponent, useNavigation } from "@outschool/ui-utils";
import React from "react";

import { transactionsPath } from "../../../shared/Routes";
import { useSubscriptionCreditBalance } from "../../hooks/useSubscriptionCreditBalance";
import { useTimeZone } from "../../providers/TimeZoneProvider";
import { useStripeCheckout } from "../../routes/Subscriptions/hooks/useStripeCheckout";

export function SubscriptionCreditNavItem() {
  const { t } = useTranslation(
    "client\\components\\nav\\SubscriptionCreditNavItem"
  );
  const navigate = useNavigation();
  const theme = useTheme();
  const {
    status,
    availableCredits,
    subscriptionCreditLimit,
    nextSubscriptionStartDate,
    loading: loadingSubscriptionCredits
  } = useSubscriptionCreditBalance();
  const { timeZone } = useTimeZone();
  const [showModal, setShowModal] = React.useState<null | HTMLElement>(null);
  const [isCreditsModalOpen, setIsCreditsModalOpen] = React.useState(false);
  const [AddCreditsModal, setAddCreditsModal] =
    React.useState<React.ComponentType<any> | null>(null);

  if (loadingSubscriptionCredits || !status) {
    return null;
  }

  const navItemTextColor = availableCredits
    ? theme.palette.common.black
    : theme.palette.error[600];

  const handleOpenCreditsModal = async () => {
    // Dynamically import the modal when the button is clicked
    const module = await import(
      /* webpackChunkName: "subscriptions" */ "../subscriptions/AddCreditsModal"
    );
    setAddCreditsModal(() => module.default);
    setIsCreditsModalOpen(true);
    setShowModal(null);
  };

  return (
    <>
      <TrackedButton
        variant="link"
        trackingName="subscription_credit_nav_item"
        data-test-id="subscription-credit-nav-item"
        sx={{
          border: "none",
          paddingX: 8,
          paddingTop: 6,
          display: "block",
          color: navItemTextColor
        }}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          setShowModal(e.currentTarget);
        }}
      >
        <Box flex={true}>
          <Box sx={{ paddingTop: "0.2rem", paddingRight: "0.5rem" }}>
            <CreditIcon
              fillColor={
                availableCredits
                  ? theme.palette.warning.main
                  : theme.palette.error[200]
              }
              strokeColor={
                availableCredits
                  ? theme.palette.warning[700]
                  : theme.palette.error[600]
              }
            />
          </Box>
          <Typography
            variant="h5"
            sx={{
              color: navItemTextColor
            }}
          >
            {`${availableCredits}`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">{t("Credits")}</Typography>
        </Box>
      </TrackedButton>
      <Menu
        anchorEl={showModal}
        open={Boolean(showModal)}
        onClose={(e: MouseEvent) => {
          e.preventDefault();
          setShowModal(null);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: 100 }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: "8px",
              padding: "1rem",
              maxWidth: "480px",
              boxShadow: "0px 0px 4em rgba(0, 0, 0, 0.2)"
            }
          }
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.warning[100],
            borderRadius: "8px",
            paddingY: "1rem",
            paddingX: "2rem"
          }}
        >
          <Typography variant="body4">{t`Cycle renewal`}: </Typography>
          <Typography variant="body4" sx={{ fontWeight: "bold" }}>
            {nextSubscriptionStartDate &&
              `${dayjs(nextSubscriptionStartDate)
                .tz(timeZone)
                .format("MMM D, YYYY")}`}
            {` (${subscriptionCreditLimit} credits)`}
          </Typography>
          <Typography
            variant="h1"
            sx={{ marginTop: "1rem", color: navItemTextColor }}
          >
            {availableCredits}
          </Typography>
          <Typography variant="body4">{t`Available credits`}</Typography>
        </Box>
        <Box
          flex
          sx={{
            padding: "2rem"
          }}
        >
          <Button
            variant="link"
            sx={{
              marginRight: "2rem"
            }}
            onClick={() => {
              setShowModal(null);
              navigate(transactionsPath());
            }}
          >
            <Icon icon={faIdBadgeRegular} sx={{ marginRight: "0.2rem" }} />
            {t`View Balance`}
          </Button>

          <ManageSubscription />
        </Box>
        <Box flex sx={{ paddingX: "2rem" }}>
          <FlameIcon />
          <Box sx={{ maxWidth: "360px", paddingX: "0.2rem" }}>
            <Box>
              <Typography
                sx={{ fontWeight: "bold", display: "block" }}
              >{t`Planning to take more classes this month?`}</Typography>
              {availableCredits
                ? t`To keep learning without interruption, you can add credits for this month.`
                : t`To keep learning without interruption, you can add credits for this month or upgrade your plan for next month.`}
            </Box>
            <Box sx={{ marginTop: "1rem" }}>
              <Button variant="contained" onClick={handleOpenCreditsModal}>
                <Icon icon={faPlusCircle} sx={{ marginRight: "0.5rem" }} />
                {t`Add More Credits`}
              </Button>
            </Box>
          </Box>
        </Box>
      </Menu>
      {AddCreditsModal && (
        <AddCreditsModal
          isOpen={isCreditsModalOpen}
          onClose={() => setIsCreditsModalOpen(false)}
        />
      )}
    </>
  );
}

function ManageSubscription() {
  const { t } = useTranslation(
    "client\\components\\nav\\SubscriptionCreditNavItem"
  );
  const { createBillingSessionAndRedirect } = useStripeCheckout();
  const Link = useLinkComponent();

  return (
    <Link
      to="/"
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        createBillingSessionAndRedirect();
      }}
    >
      <Icon icon={faIdBadgeRegular} sx={{ marginRight: "0.2rem" }} />
      <Typography sx={{ fontWeight: "fontWeightMedium" }}>
        {t`Manage Membership`}
      </Typography>
    </Link>
  );
}

const FlameIcon = () => {
  return (
    <svg
      width="20"
      height="25"
      viewBox="0 0 20 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.854388 13.8958L0.835188 6.84667C0.831188 5.27834 1.79999 4.11834 3.24479 4.37167C3.53039 4.42167 3.99839 4.57584 4.24479 4.71334L5.59759 5.4675L8.19759 2.05334C8.63403 1.48033 9.29779 1.1463 9.99999 1.1463C10.7022 1.1463 11.3659 1.48033 11.8024 2.05334L17.2184 9.16667C18.4985 10.7827 19.1991 12.8123 19.2 14.9075C19.2 20.0283 15.0744 24.1667 9.99999 24.1667C4.92559 24.1667 0.799988 20.0283 0.799988 14.9075C0.799988 14.5675 0.818388 14.23 0.854388 13.8958Z"
        fill="#FF4D5B"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M7.20956 14.2308C7.22113 14.2 7.23645 14.1709 7.25516 14.1442L9.37516 11.1367C9.51442 10.939 9.73575 10.8222 9.97116 10.8222C10.2066 10.8222 10.4279 10.939 10.5672 11.1367L12.5856 14C13.2329 14.6623 13.5983 15.5673 13.6 16.5125C13.6 18.5067 11.988 20.1233 9.99996 20.1233C8.01196 20.1233 6.39996 18.5067 6.39996 16.5125C6.39996 15.6467 6.70396 14.8525 7.20956 14.2308Z"
        fill="#FFC800"
      />
    </svg>
  );
};
