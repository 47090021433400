import { CouponState, CurrencyCode } from "@outschool/gql-backend-generated";
import {
  BulkPurchaseRecurringMeetingsInfo,
  PurchaseRecurringMeetingsInfo,
  SettleOrderEnrollmentMeetingPurchaseMutation,
  SettleOrderEnrollmentMeetingPurchaseMutationVariables
} from "@outschool/gql-frontend-generated";
import * as Localization from "@outschool/localization";
import { gql, useMutation } from "@outschool/ui-apollo";
import React from "react";

import {
  AcceptPaymentWithClassWalletMutation,
  AcceptPaymentWithClassWalletMutationVariables,
  AcceptPaymentWithCouponCodeForBulkCheckoutMutation,
  AcceptPaymentWithCouponCodeForBulkCheckoutMutationVariables,
  AcceptPaymentWithCouponCodeMutation,
  AcceptPaymentWithCouponCodeMutationVariables,
  AcceptPaymentWithFundingProgramInvoiceMutation,
  AcceptPaymentWithFundingProgramInvoiceMutationVariables,
  AcceptPaymentWithPricingOfferMutation,
  AcceptPaymentWithPricingOfferMutationVariables,
  AcceptPaymentWithPurchaseOrderMutation,
  AcceptPaymentWithPurchaseOrderMutationVariables,
  AcceptPaymentWithStripeMutation,
  AcceptPaymentWithStripeMutationVariables,
  CancelOrderMutationMutation,
  CancelOrderMutationMutationVariables,
  SettleOrderMutation,
  SettleOrderMutationVariables
} from "../../shared/generated/graphql";
import { shouldChargeInLocalCurrency } from "../lib/Localization";
import { KansasKeepUserDetails } from "../routes/LearningPlanner/checkout/KansasKeepInvoice";
import EnrollmentFragment from "./EnrollmentFragment";
import EnrollmentMeetingPurchaseFragment from "./EnrollmentMeetingPurchaseFragment";
import EnrollmentPaymentFragment from "./EnrollmentPaymentFragment";
import { useExchangeRate } from "./ExchangeRateQueries";

export const settleOrderMutation = gql`
  mutation SettleOrder(
    $orderSlugId: ID!
    $bulkPurchaseRecurringMeetingsInfo: [BulkPurchaseRecurringMeetingsInfo!]
    $prepaidPurchaseStartDate: DateTime
  ) {
    settleOrder(
      orderSlugId: $orderSlugId
      bulkPurchaseRecurringMeetingsInfo: $bulkPurchaseRecurringMeetingsInfo
      prepaidPurchaseStartDate: $prepaidPurchaseStartDate
    ) {
      orderSlugId
      isFirstPurchaseByUser
      enrollments {
        ...EnrollmentFragment
        isFirstPurchaseByUser
        pricingOffer {
          uid
        }
        payments {
          ...EnrollmentPaymentFragment
        }
      }
      enrollmentMeetingPurchase {
        ...EnrollmentMeetingPurchaseFragment
      }
      giftEnrollments {
        uid
        acceptGiftSlugId
        price_cents
        confirmedAt
        currency_code
        payments {
          ...EnrollmentPaymentFragment
        }
      }
      giftCard {
        uid
        orderSlugId
        amountCents
        redemptionCode
      }
    }
  }
  ${EnrollmentMeetingPurchaseFragment}
  ${EnrollmentFragment}
  ${EnrollmentPaymentFragment}
`;

export const settleOrderEnrollmentMeetingPurchaseMutation = gql`
  mutation SettleOrderEnrollmentMeetingPurchase(
    $orderSlugId: ID!
    $purchaseRecurringMeetingsInfo: PurchaseRecurringMeetingsInfo
    $meetingRequestUids: [ID!]
  ) {
    settleOrderEnrollmentMeetingPurchase(
      orderSlugId: $orderSlugId
      purchaseRecurringMeetingsInfo: $purchaseRecurringMeetingsInfo
      meetingRequestUids: $meetingRequestUids
    ) {
      orderSlugId
      isFirstPurchaseByUser
      enrollments {
        ...EnrollmentFragment
        isFirstPurchaseByUser
        pricingOffer {
          uid
        }
        payments {
          ...EnrollmentPaymentFragment
        }
      }
      enrollmentMeetingPurchase {
        ...EnrollmentMeetingPurchaseFragment
      }
    }
  }
  ${EnrollmentMeetingPurchaseFragment}
  ${EnrollmentFragment}
  ${EnrollmentPaymentFragment}
`;

export const acceptPaymentWithStripeMutation = gql`
  mutation AcceptPaymentWithStripe(
    $orderSlugId: ID!
    $amountCents: Int!
    $orderPaymentUid: ID
    $charterSchoolUid: ID
    $purchaserName: String
    $purchaserEmail: String
    $exchangeRate: Float
    $currencyCode: CurrencyCode
    $savePaymentMethod: Boolean
  ) {
    acceptPaymentWithStripe(
      orderSlugId: $orderSlugId
      amountCents: $amountCents
      orderPaymentUid: $orderPaymentUid
      charterSchoolUid: $charterSchoolUid
      purchaserName: $purchaserName
      purchaserEmail: $purchaserEmail
      exchangeRate: $exchangeRate
      currencyCode: $currencyCode
      savePaymentMethod: $savePaymentMethod
    ) {
      paymentIntentClientId
      orderPaymentUid
    }
  }
`;

export const acceptPaymentWithPricingOfferMutation = gql`
  mutation AcceptPaymentWithPricingOffer(
    $orderSlugId: ID!
    $amountCents: Int!
    $pricingOfferUid: ID!
    $exchangeRate: Float
    $currencyCode: String
  ) {
    acceptPaymentWithPricingOffer(
      orderSlugId: $orderSlugId
      amountCents: $amountCents
      pricingOfferUid: $pricingOfferUid
      exchangeRate: $exchangeRate
      currencyCode: $currencyCode
    )
  }
`;

export const acceptPaymentWithPurchaseOrderMutation = gql`
  mutation AcceptPaymentWithPurchaseOrder(
    $orderSlugId: ID!
    $amountCents: Int!
    $charterSchoolUid: String!
    $intercomUrl: String!
    $purchaseOrderId: String!
  ) {
    acceptPaymentWithPurchaseOrder(
      orderSlugId: $orderSlugId
      amountCents: $amountCents
      charterSchoolUid: $charterSchoolUid
      intercomUrl: $intercomUrl
      purchaseOrderId: $purchaseOrderId
    )
  }
`;

export const acceptPaymentWithCouponCodeMutation = gql`
  mutation AcceptPaymentWithCouponCode(
    $orderSlugId: ID!
    $activityUid: ID!
    $amountCents: Int!
    $code: String!
    $currencyCode: CurrencyCode
    $exchangeRate: Float
  ) {
    acceptPaymentWithCouponCode(
      orderSlugId: $orderSlugId
      activityUid: $activityUid
      amountCents: $amountCents
      code: $code
      currencyCode: $currencyCode
      exchangeRate: $exchangeRate
    )
  }
`;

export const acceptPaymentWithCouponCodeForBulkCheckoutMutation = gql`
  mutation AcceptPaymentWithCouponCodeForBulkCheckout(
    $orderSlugId: ID!
    $couponOrderInputs: [CouponOrderInput!]!
    $amountCents: Int!
    $code: String!
    $currencyCode: CurrencyCode
    $exchangeRate: Float
  ) {
    acceptPaymentWithCouponCode(
      orderSlugId: $orderSlugId
      couponOrderInputs: $couponOrderInputs
      amountCents: $amountCents
      code: $code
      currencyCode: $currencyCode
      exchangeRate: $exchangeRate
    )
  }
`;

export const acceptPaymentWithClassWalletMutation = gql`
  mutation AcceptPaymentWithClassWallet($orderSlugId: ID!, $amountCents: Int!) {
    acceptPaymentWithClassWallet(
      orderSlugId: $orderSlugId
      amountCents: $amountCents
    )
  }
`;

export const acceptPaymentWithFundingProgramInvoiceMutation = gql`
  mutation AcceptPaymentWithFundingProgramInvoice(
    $orderSlugId: ID!
    $amountCents: Int!
    $userDetails: FundingProgramInvoiceInput!
  ) {
    acceptPaymentWithFundingProgramInvoice(
      orderSlugId: $orderSlugId
      amountCents: $amountCents
      userDetails: $userDetails
    )
  }
`;

export function useAcceptPaymentWithStripe({
  orderSlugId,
  amountCents,
  charterSchoolUid,
  purchaserEmail,
  exchangeRate,
  currencyCode
}: {
  orderSlugId: string;
  amountCents: number;
  charterSchoolUid?: string;
  purchaserName?: string;
  purchaserEmail?: string;
  exchangeRate?: number;
  currencyCode?: CurrencyCode;
}) {
  const [acceptPaymentWithStripe] = useMutation<
    AcceptPaymentWithStripeMutation,
    AcceptPaymentWithStripeMutationVariables
  >(acceptPaymentWithStripeMutation);

  return React.useCallback(
    async ({
      orderPaymentUid,
      purchaserName,
      savePaymentMethod
    }: {
      orderPaymentUid?: string;
      purchaserName?: string;
      savePaymentMethod?: boolean;
    }) => {
      const {
        // @ts-ignore TS(2339): Property 'acceptPaymentWithStripe' does not exist ... Remove this comment to see the full error message
        data: { acceptPaymentWithStripe: acceptPaymentWithStripeResp }
      } = await acceptPaymentWithStripe({
        variables: {
          orderSlugId,
          amountCents,
          orderPaymentUid,
          charterSchoolUid,
          purchaserName,
          purchaserEmail,
          exchangeRate,
          currencyCode,
          savePaymentMethod
        }
      });
      return acceptPaymentWithStripeResp;
    },
    [
      acceptPaymentWithStripe,
      orderSlugId,
      amountCents,
      charterSchoolUid,
      purchaserEmail,
      exchangeRate,
      currencyCode
    ]
  );
}

export function useAcceptPaymentWithPricingOffer({
  orderSlugId
}: {
  orderSlugId: string;
}): ({
  pricingOfferUid,
  amountCents
}: {
  pricingOfferUid: string;
  amountCents: number;
}) => Promise<boolean> {
  const { currencyCode } = Localization.useCurrencyLocalization();
  const { exchangeRate } = useExchangeRate(currencyCode);

  const isChargingInLocalCurrency = shouldChargeInLocalCurrency(currencyCode);

  const [acceptPaymentWithPricingOffer] = useMutation<
    AcceptPaymentWithPricingOfferMutation,
    AcceptPaymentWithPricingOfferMutationVariables
  >(acceptPaymentWithPricingOfferMutation);
  const acceptanceFn = React.useCallback(
    async ({
      pricingOfferUid,
      amountCents
    }: {
      pricingOfferUid: string;
      amountCents: number;
    }) => {
      const {
        data: {
          // @ts-ignore TS(2339): Property 'acceptPaymentWithPricingOffer' does not ... Remove this comment to see the full error message
          acceptPaymentWithPricingOffer: acceptPaymentWithPricingOfferResp
        }
      } = await acceptPaymentWithPricingOffer({
        variables: {
          orderSlugId,
          amountCents,
          pricingOfferUid,
          currencyCode: isChargingInLocalCurrency
            ? currencyCode
            : CurrencyCode.Usd,
          exchangeRate: isChargingInLocalCurrency
            ? exchangeRate
            : Localization.USD_STATIC_EXCHANGE_RATE
        }
      });
      return acceptPaymentWithPricingOfferResp;
    },
    [
      acceptPaymentWithPricingOffer,
      orderSlugId,
      exchangeRate,
      currencyCode,
      isChargingInLocalCurrency
    ]
  );
  return acceptanceFn;
}

export function useAcceptPaymentWithPurchaseOrder(): (
  orderSlugId: string,
  amountCents: number,
  charterSchoolUid: string,
  intercomUrl: string,
  purchaseOrderId: string
) => Promise<boolean> {
  const [acceptPaymentWithPurchaseOrder] = useMutation<
    AcceptPaymentWithPurchaseOrderMutation,
    AcceptPaymentWithPurchaseOrderMutationVariables
  >(acceptPaymentWithPurchaseOrderMutation);

  const acceptanceFn = React.useCallback(
    async (
      orderSlugId: string,
      amountCents: number,
      charterSchoolUid: string,
      intercomUrl: string,
      purchaseOrderId: string
    ) => {
      const {
        data: {
          // @ts-ignore TS(2339): Property 'acceptPaymentWithPurchaseOrder' does not... Remove this comment to see the full error message
          acceptPaymentWithPurchaseOrder: acceptPaymentWithPurchaseOrderResp
        }
      } = await acceptPaymentWithPurchaseOrder({
        variables: {
          orderSlugId,
          amountCents,
          charterSchoolUid,
          intercomUrl,
          purchaseOrderId
        }
      });
      return acceptPaymentWithPurchaseOrderResp;
    },
    [acceptPaymentWithPurchaseOrder]
  );
  return acceptanceFn;
}

type UseAcceptPaymentWithCouponArgs = {
  orderSlugId: string;
  activityUid: string;
  amountCents: number;
  code: string;
  currencyCode: CurrencyCode;
  exchangeRate: number;
};

export function useAcceptPaymentWithCoupon({
  orderSlugId,
  activityUid,
  amountCents,
  code,
  currencyCode,
  exchangeRate
}: UseAcceptPaymentWithCouponArgs) {
  const acceptPaymentWithCoupon =
    useAcceptPaymentWithCouponWithoutInitialArgs();

  return React.useCallback(async () => {
    const acceptPaymentWithCouponCodeResp = await acceptPaymentWithCoupon(
      orderSlugId,
      activityUid,
      amountCents,
      code,
      currencyCode,
      exchangeRate
    );
    return acceptPaymentWithCouponCodeResp;
  }, [
    acceptPaymentWithCoupon,
    orderSlugId,
    activityUid,
    amountCents,
    code,
    currencyCode,
    exchangeRate
  ]);
}

/* version of acceptPaymentWithCoupon where we don't need to instantiate with variables */
export function useAcceptPaymentWithCouponWithoutInitialArgs(): (
  orderSlugId: string,
  activityUid: string,
  amountCents: number,
  code: string,
  currencyCode: CurrencyCode,
  exchangeRate: number
) => Promise<CouponState> {
  const [acceptPaymentWithCouponCode] = useMutation<
    AcceptPaymentWithCouponCodeMutation,
    AcceptPaymentWithCouponCodeMutationVariables
  >(acceptPaymentWithCouponCodeMutation);

  const acceptanceFn = React.useCallback(
    async (
      orderSlugId: string,
      activityUid: string,
      amountCents: number,
      code: string,
      currencyCode: CurrencyCode,
      exchangeRate: number
    ) => {
      const {
        // @ts-ignore TS(2339): Property 'acceptPaymentWithCouponCode' does not ex... Remove this comment to see the full error message
        data: { acceptPaymentWithCouponCode: acceptPaymentWithCouponCodeResp }
      } = await acceptPaymentWithCouponCode({
        variables: {
          orderSlugId,
          activityUid,
          amountCents,
          code,
          currencyCode,
          exchangeRate
        }
      });
      return acceptPaymentWithCouponCodeResp;
    },
    [acceptPaymentWithCouponCode]
  );

  return acceptanceFn;
}

export function useAcceptPaymentWithCouponForBulkCheckout(): (
  orderSlugId: string,
  couponOrderInputs: { activityUid: string }[],
  amountCents: number,
  outschoolCouponCode: string,
  currencyCode: CurrencyCode,
  exchangeRate: number
) => Promise<CouponState> {
  const [acceptPaymentWithCouponCode] = useMutation<
    AcceptPaymentWithCouponCodeForBulkCheckoutMutation,
    AcceptPaymentWithCouponCodeForBulkCheckoutMutationVariables
  >(acceptPaymentWithCouponCodeForBulkCheckoutMutation);

  const acceptanceFn = React.useCallback(
    async (
      orderSlugId: string,
      couponOrderInputs: { activityUid: string }[],
      amountCents: number,
      outschoolCouponCode: string,
      currencyCode: CurrencyCode,
      exchangeRate: number
    ) => {
      const { data } = await acceptPaymentWithCouponCode({
        variables: {
          orderSlugId,
          couponOrderInputs,
          amountCents,
          code: outschoolCouponCode,
          currencyCode,
          exchangeRate
        }
      });
      return data?.acceptPaymentWithCouponCode ?? CouponState.NoCoupon;
    },
    [acceptPaymentWithCouponCode]
  );

  return acceptanceFn;
}

export function useAcceptPaymentWithClassWallet(): (
  orderSlugId: string,
  amountCents: number
) => Promise<boolean> {
  const [acceptPaymentWithClassWallet] = useMutation<
    AcceptPaymentWithClassWalletMutation,
    AcceptPaymentWithClassWalletMutationVariables
  >(acceptPaymentWithClassWalletMutation);

  const acceptanceFn = React.useCallback(
    async (orderSlugId: string, amountCents: number) => {
      const {
        // @ts-ignore TS(2339): Property 'acceptPaymentWithClassWallet' does not e... Remove this comment to see the full error message
        data: { acceptPaymentWithClassWallet: acceptPaymentWithClassWalletResp }
      } = await acceptPaymentWithClassWallet({
        variables: {
          orderSlugId,
          amountCents
        }
      });
      return acceptPaymentWithClassWalletResp;
    },
    [acceptPaymentWithClassWallet]
  );

  return acceptanceFn;
}

export function useAcceptPaymentWithFundingProgramInvoice(): (
  orderSlugId: string,
  amountCents: number,
  userDetails: KansasKeepUserDetails
) => Promise<boolean> {
  const [acceptPaymentWithFundingProgramInvoice] = useMutation<
    AcceptPaymentWithFundingProgramInvoiceMutation,
    AcceptPaymentWithFundingProgramInvoiceMutationVariables
  >(acceptPaymentWithFundingProgramInvoiceMutation);

  const acceptanceFn = React.useCallback(
    async (
      orderSlugId: string,
      amountCents: number,
      userDetails: KansasKeepUserDetails
    ) => {
      try {
        const { data } = await acceptPaymentWithFundingProgramInvoice({
          variables: {
            orderSlugId,
            amountCents,
            userDetails
          }
        });

        if (data && data.acceptPaymentWithFundingProgramInvoice) {
          return data.acceptPaymentWithFundingProgramInvoice;
        } else {
          return false;
        }
      } catch (error) {
        throw error;
      }
    },
    [acceptPaymentWithFundingProgramInvoice]
  );

  return acceptanceFn;
}

export function useSettleOrder() {
  const [settleOrder] = useMutation<
    SettleOrderMutation,
    SettleOrderMutationVariables
  >(settleOrderMutation);

  return React.useCallback(
    async (
      orderSlugId: string,
      bulkPurchaseRecurringMeetingsInfo?: BulkPurchaseRecurringMeetingsInfo[],
      prepaidPurchaseStartDate?: Date
    ) => {
      const {
        // @ts-ignore TS(2339): Property 'settleOrder' does not exist on type 'Set... Remove this comment to see the full error message
        data: { settleOrder: order }
      } = await settleOrder({
        variables: {
          orderSlugId,
          bulkPurchaseRecurringMeetingsInfo,
          prepaidPurchaseStartDate
        }
      });
      return order;
    },
    [settleOrder]
  );
}

export function useSettleOrderEnrollmentMeetingPurchase() {
  const [settleOrderEnrollmentMeetingPurchase] = useMutation<
    SettleOrderEnrollmentMeetingPurchaseMutation,
    SettleOrderEnrollmentMeetingPurchaseMutationVariables
  >(settleOrderEnrollmentMeetingPurchaseMutation);

  return React.useCallback(
    async (
      orderSlugId: string,
      purchaseRecurringMeetingsInfo?: PurchaseRecurringMeetingsInfo,
      meetingRequestUids?: string[]
    ) => {
      const {
        // @ts-ignore TS(2339): Property 'settleOrderEnrollmentMeetingPurchase' do... Remove this comment to see the full error message
        data: { settleOrderEnrollmentMeetingPurchase: order }
      } = await settleOrderEnrollmentMeetingPurchase({
        variables: {
          orderSlugId,
          purchaseRecurringMeetingsInfo,
          meetingRequestUids
        }
      });
      return order;
    },
    [settleOrderEnrollmentMeetingPurchase]
  );
}

export const cancelOrderMutation = gql`
  mutation CancelOrderMutation($orderSlugId: String!) {
    cancelOrder(orderSlugId: $orderSlugId)
  }
`;

export function useCancelOrder() {
  const [cancelOrder] = useMutation<
    CancelOrderMutationMutation,
    CancelOrderMutationMutationVariables
  >(cancelOrderMutation);

  return React.useCallback(
    async (orderSlugId: string) => {
      const {
        // @ts-ignore TS(2339): Property 'cancelOrder' does not exist on type 'Can... Remove this comment to see the full error message
        data: { cancelOrder: order }
      } = await cancelOrder({
        variables: {
          orderSlugId
        }
      });
      return order;
    },
    [cancelOrder]
  );
}
