// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, SxProps } from "@outschool/backpack";
import { Input } from "@outschool/ui-legacy-component-library";
import React from "react";

import { CARD_ELEMENT_OPTIONS, VARIANT_DEFAULT_SX, Variants } from ".";

type StripeCardBuyerNameProps = React.HTMLProps<HTMLInputElement> & {
  ref?: React.Ref<HTMLInputElement>;
  variant?: Variants;
  sx?: SxProps;
};

export default function StripeCardBuyerName({
  variant = Variants.valid,
  sx = {},
  ref,
  ...props
}: StripeCardBuyerNameProps) {
  return (
    <Box sx={[VARIANT_DEFAULT_SX[variant], ...(Array.isArray(sx) ? sx : [sx])]}>
      <Input
        data-test-id="buyerName"
        sx={{
          height: `${CARD_ELEMENT_OPTIONS.style.base.height} !important`,
          lineHeight: `${CARD_ELEMENT_OPTIONS.style.base.lineHeight} !important`,
          border: "none !important",
          padding: "0px !important",
          "::placeholder": {
            color: `${CARD_ELEMENT_OPTIONS.style.base["::placeholder"].color}`,
            opacity: 1
          },
          width: "100%"
        }}
        placeholder="Full Name"
        ref={ref}
        {...props}
      />
    </Box>
  );
}
