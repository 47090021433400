import { updateReferrerAttribution } from "@outschool/attribution";
import cloneDeep from "lodash/cloneDeep";
import qhistory from "qhistory";
import qs from "qs";

import * as Env from "../../shared/Env";
import * as Routes from "../../shared/Routes";

let isInitialPageLoad = true;

function wrapHistory(history: any) {
  const originalReferrer = document.referrer;
  const originalLocation = cloneDeep(history.location);
  const newHistory = {
    ...history,
    push: (location: any, state: any) => {
      history.push(location, state);
    },
    replace: (location: any, state: any) => {
      history.replace(location, state);
    },
    createHref: (location: any) => {
      return history.createHref(location);
    }
  };

  const updateProperties = (history: any) => {
    const properties = ["location", "length", "entries", "index", "action"];
    properties.forEach(prop => {
      if (history.hasOwnProperty(prop)) {
        newHistory[prop] = history[prop];
      }
    });
  };

  const handleChange = (location: any, action?: any) => {
    const currentLocation = cloneDeep(location);
    updateReferrerAttribution({
      originalReferrer,
      originalLocation,
      currentLocation,
      useSecureCookie: !(Env.isTest || Env.isDevelopment),
      hostPrefix: Routes.hostPrefix,
      learnerHostPrefix: Routes.learnerHostPrefix,
      isInitialPageLoad
    });
    isInitialPageLoad = false;
    updateProperties(history);
    const noScroll = location.state && location.state.noScroll;
    if (action === "PUSH" && !noScroll) {
      // Although we have this "action = PUSH" guard, browsers ignore the
      // scrollTo call below for "action = POP" cases.
      window && window.scrollTo && window.scrollTo(0, 0);
    }
  };

  history.replace(history.location);
  handleChange(history.location);
  history.listen(handleChange);
  return newHistory;
}

export function createHistory(history: $TSFixMe) {
  // qhistory adds query field to Location type. In order to make it work
  // we added @types/qhistory which type definitions which adds Location.query
  // field.
  // Remark: qhistory/index.d.ts does not define Location.type.
  //         The @types/qhistory is required because of that
  return wrapHistory(qhistory(history, qs.stringify, qs.parse));
}
