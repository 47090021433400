import {
  AddChildMutation,
  AddChildMutationVariables,
  ChildFragmentFragment,
  CurrentUserQueryQuery,
  DeleteChildMutation,
  DeleteChildMutationVariables,
  UpdateChildMutation,
  UpdateChildMutationVariables
} from "@outschool/gql-frontend-generated";
import { gql, useMutation } from "@outschool/ui-apollo";
import {
  CurrentUserChildFragment,
  currentUserQuery,
  useSession
} from "@outschool/ui-auth";
import { validOptimisticResponse } from "@outschool/ui-utils";
import _ from "lodash";
import { useCallback } from "react";

export function useCurrentUserLearners(): Array<ChildFragmentFragment> {
  const { currentUser } = useSession();
  return currentUser?.children ?? [];
}

const addChildMutation = gql`
  mutation AddChild($childInput: ChildInput!) {
    addChild(childInput: $childInput) {
      ...CurrentUserChildFragment
    }
  }
  ${CurrentUserChildFragment}
`;
export function useAddChildMutation() {
  const [addChildMutate] = useMutation<
    AddChildMutation,
    AddChildMutationVariables
  >(addChildMutation);

  return useCallback(
    (child: $TSFixMe) =>
      addChildMutate({
        variables: { childInput: child },
        // @ts-ignore TS(2339): Property 'addChild' does not exist on type 'AddChi... Remove this comment to see the full error message
        update: (store, { data: { addChild } }) => {
          const data = store.readQuery<CurrentUserQueryQuery>({
            query: currentUserQuery
          });
          store.writeQuery({
            query: currentUserQuery,
            data: {
              currentUser: {
                // @ts-ignore TS(2531): Object is possibly 'null'.
                ...data.currentUser,
                // @ts-ignore TS(2531): Object is possibly 'null'.
                children: [...data.currentUser.children, addChild]
              }
            }
          });
        }
      }),
    [addChildMutate]
  );
}

const updateChildMutation = gql`
  mutation UpdateChild($uid: ID!, $childInput: ChildInput!) {
    updateChild(uid: $uid, childInput: $childInput) {
      ...CurrentUserChildFragment
    }
  }
  ${CurrentUserChildFragment}
`;
export function useUpdateChildMutation() {
  const [updateChildMutate, { data, loading, error }] = useMutation<
    UpdateChildMutation,
    UpdateChildMutationVariables
  >(updateChildMutation);

  const updateChild = useCallback(
    (child: $TSFixMe) => {
      const keysToInclude = [
        "name",
        "email",
        "avatar",
        "pronoun",
        "intro",
        "birthMonth",
        "birthYear",
        "optedOutOfPersonalizedRecommendationsAt",
        "isLoginEnabled",
        "username",
        "password"
      ];
      // Only pass age when date of birth is empty
      if (!(child.birthMonth && child.birthYear)) {
        keysToInclude.push("age");
      }
      return updateChildMutate({
        variables: { childInput: _.pick(child, keysToInclude), uid: child.uid },
        optimisticResponse: validOptimisticResponse({
          __typename: "Mutation",
          updateChild: {
            ...child
          }
        })
      });
    },
    [updateChildMutate]
  );

  return { updateChild, data, loading, error };
}

export const deleteChildMutation = gql`
  mutation deleteChild($uid: ID!) {
    deleteChild(uid: $uid)
  }
`;

export function useDeleteChildMutation() {
  const [deleteChildMutate] = useMutation<
    DeleteChildMutation,
    DeleteChildMutationVariables
  >(deleteChildMutation);

  return useCallback(
    (childUid: $TSFixMe) =>
      deleteChildMutate({
        variables: { uid: childUid },
        refetchQueries: ["UserAdmin"],
        // @ts-ignore TS(2339): Property 'deleteChild' does not exist on type 'Del... Remove this comment to see the full error message
        update: (store, { data: { deleteChild } }) => {
          if (!deleteChild) {
            return;
          }
          const data = store.readQuery<CurrentUserQueryQuery>({
            query: currentUserQuery
          });
          if (!data?.currentUser) {
            return;
          }
          const children = data.currentUser.children.filter(
            child => child.uid !== childUid
          );
          store.writeQuery({
            query: currentUserQuery,
            data: {
              ...data,
              currentUser: { ...data.currentUser, children }
            }
          });
        }
      }),
    [deleteChildMutate]
  );
}
