import { BoxButton } from "@outschool/ui-legacy-component-library";
import React from "react";

interface HeaderNavItemProps {
  variant?: "standard" | "transparent";
  sx?: any;
  onClick?: any;
  children?: any;
  [prop: string]: any;
}

function HeaderNavItem(
  { variant = "standard", onClick, sx, children, ...props }: HeaderNavItemProps,
  ref: React.Ref<HTMLDivElement>
) {
  const variantStyles = {
    standard: {
      color: "text"
    },
    transparent: {
      color: "white"
    }
  };
  return (
    <BoxButton
      sx={{
        display: "flex",
        alignItems: "center",
        paddingY: "12px",
        fontWeight: "bold",
        cursor: "pointer",
        transition: "all 0.2s ease",
        whiteSpace: "nowrap",
        position: "relative",
        zIndex: 2,
        ...variantStyles[variant],
        ...sx
      }}
      onClick={onClick}
      ref={ref}
      {...props}
    >
      {children}
    </BoxButton>
  );
}

export default React.forwardRef(HeaderNavItem);
