import * as Localization from "@outschool/localization";
import { useCallback } from "react";

import { useExchangeRate } from "../queries/ExchangeRateQueries";

export function useConvertToUserDefaultCurrency() {
  const { currencyCode } = Localization.useCurrencyLocalization();

  const { loading, error, exchangeRate } = useExchangeRate(currencyCode);

  // Convert an amount in USD cents to "minor" units in another currency
  const convertCents = useCallback(
    (amountCents: number) => {
      if (!loading && !error && exchangeRate && exchangeRate > 0.0) {
        return Localization.convertFromUSDCents({
          amount: amountCents,
          toCurrencyCode: currencyCode,
          toExchangeRate: exchangeRate
        });
      }
      // Default to USD, most likely to indicate user not in experiment
      return amountCents;
    },

    [currencyCode, exchangeRate, loading, error]
  );

  // Convert an amount in USD dollars to the main denomination of another currency
  const convertDollars = useCallback(
    (amountDollars: $TSFixMe) => {
      return Localization.convertMinorToMajorDenomination({
        amount: convertCents(amountDollars * 100),
        currencyCode
      });
    },
    [convertCents, currencyCode]
  );

  return { convertCents, convertDollars, loading, error };
}

export function useReturnToUSD() {
  const { currencyCode } = Localization.useCurrencyLocalization();
  const { loading, error, exchangeRate } = useExchangeRate(currencyCode);

  return useCallback(
    (amountCents: number) => {
      if (!loading && !error && exchangeRate && exchangeRate > 0.0) {
        return Localization.convertToUSDCents({
          amount: amountCents,
          fromCurrencyCode: currencyCode,
          fromExchangeRate: exchangeRate
        });
      }

      // Default to just returning the input value if not in experiment
      return amountCents;
    },
    [currencyCode, exchangeRate, loading, error]
  );
}
